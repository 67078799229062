import types from '../types/categories';

const initialState = {
  categories: [],
  loading: false,
  creatingCategory: false,
  deletingCategory: { _id: '', loading: false },
  updatingCategory: false,
  entityCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CATEGORY_ATTEMPT:
      return { ...state, loading: true };
    case types.CREATE_CATEGORY_SUCCESS:
    case types.CREATE_CATEGORY_FAIL:
      return { ...state, loading: false };
    case types.DELETE_CATEGORY_ATTEMPT:
      return {
        ...state,
        deletingCategory: { _id: action.payload, loading: true },
      };
    case types.DELETE_CATEGORY_FAIL:
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deletingCategory: { _id: '', loading: false },
      };
    case types.GET_CATEGORIES_ATTEMPT:
      return { ...state, loading: true };
    case types.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case types.GET_CATEGORIES_FAIL:
      return { ...state, loading: false };
    case types.UPDATE_CATEGORY_ATTEMPT:
      return { ...state, updatingCategory: true };
    case types.UPDATE_CATEGORY_SUCCESS:
    case types.UPDATE_CATEGORY_FAIL:
      return { ...state, updatingCategory: false };
    default:
      return state;
  }
}
