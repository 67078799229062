import React, { memo, useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
export const ItemTypes = {
  ITEM: 'item',
};

const containerStyle = {
  width: 400,
};

const DndListMerchEdit = memo(function DndListMerchEdit({
  data,
  Component,
  setData,
  entity,
  title,
}) {
  const [items, setItems] = useState(data);
  const findItem = useCallback(
    (_id) => {
      const item = items.filter((c) => `${c?._id}` === _id)[0];
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items]
  );

  useEffect(() => {
    setItems(data);
  }, [data]);

  const moveItem = useCallback(
    (_id, atIndex) => {
      const { item, index } = findItem(_id);
      setItems(
        update(items, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        })
      );
      setData(
        update(items, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        })
      );
    },
    // eslint-disable-next-line
    [findItem, items, setItems]
  );

  const handleRemoveItem = (_id) => {
    setItems([...items.filter((item) => item._id !== _id)]);
  };

  const [, drop] = useDrop(() => ({ accept: ItemTypes.ITEM }));
  return (
    <div ref={drop} style={containerStyle}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h5>{title.toUpperCase()}</h5>
      </div>
      {items?.map((item, index) => {
        let text =
          item?.label ??
          item?.name?.en ??
          item?.name?.he ??
          items?.name?.ar ??
          item._id;
        return (
          <Component
            key={item._id}
            _id={item._id}
            text={text}
            item={item}
            moveItem={moveItem}
            findItem={findItem}
            {...{ entity, handleRemoveItem }}
          />
        );
      })}
    </div>
  );
});
export default DndListMerchEdit;
