import React, { useEffect, useState, useCallback } from 'react';
import { entities, entityFields } from '../../entities';

import { Card, CardBody, Row, Col, Form, CardFooter, Button, FormGroup, Label } from 'reactstrap';

import { allowedLocales } from '../../../../../config/locales';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreateEntityField from '../../components/CreateEntityField';
import PlacesAutoComplete from 'components/PlacesAutoComplete';
import WorkHours from './business/WorkHours';
import Spinner from 'components/Spinner';
import DeleteAdminModal from '../DeleteAdminModal';
import CrownAdminModal from '../CrownAdminModal';
import CropperUpload from 'components/CropperUpload';
import SmallMap from 'components/SmallMap';

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};

const Details = ({
  instance,
  model,
  handleOnChange,
  saveHours,
  handleEntityUpdate,
  handleEntityActivation,
  handleEntityPublishStatusChange,
  handleImageChange,
  handleBusinessLocationChange,
  handleEntityDelete,
}) => {
  const dispatch = useDispatch();
  const { updatingEntity, publishingEntity, deletingEntity } = useSelector(
    (state) => state.entities
  );
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState({});
  const [fields, setFields] = useState([]);
  const [tab, setTab] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [crownModalVisible, setCrownModalVisible] = useState(false);

  const handleSetType = useCallback(
    async (selectedOption) => {
      // setType(selectedOption);
      let beforeHook = entityFields?.[selectedOption.value]?.beforeHook;
      let fields = entityFields?.[selectedOption.value]?.fields?.filter((v) => v.showOnEdit);
      setFields(fields);
      if (beforeHook) {
        let beforeHookResponse = await beforeHook(dispatch);
        beforeHookResponse &&
          Object.keys(beforeHookResponse).forEach((key) => {
            fields = fields?.map((v) => {
              if (v.key === key) {
                v.options = beforeHookResponse?.[key]?.map((v) => {
                  return {
                    value: v._id,
                    label: v.name?.en ?? v.name,
                  };
                });
              }
              return v;
            });
          });
        setFields(fields);
      } else {
        setFields(fields);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (model && Object.keys(instance).length && !state?._id) {
      handleSetType({
        value: entities[model].model,
        label: entities[model].single,
      });
      setState(
        Object.keys(instance).reduce((obj, field) => {
          let data = instance[field];
          return {
            ...obj,
            [field]: data,
          };
        }, {})
      );
    } else if (model && Object.keys(instance).length) {
      setState(
        Object.keys(instance).reduce((obj, field) => {
          return {
            ...obj,
            [field]: instance[field],
          };
        }, {})
      );
    }
    // eslint-disable-next-line
  }, [model, instance, handleSetType]);

  const handleTabChange = (index) => {
    setTab(index);
  };

  const setImage = (img) => {
    setState({
      ...state,
      primaryImage: {
        binary: img,
      },
    });
    handleImageChange(img, 'primaryImage');
  };

  return (
    <>
      <div className="content">
        <DeleteAdminModal
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          _id={state?._id}
        />
        <CrownAdminModal
          visible={crownModalVisible}
          setVisible={setCrownModalVisible}
          _id={state?._id}
        />
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <Card style={{ padding: '10px' }}>
              <CardBody>
                <Row style={{ justifyContent: 'center' }}>
                  <Col xs={12} lg={6}>
                    {!!fields.filter((v) => v.localization).length && (
                      <>
                        <div style={styles.centerContent}>
                          <span style={styles.bigFont}>Localized fields</span>
                        </div>

                        <Tabs selectedIndex={tab} onSelect={(index) => handleTabChange(index)}>
                          <TabList style={{ display: 'flex' }}>
                            {(!!fields.filter((v) => v.localization).length
                              ? allowedLocales
                              : []
                            )?.map((locale, index) => (
                              <Tab style={styles.tab} key={index}>
                                <div style={styles.bigFont}>{locale}</div>
                              </Tab>
                            ))}
                          </TabList>

                          {(!!fields.filter((v) => v.localization).length
                            ? allowedLocales
                            : []
                          )?.map((locale, index) => (
                            <TabPanel key={index}>
                              <Form>
                                {fields
                                  .filter(
                                    (v) =>
                                      v.localization &&
                                      !['image', 'address', 'workHours'].includes(v.type)
                                  )
                                  ?.map((field) => {
                                    return (
                                      <CreateEntityField
                                        key={field.key}
                                        value={state?.[field?.key]}
                                        checked={state?.[field?.key]}
                                        {...{
                                          field,
                                          handleOnChange,
                                          locale,
                                        }}
                                      />
                                    );
                                  })}
                              </Form>
                            </TabPanel>
                          ))}
                        </Tabs>
                      </>
                    )}
                    <div style={styles.centerContent}>
                      <span style={styles.bigFont}>Non-localized fields</span>
                    </div>
                    {fields
                      .filter(
                        (v) =>
                          !v.localization && !['image', 'address', 'workHours'].includes(v.type)
                      )
                      ?.map((field) => {
                        return (
                          <CreateEntityField
                            key={field.key}
                            value={state?.[field?.key]}
                            checked={state?.[field?.key]}
                            {...{
                              field,
                              handleOnChange,
                            }}
                          />
                        );
                      })}
                    {fields
                      .filter((v) => !v.localization && ['address'].includes(v.type))
                      ?.map((field) => {
                        return (
                          <div key={field.key}>
                            <FormGroup>
                              <Label for={field.key}>{field.text}</Label>
                              <PlacesAutoComplete
                                value={state?.[field?.key]}
                                handleSelect={(address, location) => {
                                  handleBusinessLocationChange(address, location);
                                  setState({
                                    ...state,
                                    address: address,
                                    location: location.join(','),
                                  });
                                }}
                              />
                            </FormGroup>
                            {instance?.location?.coordinates?.length === 2 ||
                            instance?.location?.length === 2 ? (
                              <SmallMap
                                coordinates={
                                  instance?.location?.coordinates?.length === 2
                                    ? instance?.location?.coordinates
                                    : [instance?.location[1], instance?.location[0]]
                                }
                              />
                            ) : (
                              'No coordinates provided'
                            )}
                          </div>
                        );
                      })}
                    {fields
                      .filter((v) => ['workHours'].includes(v.type))
                      ?.map((field) => {
                        return (
                          <WorkHours
                            key={field.key}
                            {...{ saveHours }}
                            hours={state?.[field?.key]}
                          />
                        );
                      })}
                    <Row>
                      {fields
                        .filter((v) => v.type === 'image')
                        ?.map((field) => {
                          return (
                            <Col key={field.key} xs={12}>
                              <CropperUpload
                                aspect={16 / 9}
                                aspectText={'16/9'}
                                img={state?.[field?.key]?.path}
                                {...{ setImage }}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {model && entityFields?.[model]?.canUpdate && (
                  <>
                    {updatingEntity ? (
                      <Spinner />
                    ) : (
                      <Button onClick={handleEntityUpdate}>Update</Button>
                    )}
                  </>
                )}
                {model && entityFields?.[model]?.canDelete && (
                  <>
                    {updatingEntity ? (
                      <Spinner />
                    ) : (
                      <>
                        {state?.deleted === false && (
                          <Button color="danger" onClick={handleEntityDelete}>
                            Delete
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
                {model && entityFields?.[model]?.canDeactivate && (
                  <>
                    {updatingEntity ? (
                      <Spinner />
                    ) : (
                      <>
                        {state?.active !== undefined && !state?.published && (
                          <Button color="danger" onClick={handleEntityActivation}>
                            {state.active ? 'Deactivate' : 'Reactivate'}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
                {model && entityFields?.[model]?.canPublish && (
                  <>
                    {publishingEntity ? (
                      <Spinner />
                    ) : (
                      <>
                        {state?.published !== undefined && (
                          <Button color="info" onClick={handleEntityPublishStatusChange}>
                            {state.published ? 'Unpublish' : 'Publish'}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
                {model && entityFields?.[model]?.admin && user?._id !== state?._id && (
                  <>
                    {deletingEntity ? (
                      <Spinner />
                    ) : (
                      <>
                        <Button color="danger" onClick={() => setDeleteModalVisible(true)}>
                          Delete
                        </Button>
                        <Button onClick={() => setCrownModalVisible(true)}>Crown</Button>
                      </>
                    )}
                  </>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Details;
