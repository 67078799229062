import { createMessage } from './message';
import { requestWrapper, supportedRequestMethods } from '../../API';
import * as types from '../types/orders';

export const getOrder = (_id) => async (dispatch) => {
  dispatch({ type: types.GET_ORDER_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/orders/${_id}`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.GET_ORDER_SUCCESS,
      payload: response.data.data,
    });
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: types.GET_ORDER_FAIL,
    });
  }
};

export const cancelOrder = (_id, reason, details) => async (dispatch) => {
  dispatch({ type: types.CANCEL_ORDER_ATTEMPT, payload: _id });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/orders/${_id}/cancel`,
    body: { reason, details },
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.CANCEL_ORDER_SUCCESS,
    });
    dispatch(getOrder(_id));
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: types.CANCEL_ORDER_FAIL,
    });
  }
};

export const transferDelivery = (_id, courier) => async (dispatch) => {
  dispatch({ type: types.TRANSFER_DELIVERY_ATTEMPT, payload: _id });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/orders/${_id}/transfer-delivery`,
    body: { courier },
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.TRANSFER_DELIVERY_SUCCESS,
    });
    dispatch(getOrder(_id));
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: types.TRANSFER_DELIVERY_FAIL,
    });
  }
};

export const getRegionCouriers = (region) => async (dispatch) => {
  dispatch({ type: types.GET_REGION_COURIERS_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/couriers/in-shift?currentRegion=${region}`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.GET_REGION_COURIERS_SUCCESS,
      payload: { region, couriers: response.data.data },
    });
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: types.GET_REGION_COURIERS_FAIL,
    });
  }
};
