import {
  CREATE_MESSAGE,
  // NOTIFICATION_CREATED,
  // NOTIFICATION_CREATION_FAILED,
  REMOVE_MESSAGE,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../types/message';
// import { requestWrapper, supportedRequestMethods } from '../../API';

export const createMessage = (message) => (dispatch) => {
  let id = Math.random();

  dispatch({
    type: CREATE_MESSAGE,
    payload: { id, text: message },
  });
  setTimeout(() => {
    dispatch({
      type: REMOVE_MESSAGE,
      payload: id,
    });
  }, 3000);
};

export const newNotification = (message, rideId) => (dispatch) => {
  let id = Math.random();


  dispatch({
    type: CREATE_NOTIFICATION,
    payload: { id, text: message, rideId },
  });
  setTimeout(() => {
    dispatch({
      type: REMOVE_NOTIFICATION,
      payload: id,
    });
  }, 3000);
};

// export const createNotification = (title, body) => async (dispatch) => {
//   let requestParams = {
//     method: supportedRequestMethods.POST,
//     endpoint: '/admin/notification',
//     body: { title, message: body },
//   };

//   dispatch({ type: SENDING_DATA, payload: true });
//   let response = await requestWrapper(requestParams);
//   dispatch({ type: SENDING_DATA, payload: false });

//   if (response && response.success) {
//     dispatch({
//       type: NOTIFICATION_CREATED,
//       payload: true,
//     });
//   } else {
//     if (response) {
//       dispatch(createMessage(response.data.errors[0].message));
//     }
//     dispatch({
//       type: NOTIFICATION_CREATION_FAILED,
//     });
//   }
// };
