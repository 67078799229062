import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Row } from 'reactstrap';

import { allowedLocales } from '../../../../../../../config/locales';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndListForPreview from './DndListForPreview';
import DndItem from './DndItem';

import CreateSection from './CreateSection';
import EditSection from './EditSection';
import { entityFields } from 'views/pages/Entities/entities';

export const MERCHANDISE = ['sections', 'items', 'modifications'];

const MerchEditMode = ({ state, setState, goToPreview, saveOrder }) => {
  const [viewTab, setViewTab] = useState(0);
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [singleMerchEntity, setSingleMerchEntity] = useState({});

  const handleTabChange = (index) => {
    setViewTab(index);
    setEditOpen(false);
    setCreateOpen(false);
    setSingleMerchEntity({});
  };

  const toggleCreate = () => setCreateOpen(!createOpen);

  const handleSetData = (tab, data) => {
    setState({ ...state, [tab]: data });
  };

  const openEdit = (entity, item) => {
    let obj = { ...item, entity };
    switch (entity) {
      case 'sections':
        obj.listLabel = 'items';
        obj.items = item.items.map((v) => ({
          _id: v._id,
          value: v._id,
          label: v.name?.en ?? v.name?.he ?? v.name?.ar ?? v._id,
          published: v.published,
        }));
        break;
      case 'items':
        obj.listLabel = 'modifications';
        obj.modifications = item.modifications.map((v) => ({
          _id: v._id,
          value: v._id,
          label: v.name?.en ?? v.name?.he ?? v.name?.ar ?? v._id,
          published: v.published,
        }));
        break;
      case 'modifications':
        obj.listLabel = 'options';
        if (item?.validations?.minimumChoices !== undefined)
          obj.minimumChoices = item?.validations?.minimumChoices;
        if (item?.validations?.maximumChoices !== undefined)
          obj.maximumChoices = item?.validations?.maximumChoices;
        obj.type = entityFields.modifications.fields
          .find((v) => v.key === 'type')
          ?.options.find((v) => v.value === obj.type);
        obj.options = item.options;
        if (!!item.validations?.minimumChoices)
          obj.minimumChoices = item.validations?.minimumChoices;
        if (!!item.validations?.maximumChoices)
          obj.maximumChoices = item.validations?.maximumChoices;
        break;
      default:
        break;
    }
    setSingleMerchEntity(obj);
    setEditOpen(true);
  };

  return (
    <>
      <Tabs selectedIndex={viewTab} onSelect={(index) => handleTabChange(index)}>
        <TabList style={{ display: 'flex' }}>
          {MERCHANDISE.map((item, index) => (
            <Tab key={index}>{item}</Tab>
          ))}
        </TabList>
        {MERCHANDISE.map((tab, index) => {
          return (
            <TabPanel key={index}>
              {!editOpen && (
                <CreateSection
                  {...{
                    state,
                    setState,
                    createOpen,
                    tab,
                    toggle: toggleCreate,
                  }}
                />
              )}
              {!createOpen && singleMerchEntity?._id && (
                <>
                  <EditSection
                    {...{
                      editOpen,
                      setEditOpen,
                      tab,
                      singleMerchEntity,
                      setSingleMerchEntity,
                    }}
                  />
                </>
              )}
              {!createOpen && !editOpen && (
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <DndProvider backend={HTML5Backend}>
                    {!!state?.[tab]?.length && (
                      <>
                        <DndListForPreview
                          {...{
                            data: state?.[tab],
                            setData: (tab, data) => handleSetData(tab, data),
                            openEdit,
                            entity: tab,
                            field: tab === 'sections' ? 'title' : 'name',
                            locale: allowedLocales?.[tab] ?? 'en',
                            Component: DndItem,
                            goToPreview,
                            saveOrder,
                          }}
                        />
                      </>
                    )}
                  </DndProvider>
                </Row>
              )}
            </TabPanel>
          );
        })}
      </Tabs>
    </>
  );
};

export default MerchEditMode;
