import types from '../types/regions';

const initialState = {
  regions: [],
  regionsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.REGIONS_LOAD_ATTEMPT:
      return { ...state, regionsLoading: true };
    case types.REGIONS_LOAD_SUCCESS:
      return { ...state, regionsLoading: false, regions: action.payload };
    case types.REGIONS_LOAD_FAIL:
      return { ...state, regionsLoading: false };
    default:
      return state;
  }
}
