import React, { useState } from 'react';
import { createMerchEntity } from '../../../../../../../redux/actions/merchandise';
import CreateEntityField from '../../../CreateEntityField';
import { entityFields } from '../../../../entities';
import OptionsForm from './OptionsForm';
import Spinner from '../../../../../../../components/Spinner';
import { Row, Col, Form, Button, Collapse } from 'reactstrap';
import { allowedLocales } from '../../../../../../../config/locales';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { createMessage } from 'redux/actions/message';
import CropperUpload from 'components/CropperUpload';

const CreateSection = ({ state, setState, tab, createOpen, toggle }) => {
  const dispatch = useDispatch();
  const {
    merchandise: { creating },
    entities: { singleEntity: business },
  } = useSelector((state) => state);
  const [options, setOptions] = useState([]);
  const [langTab, setLangTab] = useState(0);

  const handleOnChange = (e, field, locale, isMulti) => {
    switch (field.type) {
      case 'select':
        if (isMulti && e) {
          setState({
            ...state,
            create: { ...state.create, [field.key]: [...e] },
          });
        } else if (isMulti && !e) {
          setState({
            ...state,
            create: { ...state.create, [field.key]: [] },
          });
        } else {
          let obj = {
            ...state,
            create: { ...state.create, [field.key]: e },
          };
          if (field.key === 'type') {
            obj.create.minimumChoices = 0;
          }
          setState(obj);
        }
        break;
      case 'checkbox':
        setState({
          ...state,
          create: { ...state.create, [field.key]: e.target.checked },
        });
        break;
      default:
        setState({
          ...state,
          create: {
            ...state.create,
            [field.key]: field?.localization
              ? {
                  ...(state?.create?.[field.key] || {}),
                  [locale]: e.target.value,
                }
              : e.target.value,
          },
        });
        break;
    }
  };

  const handleMerchItemCreate = async () => {
    const entity = {
      ...state.create,
    };
    if (tab === 'modifications') {
      entity.options = options;
    }
    let obj = entityFields[tab].transform(entity);
    if (tab === 'modifications' && !obj.type) {
      dispatch(createMessage('Please select modification type'));
      return;
    }
    await dispatch(createMerchEntity(tab, obj, business._id));
    setState(
      entityFields?.[tab]?.fields?.reduce((obj, item) => {
        const { key, value } = item;
        return {
          ...obj,
          [key]: value,
        };
      }, {})
    );
    setOptions([]);
  };

  const setImage = (img) => {
    setState({
      ...state,
      create: {
        ...state.create,
        primaryImage: {
          binary: img,
        },
      },
    });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>
          Toggle creation
        </Button>
      </div>
      <Collapse isOpen={createOpen}>
        <Tabs selectedIndex={langTab} onSelect={(index) => setLangTab(index)}>
          <div style={styles.centerContent}>
            <span style={styles.bigFont}>Localized fields</span>
          </div>
          <TabList style={{ display: 'flex' }}>
            {allowedLocales.map((locale, index) => (
              <Tab style={styles.tab} key={index}>
                <div style={styles.bigFont}>{locale}</div>
              </Tab>
            ))}
          </TabList>
          {allowedLocales.map((locale, index) => (
            <TabPanel key={index}>
              <Form>
                {!!entityFields?.[tab]?.fields?.filter(
                  (v) => v.localization && v.type !== 'options'
                ).length && (
                  <>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => v.localization && v.type !== 'options')
                      .map((field, index) => {
                        return (
                          <CreateEntityField
                            key={index}
                            value={state?.create?.[field?.key]}
                            checked={state?.create?.[field?.key]}
                            {...{
                              field,
                              handleOnChange,
                              locale,
                            }}
                          />
                        );
                      })}
                  </>
                )}
                {tab === 'modifications' && <OptionsForm {...{ options, setOptions }} />}
                {!!entityFields?.[tab]?.fields?.filter(
                  (v) => !v.localization && !['options', 'image'].includes(v.type)
                ).length && (
                  <>
                    <div style={styles.centerContent}>
                      <span style={styles.bigFont}>Non-localized fields</span>
                    </div>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => !v.localization && !['options', 'image'].includes(v.type))
                      .filter((v) => {
                        if (
                          tab === 'modifications' &&
                          ['singlechoice', undefined].includes(state?.create?.type?.value) &&
                          ['minimumChoices', 'maximumChoices'].includes(v.key)
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .map((field, index) => {
                        return (
                          <CreateEntityField
                            key={index}
                            value={state?.create?.[field?.key] ?? ''}
                            checked={state?.create?.[field?.key] ?? false}
                            {...{
                              field,
                              handleOnChange,
                              locale,
                            }}
                          />
                        );
                      })}
                  </>
                )}
                {!!entityFields?.[tab]?.fields?.filter((v) => v.type === 'image').length && (
                  <Row>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => v.type === 'image')
                      .map((field, index) => {
                        return (
                          <Col key={index} xs={12}>
                            <CropperUpload
                              aspect={16 / 9}
                              aspectText={'16/9'}
                              img={state?.create?.[field?.key]?.path}
                              {...{ setImage }}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                )}
              </Form>
            </TabPanel>
          ))}
          {creating?.[tab] ? <Spinner /> : <Button onClick={handleMerchItemCreate}>Create</Button>}
        </Tabs>
      </Collapse>
    </>
  );
};

export default CreateSection;

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};
