import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MerchEditMode from './components/MerchEditMode';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MerchPreviewMode from './components/MerchPreviewMode';
import Note from 'components/Note';

const Merchandise = ({ instance, model, saveOrder }) => {
  const [tab, setTab] = useState(0);
  const { sections, items, modifications } = useSelector((state) => state.merchandise);
  const [state, setState] = useState({});

  useEffect(() => {
    setState({ sections, items, modifications });
  }, [sections, items, modifications]);

  const handleTabChange = (index) => {
    setTab(index);
  };

  const TABS = [
    { title: 'Preview', Component: <MerchPreviewMode {...{ state }} /> },
    {
      title: 'Edit',
      Component: (
        <MerchEditMode {...{ state, setState, saveOrder, goToPreview: () => handleTabChange(0) }} />
      ),
    },
  ];

  return (
    <div>
      <Note title="Note that changing items will affect customers that have it in their cart. It's better doing this while the business is offline and can not accept orders." />
      <Tabs selectedIndex={tab} onSelect={(index) => handleTabChange(index)}>
        <TabList style={{ display: 'flex' }}>
          {TABS.map((item, index) => (
            <Tab key={index}>{item.title}</Tab>
          ))}
        </TabList>
        {TABS.map(({ Component }, index) => (
          <TabPanel key={index}>{Component}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default Merchandise;
