import * as types from '../types/orders';
const initialState = {
  order: null,
  orderLoading: false,
  cancelingOrder: { _id: null, loading: false },
  transferringOrder: { _id: null, loading: false },
  couriers: {},
  couriersLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CANCEL_ORDER_ATTEMPT:
      return {
        ...state,
        cancelingOrder: { _id: action.payload, loading: true },
      };
    case types.CANCEL_ORDER_SUCCESS:
    case types.CANCEL_ORDER_FAIL:
      return { ...state, cancelingOrder: { _id: null, loading: false } };
    case types.TRANSFER_DELIVERY_ATTEMPT:
      return {
        ...state,
        transferringOrder: { _id: action.payload, loading: true },
      };
    case types.TRANSFER_DELIVERY_SUCCESS:
    case types.TRANSFER_DELIVERY_FAIL:
      return { ...state, transferringOrder: { _id: null, loading: false } };
    case types.GET_REGION_COURIERS_ATTEMPT:
      return { ...state, couriersLoading: true };
    case types.GET_REGION_COURIERS_SUCCESS:
      return {
        ...state,
        couriersLoading: false,
        couriers: {
          ...state.couriers,
          [action.payload.region]: action.payload.couriers.available,
        },
      };
    case types.GET_REGION_COURIERS_FAIL:
      return { ...state, couriersLoading: false };
    case types.GET_ORDER_ATTEMPT:
      return { ...state, orderLoading: true };
    case types.GET_ORDER_SUCCESS:
      return { ...state, orderLoading: false, order: action.payload };
    case types.GET_ORDER_FAIL:
      return { ...state, orderLoading: false };
    default:
      return state;
  }
}
