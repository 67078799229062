import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from 'redux/store';
import App from 'App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'assets/css/nucleo-icons.css';
import 'react-notification-alert/dist/animate.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.1.0';
import 'assets/demo/demo.css';
import 'assets/css/custom.css';
import Alert from './components/Alert';
import NotificationAlert from './components/NotificationAlert';

process.env.REACT_APP_ENV !== 'development' &&
  Sentry.init({
    dsn: 'https://8614d45f43344f999bc38bd441e5cb78@o348190.ingest.sentry.io/5657606',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    normalizeDepth: 5,
    environment: process.env.REACT_APP_ENV,
  });

if ('serviceWorker' in navigator && 'Notification' in navigator) {
  navigator.serviceWorker
    .register('../../firebase-messaging-sw.js')
    .then(function (registration) {
      // 'Registration successful, scope is:', registration.scope
    })
    .catch(function (err) {
      // 'Service worker registration failed, error:', err
    });
}

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <div className="rna-container">
        <Alert />
        <NotificationAlert />
      </div>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
