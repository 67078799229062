import {
  CREATE_MESSAGE,
  REMOVE_MESSAGE,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../types/message';
const initialState = {
  message: {
    id: null,
    text: null,
  },
  notification: {
    id: null,
    test: null,
    rideId: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: { id: null, text: null, rideId: null },
      };
    case CREATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: { id: null, text: null },
      };
    default:
      return state;
  }
}
