import React from 'react';
import { Redirect } from 'react-router-dom';

// import Dashboard from './views/pages/Dashboard.js';
import EntityList from './views/pages/Entities/EntityList.js';
import EntityDetails from './views/pages/Entities/EntityDetails.js';
import CreateEntity from './views/pages/Entities/CreateEntity';
import Categories from './views/pages/Categories';
import OrderDetails from './views/pages/OrderDetails';
import Statistics from './views/pages/Statistics';
import MapScreenWrapper from 'views/pages/map/MapScreenWrapper.js';

export const sideBarRoutes = [
  {
    path: '/dashboard',
    name: `Dashboard`,
    icon: 'tim-icons icon-square-pin',
    component: MapScreenWrapper,
    onlyCrowned: false,
  },
  {
    path: '/list-business',
    name: `Businesses List`,
    icon: 'tim-icons icon-cart',
    component: EntityList,
    onlyCrowned: false,
  },
  {
    path: '/create-business',
    name: `Create Business`,
    icon: 'tim-icons icon-bag-16',
    component: CreateEntity,
    onlyCrowned: false,
  },
  {
    path: '/list-courier',
    name: `Couriers List`,
    icon: 'tim-icons icon-delivery-fast',
    component: EntityList,
    onlyCrowned: false,
  },
  {
    path: '/create-courier',
    name: `Create Courier`,
    icon: 'tim-icons icon-single-02',
    component: CreateEntity,
    onlyCrowned: false,
  },
  {
    path: '/list-order',
    name: `Orders List`,
    icon: 'tim-icons icon-paper',
    component: EntityList,
    onlyCrowned: false,
  },
  {
    path: '/list-client',
    name: `Client List`,
    icon: 'tim-icons icon-single-02',
    component: EntityList,
    onlyCrowned: false,
  },
  {
    path: '/list-admin',
    name: `Admin List`,
    icon: 'tim-icons icon-single-02',
    component: EntityList,
    onlyCrowned: true,
  },
  {
    path: '/create-admin',
    name: `Create Admin`,
    icon: 'tim-icons icon-single-02',
    component: CreateEntity,
    onlyCrowned: true,
  },
  {
    path: '/categories',
    name: `Categories`,
    icon: 'tim-icons icon-book-bookmark',
    component: Categories,
    onlyCrowned: false,
  },
  {
    path: '/stats',
    name: `Statistics`,
    icon: 'tim-icons icon-chart-bar-32',
    component: Statistics,
    onlyCrowned: false,
  },
];

export const routes = [
  ...sideBarRoutes,
  {
    path: '/details-business',
    specific: '/:id',
    name: `Business Details`,
    icon: 'tim-icons icon-bag-16',
    component: EntityDetails,
  },
  {
    path: '/details-courier',
    specific: '/:id',
    name: `Courier Details`,
    icon: 'tim-icons icon-bag-16',
    component: EntityDetails,
  },
  {
    path: '/details-client',
    specific: '/:id',
    name: `Client Details`,
    icon: 'tim-icons icon-bag-16',
    component: EntityDetails,
  },
  {
    path: '/details-order',
    specific: '/:id',
    name: `Order Details`,
    icon: 'tim-icons icon-bag-16',
    component: OrderDetails,
  },
  {
    path: '/details-admin',
    specific: '/:id',
    name: `Admin Details`,
    icon: 'tim-icons icon-bag-16',
    component: EntityDetails,
  },
  {
    path: '*',
    name: `Dashboard`,
    icon: 'tim-icons icon-single-02',
    component: () => <Redirect to="/dashboard" />,
  },
];
