const types = {
  GET_CATEGORIES_ATTEMPT: 'GET_CATEGORIES_ATTEMPT',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',
  CREATE_CATEGORY_ATTEMPT: 'CREATE_CATEGORY_ATTEMPT',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAIL: 'CREATE_CATEGORY_FAIL',
  DELETE_CATEGORY_ATTEMPT: 'DELETE_CATEGORY_ATTEMPT',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAIL: 'DELETE_CATEGORY_FAIL',
  UPDATE_CATEGORY_ATTEMPT: 'UPDATE_CATEGORY_ATTEMPT',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAIL: 'UPDATE_CATEGORY_FAIL',
};

export default types;
