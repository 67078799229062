import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { allowedLocales } from '../../../../../../../config/locales';
import MerchItem from './MerchItem';

const MerchPreviewMode = ({ state }) => {
  const [tab, setTab] = useState(0);

  return (
    <Tabs selectedIndex={tab} onSelect={(index) => setTab(index)}>
      <TabList style={{ display: 'flex' }}>
        {allowedLocales.map((locale, index) => (
          <Tab style={styles.tab} key={index}>
            <div style={styles.bigFont}>{locale}</div>
          </Tab>
        ))}
      </TabList>
      {allowedLocales.map((locale, index) => (
        <TabPanel key={index} style={styles.centerContent}>
          <div style={styles.previewScreen}>
            {state?.sections?.map((section) => (
              <div key={section._id}>
                <div>{section?.title?.[locale] ?? section?.title?.['en']}</div>
                <div>
                  {section?.description?.[locale]}
                </div>
                {section?.items?.map((item) => (
                  <MerchItem key={item._id} {...{ item, locale }} />
                ))}
              </div>
            ))}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default MerchPreviewMode;

const styles = {
  previewScreen: {
    width: 300,
    padding: 17,
  },
  item: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ccc',
    width: '50%',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};
