const types = {
  ENTITIES_LOAD_ATTEMPT: 'ENTITIES_LOAD_ATTEMPT',
  ENTITIES_LOAD_SUCCESS: 'ENTITIES_LOAD_SUCCESS',
  ENTITIES_LOAD_FAIL: 'ENTITIES_LOAD_FAIL',
  ENTITY_CREATE_ATTEMPT: 'ENTITY_CREATE_ATTEMPT',
  ENTITY_CREATE_SUCCESS: 'ENTITY_CREATE_SUCCESS',
  ENTITY_CREATE_FAIL: 'ENTITY_CREATE_FAIL',
  ENTITY_UPDATE_ATTEMPT: 'ENTITY_UPDATE_ATTEMPT',
  ENTITY_UPDATE_SUCCESS: 'ENTITY_UPDATE_SUCCESS',
  ENTITY_UPDATE_FAIL: 'ENTITY_UPDATE_FAIL',
  ENTITY_LOAD_ATTEMPT: 'ENTITY_LOAD_ATTEMPT',
  ENTITY_LOAD_SUCCESS: 'ENTITY_LOAD_SUCCESS',
  ENTITY_LOAD_FAIL: 'ENTITY_LOAD_FAIL',
  ENTITY_DEACTIVATE_ATTEMPT: 'ENTITY_DEACTIVATE_ATTEMPT',
  ENTITY_DEACTIVATE_SUCCESS: 'ENTITY_DEACTIVATE_SUCCESS',
  ENTITY_DEACTIVATE_FAIL: 'ENTITY_DEACTIVATE_FAIL',
  ENTITY_REACTIVATE_ATTEMPT: 'ENTITY_REACTIVATE_ATTEMPT',
  ENTITY_REACTIVATE_SUCCESS: 'ENTITY_REACTIVATE_SUCCESS',
  ENTITY_REACTIVATE_FAIL: 'ENTITY_REACTIVATE_FAIL',
  ENTITY_PUBLISH_ATTEMPT: 'ENTITY_PUBLISH_ATTEMPT',
  ENTITY_PUBLISH_SUCCESS: 'ENTITY_PUBLISH_SUCCESS',
  ENTITY_PUBLISH_FAIL: 'ENTITY_PUBLISH_FAIL',
  ENTITY_UNPUBLISH_ATTEMPT: 'ENTITY_UNPUBLISH_ATTEMPT',
  ENTITY_UNPUBLISH_SUCCESS: 'ENTITY_UNPUBLISH_SUCCESS',
  ENTITY_UNPUBLISH_FAIL: 'ENTITY_UNPUBLISH_FAIL',
  CREATE_ADMIN_ATTEMPT: 'CREATE_ADMIN_ATTEMPT',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_FAIL: 'CREATE_ADMIN_FAIL',
  CROWN_ADMIN_ATTEMPT: 'CROWN_ADMIN_ATTEMPT',
  CROWN_ADMIN_SUCCESS: 'CROWN_ADMIN_SUCCESS',
  CROWN_ADMIN_FAIL: 'CROWN_ADMIN_FAIL',
  DELETE_ADMIN_ATTEMPT: 'DELETE_ADMIN_ATTEMPT',
  DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_FAIL: 'DELETE_ADMIN_FAIL',
  SET_ENTITY: 'SET_ENTITY',
  CLEAR_ENTITY: 'CLEAR_ENTITY',
};

export default types;
