import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { default as DatePicker } from 'react-datepicker';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { generateReceiptReport } from 'redux/actions/dashboard';
import { generateOrderReport } from 'redux/actions/dashboard';

const Statistics = () => {
  const dispatch = useDispatch();
  const { reportData, reportLoading, ordersData, ordersLoading } = useSelector(
    (state) => state.dashboard
  );
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });
  const [orderDates, setOrdersDates] = useState({
    start: null,
    end: null,
  });

  const handleGenerateReport = () => {
    dispatch(generateReceiptReport(dates.start, dates.end));
  };

  const handleGenerateOrderReport = () => {
    dispatch(generateOrderReport(orderDates.start, orderDates.end));
  };

  return (
    <div className="content">
      <Card className="p-2">
        <Row>
          <Col xs={12}>
            <h4>Export receipt summary</h4>
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              selected={dates.start}
              onChange={(e) => {
                setDates({ ...dates, start: e.getTime() });
              }}
              showTimeSelect={false}
              dateFormat="d/M/yyyy"
            />
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              selected={dates.end}
              onChange={(e) => {
                setDates({ ...dates, end: e.getTime() });
              }}
              showTimeSelect={false}
              dateFormat="d/M/yyyy"
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={6}>
                <Button onClick={handleGenerateReport} disabled={reportLoading}>
                  Create Report
                </Button>
              </Col>
              <Col xs={6}>
                {reportData && (
                  <CSVLink
                    filename={`${new Date(
                      dates.start
                    ).toDateString()}-${new Date(
                      dates.end
                    ).toDateString()}.csv`}
                    data={reportData}
                  >
                    <Button>Download csv</Button>
                  </CSVLink>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h4>Export orders</h4>
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              selected={orderDates.start}
              onChange={(e) => {
                setOrdersDates({ ...orderDates, start: e.getTime() });
              }}
              showTimeSelect={false}
              dateFormat="d/M/yyyy"
            />
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              selected={orderDates.end}
              onChange={(e) => {
                setOrdersDates({ ...orderDates, end: e.getTime() });
              }}
              showTimeSelect={false}
              dateFormat="d/M/yyyy"
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={6}>
                <Button
                  onClick={handleGenerateOrderReport}
                  disabled={ordersLoading}
                >
                  Create Order Report
                </Button>
              </Col>
              <Col xs={6}>
                {ordersData && (
                  <CSVLink
                    filename={`${new Date(
                      ordersData.start
                    ).toDateString()}-${new Date(
                      ordersData.end
                    ).toDateString()}.csv`}
                    data={ordersData}
                  >
                    <Button>Download csv</Button>
                  </CSVLink>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Statistics;
