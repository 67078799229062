import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input } from 'reactstrap';
import { createMessage } from '../redux/actions/message';
import { useDispatch } from 'react-redux';
import { GoogleApiWrapper } from 'google-maps-react';

const PlacesAutoComplete = ({ handleSelect, value }) => {
  const [query, setQuery] = useState(value ?? '');
  const dispatch = useDispatch();

  const select = async (addressString) => {
    try {
      const results = await geocodeByAddress(addressString);

      if (!results.length) {
        dispatch(createMessage('Address not found'));
        return;
      }

      const latLng = await getLatLng(results[0]);

      if (!latLng.lat || !latLng.lng) {
        dispatch(createMessage('Address not found'));
        return;
      }
      let street =
        results?.[0]?.address_components?.find((v) => v.types.includes('route'))?.long_name ?? '';
      let number =
        results?.[0]?.address_components?.find((v) => v.types.includes('street_number'))
          ?.long_name ?? '';

      let address = `${street} ${number}`;

      setQuery(address);
      handleSelect(address, Object.values(latLng));
    } catch (err) {
      console.error('Error', err);
      dispatch(createMessage('Addresses not found'));
      return;
    }
  };

  return (
    <PlacesAutocomplete
      value={query}
      onChange={setQuery}
      onSelect={select}
      debounce={500}
      onError={() => dispatch(createMessage('Addresses not found'))}
      searchOptions={{
        types: ['address'],
        componentRestrictions: {
          country: ['il'],
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: 'Choose exact location..',
              className: 'location-search-input',
            })}
            bssize="lg"
            id="input-identifier"
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  language: 'en',
})(PlacesAutoComplete);
