import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Details, Manager, Merchandise } from './components/entityTabs/';
import { getRegions } from '../../../redux/actions/regions';
import { localeStringOptions } from 'utils';
import { getCategories } from 'redux/actions/categories';
import { cleanObj } from 'utils';

export const entityTabs = {
  business: [
    {
      en: 'businesses',
      text: 'Businesses',
      single: 'business',
    },
    {
      en: 'restaurant',
      text: 'Restaurants',
      single: 'restaurant',
    },
    {
      en: 'retail',
      text: 'Retailers',
      single: 'retail',
    },
  ],
  courier: [
    {
      en: 'couriers',
      text: 'Couriers',
      single: 'courier',
    },
  ],
  client: [
    {
      en: 'clients',
      text: 'Clients',
      single: 'client',
    },
  ],
  order: [
    {
      en: 'orders',
      text: 'Orders',
      single: 'order',
    },
  ],
  admin: [
    {
      en: 'admins',
      text: 'Admins',
      single: 'admin',
    },
  ],
};

const businessColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Published',
    accessor: 'published',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'City',
    accessor: 'city',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Region',
    accessor: 'region',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Date Joined',
    accessor: 'created_at',
    headerClassName: 'text-center',
    className: 'text-center',
    sortable: false,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    headerClassName: 'text-center',
    className: 'text-center',
  },
];

const userColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    headerClassName: 'text-center',
    className: 'text-center',
  },
];

const adminColumns = [
  {
    Header: 'Crowned',
    accessor: 'crowned',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Email',
    accessor: 'email',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  ...userColumns.filter((v) => v.accessor !== 'phone'),
];

const orderColumns = [
  {
    Header: 'Number',
    accessor: 'number',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: true,
  },
  {
    Header: 'Business',
    accessor: 'businessName',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Courier',
    accessor: 'courierName',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Client',
    accessor: 'clientName',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: true,
    sortable: false,
  },
  {
    Header: 'Date',
    accessor: 'created_at',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'State',
    accessor: 'state',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Delivery method',
    accessor: 'deliveryMethod',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    headerClassName: 'text-center',
    className: 'text-center',
  },
];

export const entities = {
  business: {
    model: 'business',
    canCreate: true,
    single: 'Business',
  },
  courier: {
    model: 'courier',
    canCreate: true,
    single: 'Courier',
  },
  order: {
    model: 'order',
    canCreate: false,
    single: 'Order',
  },
  client: {
    model: 'client',
    canCreate: false,
    single: 'Client',
  },
  admin: {
    model: 'admin',
    canCreate: true,
    single: 'Admin',
  },
};

export const entitiesColumns = {
  business: businessColumns,
  restaurant: businessColumns,
  retail: businessColumns,
  courier: userColumns,
  client: userColumns,
  order: orderColumns,
  admin: adminColumns,
};

export const returnEntityObj = (type, entity, key) => {
  let fields = [];

  switch (type) {
    case 'business':
      fields = businessColumns.map((_) => _.accessor);
      break;
    case 'courier':
    case 'client':
      fields = userColumns.map((_) => _.accessor);
      break;
    case 'order':
      fields = orderColumns.map((_) => _.accessor);
      break;
    case 'admin':
      fields = adminColumns.map((_) => _.accessor);
      break;
    default:
      break;
  }

  return fields.reduce((obj, field) => {
    let value;
    switch (field) {
      case 'crowned':
        value = entity.crowned ? 'CROWNED' : '';
        break;
      case 'businessName':
        value = entity.business?.name;
        break;
      case 'courierName':
        value = entity?.deliverySettings?.courier?.firstName
          ? `${entity?.deliverySettings?.courier?.firstName} ${entity?.deliverySettings?.courier?.lastName}`
          : '';
        break;
      case 'clientName':
        value = `${entity?.client?.firstName} ${entity?.client?.lastName}`;
        break;
      case 'created_at':
        value = new Date(entity?.created_at || entity?.company?.created_at || 0).toLocaleDateString(
          'he-il',
          localeStringOptions
        );
        break;
      case 'published':
        value = entity[field] ? 'published' : 'not published';
        break;
      case 'region':
        value = entity[field]?.name;
        break;
      case 'actions':
        value = !entity?.crowned && (
          <div className="actions-column">
            <UncontrolledDropdown>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
              >
                Actions
              </DropdownToggle>
              <DropdownMenu right aria-labelledby="dropdownMenuButton">
                <Link to={`/details-${type}/${entity._id}`} className="user-profile-link">
                  View Details
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        );
        break;
      default:
        value = entity[field];
    }

    return {
      ...obj,
      key,
      [field]: value,
    };
  }, {});
};

const normalizeWorkHours = (workHoursObj) => {
  let modified = {};

  for (let key in workHoursObj) {
    modified[key] = workHoursObj[key].map((v) => {
      let start = v.start;
      let end = v.end;
      return { start, end };
    });
  }

  return modified;
};

export const returnDetailsObject = (instance, entity) => {
  return entityFields[entity].fields.reduce((obj, field) => {
    let value = instance[field.key];
    switch (entity) {
      case 'sections':
        switch (field.key) {
          default:
            break;
        }
        break;
      case 'business':
        switch (field.key) {
          case 'active':
            value = instance.active;
            break;
          case 'categories':
            value = instance?.[field?.key]?.map((v) => ({
              value: v._id,
              label: v.name?.en ?? v.name?.he ?? v.name?.ar ?? v._id,
            }));
            break;
          case 'type':
            value = {
              value: instance[field.key],
              label: instance[field.key],
            };
            break;
          case 'region':
            value = {
              value: instance[field.key]._id,
              label:
                instance[field.key].name?.en ??
                instance[field.key].name?.he ??
                instance[field.key].name?.ar,
            };
            break;
          case 'phone':
            value = instance.contact?.phone;
            break;
          case 'website':
            value = instance.contact?.website;
            break;
          case 'deliveryEstimation':
            value = instance.deliveryEstimates.delivery;
            break;
          case 'pickupEstimation':
            value = instance.deliveryEstimates.pickup;
            break;
          case 'deliveryMethods':
            value =
              instance?.[field?.key] &&
              Object.keys(instance[field.key])
                .filter((v) => v !== 'EatIn')
                .map((v) => ({
                  value: v,
                  label: v,
                }));
            break;
          default:
            break;
        }
        break;
      case 'courier':
      case 'client':
        switch (field.key) {
          case 'phone':
            value = instance.phone;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return {
      ...obj,
      [field.key]: value,
    };
  }, {});
};

export const entityDetailsTabs = {
  business: [
    { title: 'Details', component: Details },
    { title: 'Merchandise', component: Merchandise },
    { title: 'Manager', component: Manager },
  ],
  courier: [
    { title: 'Details', component: Details },
    // { title: 'Orders', component: CourierOrders },
  ],
  client: [
    { title: 'Details', component: Details },
    // { title: 'Orders', component: CourierOrders },
  ],
  admin: [{ title: 'Details', component: Details }],
};

export const entityFields = {
  admin: {
    admin: true,
    canUpdate: false,
    canDeactivate: true,
    beforeHook: async (dispatch) => {},
    fields: [
      {
        key: '_id',
        text: 'objectId',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnCreate: false,
        showOnEdit: false,
        inputType: 'text',
      },
      {
        key: 'firstName',
        text: 'First name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'lastName',
        text: 'Last name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'email',
        text: 'Email',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj._id !== undefined && delete obj._id;
      obj?.active !== undefined && delete obj.active;
      return obj;
    },
  },
  sections: {
    fields: [
      {
        key: 'title',
        required: true,
        text: 'Title',
        type: 'input',
        inputType: 'text',
        localization: true,
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'description',
        text: 'Description',
        type: 'input',
        inputType: 'text',

        localization: true,
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj?.active !== undefined && delete obj.active;
      obj.items = obj?.items?.map((v) => v._id);
      obj.entity !== undefined && delete obj.entity;
      obj.listLabel !== undefined && delete obj.listLabel;
      obj.published !== undefined && delete obj.published;
      obj.created_at !== undefined && delete obj.created_at;
      return obj;
    },
  },
  items: {
    fields: [
      {
        key: 'name',
        text: 'Name',
        type: 'input',
        inputType: 'text',
        required: true,
        localization: true,
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'description',
        text: 'Description',
        type: 'input',
        localization: true,
        inputType: 'text',

        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'price',
        text: 'Price in agorot (1₪ = 100 agorot)',
        type: 'input',
        localization: false,
        inputType: 'number',
        required: true,
        value: 0,
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'primaryImage',
        text: 'Primary Item Image',
        type: 'image',
        value: '',
        showOnCreate: true,
        required: true,
        showOnEdit: true,
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj?.active !== undefined && delete obj.active;
      if (obj.primaryImage?.binary) obj.primaryImage = obj.primaryImage.binary;
      else obj?.primaryImage !== undefined && delete obj.primaryImage;
      obj.modifications = obj?.modifications?.map((v) => v._id) ?? [];
      obj?.entity !== undefined && delete obj.entity;
      obj?.listLabel !== undefined && delete obj.listLabel;
      obj?.published !== undefined && delete obj.published;
      obj?.created_at !== undefined && delete obj.created_at;
      obj?.vatPercentage !== undefined && delete obj.vatPercentage;
      obj?.checksum !== undefined && delete obj.checksum;
      return obj;
    },
  },
  modifications: {
    fields: [
      {
        required: true,
        key: 'name',
        text: 'Name',
        type: 'input',
        localization: true,
        inputType: 'text',
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'description',
        text: 'Description',
        type: 'input',
        localization: true,
        inputType: 'text',
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'type',
        text: 'Modification type',
        type: 'select',
        multi: false,
        required: true,
        value: { value: 'singlechoice', label: 'Single Choice' },
        options: [
          { value: 'singlechoice', label: 'Single Choice' },
          { value: 'multichoice', label: 'Multi Choice' },
        ],
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'minimumChoices',
        text: 'Minimum choices (if 0, then no selection is required)',
        type: 'input',
        localization: false,
        inputType: 'number',
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'maximumChoices',
        text: 'Maximum choices (can not exceed options amount)',
        type: 'input',
        localization: false,
        inputType: 'number',
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
      {
        key: 'options',
        text: 'Options',
        type: 'options',
        localization: true,
        inputType: 'text',
        value: '',
        editable: true,
        showOnEdit: true,
        showOnCreate: true,
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj.type = fields?.type?.value;
      obj?.active !== undefined && delete obj.active;
      obj?.entity !== undefined && delete obj.entity;
      obj?.listLabel !== undefined && delete obj.listLabel;
      obj?.published !== undefined && delete obj.published;
      obj?.created_at !== undefined && delete obj.created_at;
      obj?.validations !== undefined && delete obj.validations;
      obj = cleanObj(obj);
      return obj;
    },
  },
  courier: {
    canUpdate: true,
    canDeactivate: true,
    beforeHook: () => {},
    fields: [
      {
        key: '_id',
        text: 'objectId',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'active',
        text: 'Active',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'available',
        text: 'Available now',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'phone',
        text: 'Phone (should start with +972)',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
        // regex: /\D/,
      },
      {
        key: 'firstName',
        text: 'First name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'lastName',
        text: 'Last name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj?.active !== undefined && delete obj.active;
      obj?.available !== undefined && delete obj.available;
      return obj;
    },
  },
  client: {
    canUpdate: false,
    canDelete: true,
    beforeHook: () => {},
    fields: [
      {
        key: '_id',
        text: 'objectId',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'phone',
        text: 'Phone',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
        // regex: /\D/,
        editable: false,
      },
      {
        key: 'firstName',
        text: 'First name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
        editable: false,
      },
      {
        key: 'lastName',
        text: 'Last name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
        editable: false,
      },
      {
        key: 'deleted',
        text: 'Deleted',
        required: false,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: false,
        showOnEdit: false,
        inputType: 'text',
        editable: false,
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj?.active !== undefined && delete obj.active;
      return obj;
    },
  },
  'business-manager': {
    canUpdate: true,
    canDeactivate: true,
    beforeHook: () => {},
    fields: [
      {
        key: '_id',
        text: 'objectId',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'firstName',
        text: 'First name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'lastName',
        text: 'Last name',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'email',
        text: 'Email',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
    ],
    transform: (fields) => {
      let obj = fields;
      obj?.active !== undefined && delete obj.active;
      return obj;
    },
  },
  business: {
    canPublish: true,
    canUpdate: true,
    canDeactivate: true,
    beforeHook: async (dispatch) => {
      let region = await dispatch(getRegions());
      let categories = await dispatch(getCategories({ limit: 50, offset: 0 }));
      return {
        region,
        categories,
      };
    }, // get regions
    fields: [
      {
        key: '_id',
        text: 'objectId',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
      },
      {
        key: 'active',
        text: 'Active',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: false,
        inputType: 'text',
      },
      {
        key: 'available',
        text: 'Available now',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'published',
        text: 'Published',
        type: 'input',
        localization: false,
        value: '',
        editable: false,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'name',
        text: 'Business name',
        required: true,
        localization: true,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'phone',
        text: 'Phone',
        required: true,
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        // regex: /\D/,
        inputType: 'text',
      },
      {
        key: 'tagline',
        text: 'Tagline',
        localization: true,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
        required: true,
      },

      {
        key: 'type',
        text: 'Business Type',
        type: 'select',
        multi: false,
        value: { value: 'restaurant', label: 'restaurant' },
        options: [
          { value: 'restaurant', label: 'restaurant' },
          { value: 'retail', label: 'retail' },
        ],
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'deliveryMethods',
        text: 'Delivery Methods',
        type: 'select',
        multi: true,
        value: [
          { value: 'TakeAway', label: 'TakeAway' },
          { value: 'HomeDelivery', label: 'HomeDelivery' },
        ],
        options: [
          { value: 'TakeAway', label: 'TakeAway' },
          { value: 'HomeDelivery', label: 'HomeDelivery' },
        ],
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'categories',
        text: 'Categories',
        type: 'select',
        multi: true,
        value: [],
        options: [],
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'region',
        text: 'Work region',
        type: 'select',
        multi: false,
        value: null,
        options: [{ value: '', label: '' }],
        showOnCreate: true,
        showOnEdit: true,
        required: true,
      },
      {
        key: 'address',
        text: 'Address, including building number',
        type: 'address',
        localization: false,
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        required: true,
        inputType: 'address',
      },
      {
        key: 'city',
        text: 'City',
        type: 'input',
        localization: false,
        value: '',
        showOnCreate: true,
        required: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'sellerKey',
        text: 'PayMe Seller Key',
        type: 'input',
        required: true,
        localization: false,
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'text',
      },
      {
        key: 'marketPlaceFee',
        text: 'Market place fee with 2 decimals (0.10)',
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        inputType: 'number',
        required: true,
        min: 0,
        max: 1,
      },
      {
        key: 'website',
        text: 'Website',
        localization: false,
        type: 'input',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'primaryImage',
        text: 'Primary Business Image',
        // required: true,
        type: 'image',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
      },
      {
        key: 'location',
        text: 'Location',
        type: 'input',
        value: '',
        showOnCreate: false,
        showOnEdit: false,
        required: true,
      },
      {
        key: 'workHours',
        text: 'Work Hours',
        type: 'workHours',
        value: {},
        showOnCreate: true,
        showOnEdit: true,
        required: true,
      },
      {
        key: 'deliveryEstimation',
        text: 'Delivery estimation time',
        type: 'input',
        inputType: 'number',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        required: false,
      },
      {
        key: 'pickupEstimation',
        text: 'Pickup estimation time',
        type: 'input',
        inputType: 'number',
        value: '',
        showOnCreate: true,
        showOnEdit: true,
        required: false,
      },
      // {
      //   key: 'deliveryEstimates.delivery',
      //   text: 'Delivery estimation time',
      //   type: 'select',
      //   multi: false,
      //   value: null,
      //   options: Array(12)
      //     .fill('')
      //     .map((v, i) => ({ value: (i + 1) * 5, label: (i + 1) * 5 })),
      //   showOnCreate: true,
      //   showOnEdit: true,
      // },
      // {
      //   key: 'deliveryEstimates.pickup',
      //   text: 'pickup estimation time',
      //   type: 'select',
      //   multi: false,
      //   value: null,
      //   options: Array(12)
      //     .fill('')
      //     .map((v, i) => ({ value: (i + 1) * 5, label: (i + 1) * 5 })),
      //   showOnCreate: true,
      //   showOnEdit: true,
      // },
      // {
      //   key: 'boolean',
      //   text: 'הרשאת קבלת נסיעות באופן עצמאי',
      //   type: 'checkbox',
      //   value: true,
      // },
      // {
      //   key: 'company',
      //   text: 'חברה אליה משויך הנהג',
      //   type: 'select',
      //   multi: false,
      //   value: null,
      //   options: [{ value: '', label: '' }],
      // },
    ],
    transform: (fields) => {
      let obj = fields;
      obj.region = fields.region?.value;
      obj.type = fields.type?.value;
      obj.phone = fields.phone;
      obj.website = fields.website;
      !obj.website?.length && delete obj.website;
      obj.workHours =
        obj?.workHours && Object.keys(obj?.workHours)?.length > 0
          ? normalizeWorkHours(obj.workHours)
          : {};
      if (obj.primaryImage?.binary) {
        obj.primaryImage = obj.primaryImage.binary;
      } else if (obj.primaryImage !== undefined) {
        delete obj.primaryImage;
      }
      if (obj.location && Array.isArray(obj.location)) {
        obj.location = obj?.location?.join(',');
      } else {
        obj.location !== undefined && delete obj.location;
      }
      obj.deliveryMethods = fields?.deliveryMethods?.reduce(
        (total, item) => ({ ...total, [item.value]: true }),
        {}
      );
      obj.categories = fields.categories?.reduce((total, item) => [...total, item.value], []);
      obj.available !== undefined && delete obj.available;
      obj.deliveryEstimates = {
        delivery: +(obj.deliveryEstimation ?? 0),
        pickup: +(obj.pickupEstimation ?? 0),
      };
      obj.published !== undefined && delete obj.published;
      obj?.active !== undefined && delete obj.active;
      obj.deliveryEstimation !== undefined && delete obj.deliveryEstimation;
      obj.pickupEstimation !== undefined && delete obj.pickupEstimation;
      return obj;
    },
  },
};
