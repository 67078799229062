import { createMessage } from './message';

import { requestWrapper, supportedRequestMethods } from '../../API';

import types from '../types/categories';
import { toFormData } from 'utils';

export const getCategories = ({ offset, limit }) => async (dispatch) => {
  dispatch({ type: types.GET_CATEGORIES_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/categories?offset=${offset}&limit=${limit}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.GET_CATEGORIES_SUCCESS,
      payload: response.data.data.results,
    });
    return response.data.data.results;
  } else {
    dispatch({
      type: types.GET_CATEGORIES_FAIL,
    });
    let message =
      response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const createCategory = (obj) => async (dispatch) => {
  dispatch({ type: types.CREATE_CATEGORY_ATTEMPT });

  let body = toFormData(obj);

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin-api/categories`,
    body,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.CREATE_CATEGORY_SUCCESS,
    });
    dispatch(getCategories({ limit: 20, offset: 0 }));
  } else {
    dispatch({
      type: types.CREATE_CATEGORY_FAIL,
    });
    let message =
      response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const deleteCategory = (_id) => async (dispatch) => {
  dispatch({ type: types.DELETE_CATEGORY_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `admin-api/categories/${_id}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.DELETE_CATEGORY_SUCCESS,
    });
    dispatch(getCategories({ limit: 20, offset: 0 }));
  } else {
    dispatch({
      type: types.DELETE_CATEGORY_FAIL,
    });
    let message =
      response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const updateCategory = (obj) => async (dispatch) => {
  let _id = obj._id;
  delete obj._id;
  dispatch({ type: types.UPDATE_CATEGORY_ATTEMPT });

  let body = toFormData(obj);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/categories/${_id}`,
    body,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.UPDATE_CATEGORY_SUCCESS,
    });
    dispatch(getCategories({ limit: 20, offset: 0 }));
  } else {
    dispatch({
      type: types.UPDATE_CATEGORY_FAIL,
    });
    let message =
      response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};
