import React from 'react';
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Spinner,
  Row,
} from 'reactstrap';

import { connect } from 'react-redux';
import {
  sendCredentials,
  login,
  resetCredentials,
} from '../../redux/actions/auth';
import Timer from 'react-compound-timer';
import { useState, useEffect } from 'react';
import { project } from '../../config/index';

/**
 * Component for user login
 */
const Login = (props) => {
  const [state, setState] = useState({
    // For the sake of convenience, If not in production, check if a test user is provided. Mike, I challenge you to try to make this check more optimal.
    email:
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_TEST_USER_EMAIL
          ? process.env.REACT_APP_TEST_USER_EMAIL
          : ''
        : '',
    otp: '',
    visibility: true,
  });

  useEffect(() => {
    document.body.classList.toggle('login-page');
    return () => document.body.classList.toggle('login-page');
  }, []);

  useEffect(() => {
    if (props.auth.authenticated) {
      props.history.push('/dashboard');
    }
  });

  /**
   * function for handling email
   */
  const handleOTP = async (e) => {
    const { email } = state;
    if (email !== '') {
      let data = await props.sendCredentials(email);
      setState({
        ...state,
        visibility: true,
        otp: data?.data?.user?.otp ? data.data.user.otp : '', // QoL Dev Feature
      });
    }
  };

  /**
   * function for handling login with email and otp
   */
  const handleLogin = (e) => {
    const { email, otp } = state;
    if (otp !== '') {
      props.login(email, otp);
    }
  };

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleTimerStop = () => {
    setState({ ...state, visibility: false });
  };

  if (props.auth.verifyingSession) {
    return (
      <div className="text-center">
        <div style={{ paddingTop: 300 }}>
          <Spinner size={'3em'} />
        </div>
      </div>
    );
  }

  return (
    <div className="content" style={{ paddingTop: 100 }}>
      <Container className="login-container">
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              !props.auth.otpSent && handleOTP();
              props.auth.otpSent && handleLogin();
            }}
          >
            <Card className="card-login card-white">
              <CardHeader>
                <img
                  alt={project.name}
                  style={{ width: 200, marginTop: -100, marginBottom: 30 }}
                  src={project.img}
                />
                <h1>{project.login.mainText}</h1>
                {props.auth.otpSent ? (
                  <>
                    <CardBody>
                      <Row
                        style={{
                          display: state.visibility ? 'flex' : 'none',
                        }}
                      >
                        <Col
                          style={{
                            fontSize: '40px',
                            color: 'var(--primary-color)',
                          }}
                        >
                          {state.visibility && (
                            <Timer
                              formatValue={(value) =>
                                `${value < 10 ? `0${value}` : value}`
                              }
                              initialTime={120000}
                              direction="backward"
                              startImmediately={true}
                              checkpoints={[
                                {
                                  time: 0,
                                  callback: handleTimerStop,
                                },
                              ]}
                            >
                              {() => (
                                <React.Fragment>
                                  <Timer.Minutes />:
                                  <Timer.Seconds />
                                </React.Fragment>
                              )}
                            </Timer>
                          )}
                        </Col>
                      </Row>

                      <InputGroup
                        className={classnames({
                          'input-group-focus': false,
                        })}
                      >
                        <Input
                          placeholder="otp"
                          value={state.otp}
                          type="text"
                          name="otp"
                          onChange={handleInput}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="tim-icons icon-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      {state.visibility && (
                        <Row>
                          <Col>
                            <Button
                              className="btn-round"
                              color="primary"
                              size="md"
                              type="submit"
                            >
                              {project.login.continueButtonText}
                            </Button>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <Button
                            className="btn-round"
                            color="primary"
                            size="md"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOTP();
                            }}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {project.login.sendAgainButtonText}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            className="btn-round"
                            color="primary"
                            size="md"
                            onClick={(e) => {
                              e.preventDefault();
                              props.resetCredentials();
                              setState({ ...state, visibility: true });
                            }}
                          >
                            {project.login.goBackButtonText}
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <InputGroup>
                        <Input
                          placeholder="Email"
                          value={state.email}
                          type="email"
                          name="email"
                          onChange={handleInput}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="btn-round"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOTP();
                        }}
                        bssize="lg"
                        type="submit"
                      >
                        {project.login.sendButtonText}
                      </Button>
                    </CardFooter>
                  </>
                )}
              </CardHeader>
            </Card>
          </form>
        </Col>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendCredentials,
  resetCredentials,
  login,
})(Login);
