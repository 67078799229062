export const GET_BMS_ATTEMPT = 'GET_BMS_ATTEMPT';
export const GET_BMS_SUCCESS = 'GET_BMS_SUCCESS';
export const GET_BMS_FAIL = 'GET_BMS_FAIL';

export const CREATE_BM_ATTEMPT = 'CREATE_BM_ATTEMPT';
export const CREATE_BM_SUCCESS = 'CREATE_BM_SUCCESS';
export const CREATE_BM_FAIL = 'CREATE_BM_FAIL';

export const DELETE_BM_ATTEMPT = 'DELETE_BM_ATTEMPT';
export const DELETE_BM_SUCCESS = 'DELETE_BM_SUCCESS';
export const DELETE_BM_FAIL = 'DELETE_BM_FAIL';

export const SET_BMS = 'SET_BMS';
export const SET_BMS_PAGE = 'SET_BMS_PAGE';
