import Note from 'components/Note';
import Spinner from 'components/Spinner';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { getRegionCouriers } from 'redux/actions/order';
import { transferDelivery } from 'redux/actions/order';
import { cancelOrder } from 'redux/actions/order';
import { getOrder } from 'redux/actions/order';
import { localeStringOptions } from 'utils';
import { RedStar } from './Entities/components/CreateEntityField';

const cancelReasons = [
  { reason: 'closingSoon', text: 'Closing soon', details: 'Closing soon...' },
  {
    reason: 'itemUnavailable',
    text: 'Items unavailable',
    details: 'The orders items are unavailable...',
  },
  {
    reason: 'commentProblem',
    text: 'Problems with clients comment',
    details: 'There are issues with the client comment...',
  },
  { reason: 'other', text: 'Other', details: '' },
];

const terminationTexts = {
  itemUnavailable: 'Items unavailable',
  closingSoon: 'Closing soon',
  commentProblem: 'Problems with clients comment',
  noResponse: 'No response from business',
  other: 'Other',
};

const OrderDetails = ({ match }) => {
  const dispatch = useDispatch();
  const { order, orderLoading, cancelingOrder, couriers, transferringOrder } = useSelector(
    (state) => state.orders
  );

  const [courierForTransfer, setCourierForTransfer] = useState({
    value: {},
    label: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelData, setCancelData] = useState({
    reason: { value: '', label: '' },
    details: '',
  });

  useEffect(() => {
    match.params.id &&
      (() => {
        dispatch(getOrder(match.params.id));
      })();
  }, [match.params.id, dispatch]);

  useEffect(() => {
    order?.region && dispatch(getRegionCouriers(order?.region));
  }, [order, dispatch]);

  const handleCancelOrder = () => {
    dispatch(cancelOrder(order._id, cancelData.reason.value, cancelData.details));
    toggleCancelModal();
    dispatch(getOrder(match.params.id));
  };

  const handleTransferOrder = () => {
    courierForTransfer?.value?._id &&
      dispatch(transferDelivery(order._id, courierForTransfer.value._id));
    toggleModal();
    dispatch(getOrder(match.params.id));
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleCancelModal = () => setCancelModalOpen(!cancelModalOpen);

  return (
    <>
      <Modal isOpen={cancelModalOpen} toggle={toggleCancelModal} backdrop={true}>
        <ModalHeader>Are you sure you want to cancel this order?</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <FormGroup>
                  <Label for={'reason'}>
                    <RedStar />
                    Please provide reason for cancellation
                  </Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    id="reason"
                    name="singleSelect"
                    components={{
                      ClearIndicator: () => null,
                    }}
                    value={cancelData.reason}
                    onChange={(e) => {
                      setCancelData({
                        ...cancelData,
                        reason: e,
                        details: cancelReasons.find((v) => v.reason === e.value).details,
                      });
                    }}
                    options={cancelReasons.map((v) => ({
                      value: v.reason,
                      label: v.text,
                    }))}
                    isMulti={false}
                    placeholder="Choose"
                    noOptionsMessage={() => 'No more options'}
                    closeMenuOnSelect={true}
                  />
                </FormGroup>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup>
                <Input
                  name="id"
                  id="input-identifier"
                  value={cancelData.details}
                  onChange={(e) => setCancelData({ ...cancelData, details: e.target.value })}
                  placeholder="Filter by order number or business name.."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button
                disabled={cancelingOrder.loading || !cancelData.details || !cancelData.reason.value}
                color="danger"
                onClick={handleCancelOrder}
              >
                Cancel order
              </Button>
            </Col>
            <Col xs={6}>
              <Button disabled={cancelingOrder.loading} color="info" onClick={toggleCancelModal}>
                Do not cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={true}>
        <ModalHeader>Select courier for transfer</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Note subtitle="Please note that the only validation there are on couriers is that they are available and in order's region, other than that, please use the map to see their location" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="singleSelect"
                components={{
                  ClearIndicator: () => null,
                }}
                value={courierForTransfer}
                onChange={(e) => {
                  setCourierForTransfer(e);
                }}
                options={couriers?.[order?.region]?.map((v) => ({
                  value: v,
                  label: `${v?.firstName} ${v?.lastName} ${v?.phone}`,
                }))}
                placeholder="Choose courier"
                noOptionsMessage={() => 'No more options'}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Button
                disabled={transferringOrder.loading}
                color="danger"
                onClick={handleTransferOrder}
              >
                Transfer order
              </Button>
            </Col>
            <Col xs={6}>
              <Button disabled={transferringOrder.loading} color="info" onClick={toggleModal}>
                Do not transfer
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="content">
        <Card style={{ padding: 10 }}>
          {orderLoading ? (
            <Spinner />
          ) : (
            <>
              <Row>
                <Col xs={12} className="text-left">
                  <Link to={`/list-order`}>
                    <Button
                      className="btn-fill mx-1"
                      color="primary"
                      type="submit"
                      style={{ width: '250px' }}
                    >
                      Go back
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h3>
                    #{order?.number}
                    {' - '}
                    {order?.created_at !== undefined &&
                      new Date(order.created_at).toLocaleString('he-il', localeStringOptions)}
                    {' - '}
                    {order?.business?.name}
                  </h3>
                </Col>
                <Col xs={12}>
                  <p style={styles.headerText}>
                    {order?.deliveryMethod} - <span style={{ color: 'red' }}>{order?.state}</span>
                  </p>
                </Col>
                <hr />
                <Col xs={12}>
                  <p style={styles.headerText}>UID: {order?._id}</p>
                </Col>
                <Col xs={12}>
                  <div>
                    <p style={styles.headerText}>Business: {order?.business?.name}</p>
                    <p>
                      Address: {order?.business?.address} {order?.business?.city}{' '}
                    </p>
                    <p>Contact: {order?.business?.contact?.phone}</p>
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    <p style={styles.headerText}>
                      Client: {order?.client?.firstName} {order?.client?.lastName}
                    </p>
                    <p>
                      Contact: {order?.client?.phone} {order?.client?.email}
                    </p>
                    <p>Comment: {order?.clientComment}</p>
                  </div>
                </Col>
                <Col xs={12}>
                  <p style={styles.headerText}>Items:</p>
                  {order?.items.map((v, i) => (
                    <div key={i + v._id}>
                      <p>
                        <b>
                          {v?.name} x {v?.count}
                        </b>
                        {' - '} Price: {v?.price}
                      </p>
                      <div>
                        {v.modifications?.map((mod, i) => (
                          <p key={i + mod._id}>
                            {' '}
                            {mod?.name} - {mod?.price}{' '}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </Col>
                <Col>
                  <p style={styles.headerText}> Delivery location: </p>
                  <div>
                    <p>
                      Address: {order?.deliveryLocation?.address} {order?.deliveryLocation?.city}
                    </p>
                    <p>
                      Floor: {order?.deliveryLocation?.floor}, Apartment:{' '}
                      {order?.deliveryLocation?.apartment}{' '}
                    </p>
                    <p>Details: {order?.deliveryLocation?.details}</p>
                    <p>
                      Coordinates: {order?.deliveryLocation?.location?.coordinates[1]}
                      {','}
                      {order?.deliveryLocation?.location?.coordinates[0]}
                    </p>
                  </div>
                </Col>

                <Col xs={12}>
                  <p style={styles.headerText}>Charges details:</p>
                  <p>Items price: {order?.totalItemPrice}</p>
                  <p>Delivery price: {order?.totalDeliveryPrice}</p>
                  <p>Total price: {order?.totalOrderPrice}</p>
                </Col>
              </Row>
              {!!order?.termination?.reason && (
                <Row>
                  <Col xs={12}>
                    <p style={styles.headerText}>Termination details</p>
                  </Col>
                  {!!order?.termination?.time && (
                    <Col xs={12}>
                      <p style={styles.headerText}>Time: {order?.termination?.time}</p>
                    </Col>
                  )}
                  <Col xs={12}>
                    <p style={styles.headerText}>
                      Reason: {terminationTexts?.[order?.termination?.reason]}
                    </p>
                  </Col>
                  {!!order?.termination?.details && (
                    <Col xs={12}>
                      <p style={styles.headerText}>Details: {order?.termination?.details}</p>
                    </Col>
                  )}
                  {!!order?.termination?.instigatingEntity && (
                    <Col xs={12}>
                      <p style={styles.headerText}>By: {order?.termination?.instigatingEntity}</p>
                    </Col>
                  )}
                  {!!order?.termination?.instigator && (
                    <Col xs={12}>
                      <p style={styles.headerText}>
                        Instigator id: {order?.termination?.instigator}
                      </p>
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          <CardFooter>
            <Row>
              {['placed', 'accepted', 'awaitingPickup', 'inDelivery'].includes(order?.state) && (
                <>
                  <Button disabled={cancelingOrder.loading} onClick={toggleCancelModal}>
                    Cancel order
                  </Button>
                  <Button disabled={transferringOrder.loading} onClick={toggleModal}>
                    Transfer order
                  </Button>
                </>
              )}
            </Row>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default OrderDetails;

const styles = {
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },
};
