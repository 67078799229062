import types from '../types/regions';
import { createMessage } from './message';
import { requestWrapper, supportedRequestMethods } from '../../API';

export const getRegions = () => async (dispatch) => {
  dispatch({ type: types.REGIONS_LOAD_ATTEMPT });

  let requestParams = { 
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/regions`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.REGIONS_LOAD_SUCCESS,
      payload: response.data.data, 
    });
    return response.data.data;
  } else {
    dispatch({
      type: types.REGIONS_LOAD_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message
    if (message) dispatch(createMessage(message))
  }
};
export const createRegion = ({ country = 'IL', name, geozone }) => async (
  dispatch
) => {
  dispatch({ type: types.REGION_CREATE_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin-api/regions`,
    body: { country, name, geozone },
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.REGION_CREATE_SUCCESS,
      payload: { ...response.data.data },
    });
  } else {
    dispatch({
      type: types.REGION_CREATE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message
    if (message) dispatch(createMessage(message))
  }
};
export const deleteRegion = (_id) => async (dispatch) => {
  dispatch({ type: types.REGION_DELETE_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `admin-api/regions/${_id}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.REGION_DELETE_SUCCESS,
      payload: _id,
    });
  } else {
    dispatch({
      type: types.REGION_DELETE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message
    if (message) dispatch(createMessage(message))
  }
};
