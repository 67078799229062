import { GET_REGION_COURIERS_SUCCESS } from 'redux/types/orders';
import { PROBLEMATIC_ORDERS_LOADED } from '../types/map';

const initialState = {
  delayed: [],
  unassigned: [],
  failed: [],
  available: [],
  taken: [],
  orders: [],
  unavailable: [],
  businesses: [],
  couriers: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REGION_COURIERS_SUCCESS:
      return {
        ...state,
        couriersLoading: false,
        couriers: {
          ...state.couriers,
          [action.payload.region]: {
            available: action.payload.couriers.available,
            inDelivery: action.payload.couriers.inDelivery,
          },
        },
      };
    case PROBLEMATIC_ORDERS_LOADED:
      let delayed = action.payload.delayedOrders?.map((v) => v._id);
      let unassigned = action.payload.unassignedDeliveries?.map((v) => v._id);
      let failed = action.payload.failedCaptures?.map((v) => v._id);
      return {
        ...state,
        delayed: [
          ...state.delayed.filter((v) => delayed.includes(v._id)),
          ...action.payload.delayedOrders?.filter(
            (v) => !state.delayed.map((item) => item._id).includes(v._id)
          ),
        ],
        unassigned: [
          ...state.unassigned.filter((v) => unassigned.includes(v._id)),
          ...action.payload.unassignedDeliveries?.filter(
            (v) => !state.unassigned.map((item) => item._id).includes(v._id)
          ),
        ],
        failed: [
          ...state.failed.filter((v) => failed.includes(v._id)),
          ...action.payload.failedCaptures?.filter(
            (v) => !state.failed.map((item) => item._id).includes(v._id)
          ),
        ],
      };
    default:
      return state;
  }
}
