import React, { useEffect, useState } from 'react';
import CreateEntityField from '../../../CreateEntityField';
import { entityFields } from '../../../../entities';
import OptionsForm from './OptionsForm';
import Spinner from '../../../../../../../components/Spinner';
import { Row, Col, Form, Button, Collapse } from 'reactstrap';
import { allowedLocales } from '../../../../../../../config/locales';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndListMerchEdit from './DndListMerchEdit';
import DndMerchEditItem from './DndMerchEditItem';
import { DndProvider } from 'react-dnd';
import {
  deactivateMerchEntity,
  publishMerchEntity,
  reactivateMerchEntity,
  unpublishMerchEntity,
  updateMerchEntity,
} from '../../../../../../../redux/actions/merchandise';
import Note from 'components/Note';
import CropperUpload from 'components/CropperUpload';

const EditSection = ({ editOpen, setEditOpen, tab, singleMerchEntity, setSingleMerchEntity }) => {
  const dispatch = useDispatch();
  const {
    merchandise: {
      publishing,
      unpublishing,
      updating,
      items,
      modifications,
      deactivating,
      reactivating,
    },
  } = useSelector((state) => state);
  const [options, setOptions] = useState([]);
  const [list, setList] = useState([]);
  const [langTab, setLangTab] = useState(0);

  const handleOnChange = (e, field, locale, isMulti) => {
    switch (field.type) {
      case 'select':
        if (isMulti && e) {
          setSingleMerchEntity({
            ...singleMerchEntity,
            [field.key]: [...e],
          });
        } else if (isMulti && !e) {
          setSingleMerchEntity({
            ...singleMerchEntity,
            [field.key]: [],
          });
        } else {
          setSingleMerchEntity({
            ...singleMerchEntity,
            [field.key]: e,
          });
        }
        break;
      case 'checkbox':
        setSingleMerchEntity({
          ...singleMerchEntity,
          [field.key]: e.target.checked,
        });
        break;
      default:
        setSingleMerchEntity({
          ...singleMerchEntity,
          [field.key]: field?.localization
            ? {
                ...(singleMerchEntity?.[field.key] || {}),
                [locale]: e.target.value,
              }
            : e.target.value,
        });
        break;
    }
  };

  const handleMerchItemUpdate = async () => {
    const entity = {
      ...singleMerchEntity,
      [singleMerchEntity.listLabel]: list.length
        ? list
        : singleMerchEntity[singleMerchEntity.listLabel],
    };
    if (tab === 'modifications') {
      entity.options = options;
    }
    let obj = entityFields[singleMerchEntity.entity].transform(entity);
    if (tab === 2 && !obj.type) {
      return;
    }
    await dispatch(updateMerchEntity(singleMerchEntity.entity, obj));
  };

  const setImage = (img) => {
    setSingleMerchEntity({
      ...singleMerchEntity,
      primaryImage: {
        binary: img,
      },
    });
  };

  const hide = () => {
    setEditOpen(false);
    setSingleMerchEntity({});
  };

  const handleMerchPublish = async () => {
    if (singleMerchEntity?.published) {
      await dispatch(unpublishMerchEntity(singleMerchEntity?.entity, singleMerchEntity?._id));
      setSingleMerchEntity({ ...singleMerchEntity, published: false });
    } else {
      await dispatch(publishMerchEntity(singleMerchEntity?.entity, singleMerchEntity?._id));
      setSingleMerchEntity({ ...singleMerchEntity, published: true });
    }
  };

  useEffect(() => {
    if (tab === 'modifications') {
      !!singleMerchEntity.options.length && setOptions(singleMerchEntity.options);
    }
  }, [tab, singleMerchEntity.options]);

  const handleEntityActivation = async () => {
    if (singleMerchEntity?.active === true) {
      await dispatch(deactivateMerchEntity(singleMerchEntity?.entity, singleMerchEntity._id));
      setSingleMerchEntity({ ...singleMerchEntity, active: false });
    } else if (singleMerchEntity?.active === false) {
      await dispatch(reactivateMerchEntity(singleMerchEntity?.entity, singleMerchEntity._id));
      setSingleMerchEntity({ ...singleMerchEntity, active: true });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}></div>
      <Collapse isOpen={editOpen}>
        <Tabs selectedIndex={langTab} onSelect={(index) => setLangTab(index)}>
          <div style={styles.centerContent}>
            <span style={styles.bigFont}>Localized fields</span>
          </div>
          <TabList style={{ display: 'flex' }}>
            {allowedLocales.map((locale, index) => (
              <Tab style={styles.tab} key={index}>
                <div style={styles.bigFont}>{locale}</div>
              </Tab>
            ))}
          </TabList>
          {allowedLocales.map((locale, index) => (
            <TabPanel key={index}>
              <Form>
                {!!entityFields?.[tab]?.fields?.filter(
                  (v) => v.localization && v.type !== 'options'
                ).length && (
                  <>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => v.localization && v.type !== 'options')
                      .map((field, index) => {
                        return (
                          <CreateEntityField
                            key={index}
                            value={singleMerchEntity?.[field?.key]}
                            checked={singleMerchEntity?.[field?.key]}
                            {...{
                              field,
                              handleOnChange,
                              locale,
                            }}
                          />
                        );
                      })}
                  </>
                )}
                {tab === 'modifications' && <OptionsForm {...{ options, setOptions }} />}
                {!!entityFields?.[tab]?.fields?.filter(
                  (v) => !v.localization && !['options', 'image'].includes(v.type)
                ).length && (
                  <>
                    <div style={styles.centerContent}>
                      <span style={styles.bigFont}>Non-localized fields</span>
                    </div>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => !v.localization && !['options', 'image'].includes(v.type))
                      .filter((v) => {
                        if (
                          tab === 'modifications' &&
                          singleMerchEntity?.type?.value === 'singlechoice' &&
                          ['minimumChoices', 'maximumChoices'].includes(v.key)
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .map((field, index) => {
                        return (
                          <CreateEntityField
                            key={index}
                            value={singleMerchEntity?.[field?.key] ?? ''}
                            checked={singleMerchEntity?.[field?.key] ?? false}
                            {...{
                              field,
                              handleOnChange,
                              locale,
                              max: 2,
                            }}
                          />
                        );
                      })}
                  </>
                )}
                {!!entityFields?.[tab]?.fields?.filter((v) => v.type === 'image').length && (
                  <Row>
                    {entityFields?.[tab]?.fields
                      ?.filter((v) => v.type === 'image')
                      .map((field, index) => {
                        return (
                          <Col key={index} xs={12}>
                            <CropperUpload
                              aspect={16 / 9}
                              aspectText={'16/9'}
                              img={singleMerchEntity?.[field?.key]?.path}
                              {...{ setImage }}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                )}
              </Form>
            </TabPanel>
          ))}
          {editOpen && tab !== 'modifications' && (
            <>
              <CreateEntityField
                value={singleMerchEntity?.[singleMerchEntity?.listLabel] ?? ''}
                checked={false}
                {...{
                  field: {
                    key: singleMerchEntity?.listLabel,
                    text: singleMerchEntity?.listLabel,
                    type: 'select',
                    multi: true,
                    value: [],
                    options: (singleMerchEntity?.listLabel === 'items' ? items : modifications).map(
                      (v) => {
                        return {
                          name: v.name,
                          _id: v._id,
                          value: v._id,
                          label: v.name?.en ?? v.name?.he ?? v.name?.ar ?? v._id,
                        };
                      }
                    ),
                  },
                  handleOnChange,
                  // locale: 'en',
                }}
              />
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <DndProvider backend={HTML5Backend}>
                  {!!singleMerchEntity?.[singleMerchEntity?.listLabel]?.length && (
                    <>
                      <DndListMerchEdit
                        {...{
                          data: singleMerchEntity?.[singleMerchEntity?.listLabel],
                          setData: (data) => {
                            setList(data);
                          },
                          entity: tab,
                          Component: DndMerchEditItem,
                          title: singleMerchEntity?.listLabel,
                        }}
                      />
                    </>
                  )}
                </DndProvider>
              </Row>
            </>
          )}
          <Note title="Please note that modifications must first be added to items before publishing and items must first be added to sections before publishing" />
          {updating?.[tab] ||
          publishing?.[tab] ||
          unpublishing?.[tab] ||
          deactivating?.[tab] ||
          reactivating?.[tab] ? (
            <Spinner />
          ) : (
            <>
              <Button color="info" onClick={handleMerchItemUpdate}>
                Update
              </Button>
              {singleMerchEntity?.active !== undefined && !singleMerchEntity?.published && (
                <Button color="danger" onClick={handleEntityActivation}>
                  {singleMerchEntity.active ? 'Deactivate' : 'Reactivate'}
                </Button>
              )}
              <Button color="info" onClick={handleMerchPublish}>
                {singleMerchEntity?.published ? 'Unpublish' : 'Publish'}
              </Button>
              <Button color="danger" onClick={hide}>
                Cancel
              </Button>
            </>
          )}
        </Tabs>
      </Collapse>
    </>
  );
};

export default EditSection;

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};
