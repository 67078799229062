import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import dashboard from './dashboard';
import map from './map';
import entities from './entities';
import merchandise from './merchandise';
import managers from './business-manager';
import categories from './categories';
import orders from './orders';
import regions from './regions';

const rootReducer = combineReducers({
  categories,
  managers,
  auth,
  message,
  dashboard,
  map,
  entities,
  merchandise,
  orders,
  regions,
});

export default rootReducer;
