/*

Using the notifications alert provided in the template and a custom error reducer this can display alert on any screen. Only provide a string for the alert text.

See error actions in the redux folder for more details.

Placed at the App component inside some container. 

*/
import React, { Component } from 'react';
import NotificationAlert from 'react-notification-alert';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

let options = {};

class RideNotification extends Component {
  notify() {
    this.refs.notify.notificationAlert(options);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.notification?.id !== this.props?.notification?.id &&
      this.props?.notification?.id
    ) {
      options = {
        place: 'tl',
        message: (
          <div style={styles.notification}>
            <div>{this.props.notification?.text}</div>
            {/* <Link
              style={styles.link}
              to={`/map/${this.props.notification?.rideId}`}
            >
              לחץ כאן
            </Link> */}
          </div>
        ),
        type: 'primary',
        icon: 'now-ui-icons ui-1_bell-53',
        autoDismiss: 5,
      };
      this.refs.notify.notificationAlert(options);
    }
  }
  render() {
    return (
      <div>
        <NotificationAlert ref="notify" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.message.notification,
});

export default connect(mapStateToProps, {})(RideNotification);

const styles = {
  notification: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: 'white',
    fontSize: '20px',
  },
};
