// @ts-nocheck
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip,
} from 'reactstrap';

import { project } from '../../config';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/auth';

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    collapseOpen: false,
    modalSearch: false,
    color: 'navbar-transparent',
  });

  useEffect(() => {
    window.addEventListener('resize', updateColor);
    return () => {
      window.removeEventListener('resize', updateColor);
    };
    // eslint-disable-next-line
  }, []);

  // function that adds color white/transparent to the navbar on resize (is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && state.collapseOpen) {
      setState({
        ...state,
        color: 'bg-white',
      });
    } else {
      setState({
        ...state,
        color: 'navbar-transparent',
      });
    }
  };
  // function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (state.collapseOpen) {
      setState({
        ...state,
        color: 'navbar-transparent',
      });
    } else {
      setState({
        ...state,
        color: 'bg-white',
      });
    }
    setState({
      ...state,
      collapseOpen: !state.collapseOpen,
    });
  };

  // function is to open the Search modal
  const toggleModalSearch = () => {
    setState({
      ...state,
      modalSearch: !state.modalSearch,
    });
  };

  return (
    <>
      <Navbar
        className={classNames('navbar-absolute', {
          [state.color]: props.location.pathname.indexOf('full-screen-map') === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip209599" placement="right">
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: props.sidebarOpened,
              })}
            >
              <button className="navbar-toggler" type="button" onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={state.collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={project.icon} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">{project.name}</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink
                    tag="li"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <DropdownItem className="nav-item">Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal modalClassName="modal-search" isOpen={state.modalSearch} toggle={toggleModalSearch}>
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminNavbar;
