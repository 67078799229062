import React, { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './DndListForPreview';

const Item = memo(function Item({
  _id,
  text,
  moveItem,
  findItem,
  item,
  openEdit,
  entity,
}) {
  const originalIndex = findItem(_id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.ITEM,
      item: { _id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { _id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
      },
    }),
    [_id, originalIndex, moveItem]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ITEM,
      canDrop: () => false,
      hover({ _id: draggedId }) {
        if (draggedId !== _id) {
          const { index: overIndex } = findItem(_id);
          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem]
  );
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={(node) => entity === 'sections' && drag(drop(node))}
      style={{ opacity, ...styles.item }}
    >
      <div style={{ color: 'black' }}>{text}</div>
      <div style={{ ...styles.buttonsContainer }}>
        <div style={styles.moveButton} onClick={() => {
          openEdit(entity, item)
        }}>
          <i
            style={{
              fontSize: 25,
              fontWeight: 800,
              marginRight: 10,
            }}
            className={`tim-icons icon-pencil`}
          />
        </div>
        <div>
          <i
            style={{
              color: item?.published ? '#33ff33' : '#ff3333',
              fontSize: 30,
              fontWeight: 800,
              marginRight: 10,
            }}
            className={`tim-icons ${
              item?.published ? 'icon-check-2' : 'icon-simple-remove'
            }`}
          />
        </div>
        {entity === 'sections' && (
          <div style={styles.moveButton}>
            <i
              style={{
                fontSize: 25,
                fontWeight: 800,
                marginRight: 10,
              }}
              className={`tim-icons icon-tap-02`}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default Item;

const styles = {
  buttonsContainer: {
    display: 'flex',
  },
  item: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ccc',
    width: '100%',
    border: '1px dashed gray',
  },
  moveButton: {
    cursor: 'move',
  },
};
