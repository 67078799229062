import React from 'react';
import MapScreen from './MapScreen';

const MapScreenWrapper = (props) => {
  return (
    <div className="content">
      <MapScreen {...props} />
    </div>
  );
};

export default MapScreenWrapper;
