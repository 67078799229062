export const DATA_LOADING = 'DATA_LOADING';

export const STATISTICS_LOADED = 'STATISTICS_LOADED';
export const STATISTICS_LOAD_FAIL = 'STATISTICS_LOAD_FAIL';

export const GENERATE_RECEIPT_REPORT_ATTEMPT =
  'GENERATE_RECEIPT_REPORT_ATTEMPT';
export const GENERATE_RECEIPT_REPORT_SUCCESS =
  'GENERATE_RECEIPT_REPORT_SUCCESS';
export const GENERATE_RECEIPT_REPORT_FAIL = 'GENERATE_RECEIPT_REPORT_FAIL';

export const GENERATE_ORDER_REPORT_ATTEMPT =
  'GENERATE_ORDER_REPORT_ATTEMPT';
export const GENERATE_ORDER_REPORT_SUCCESS =
  'GENERATE_ORDER_REPORT_SUCCESS';
export const GENERATE_ORDER_REPORT_FAIL = 'GENERATE_ORDER_REPORT_FAIL';
