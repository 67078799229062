import { entityFields } from './views/pages/Entities/entities';
import * as Sentry from '@sentry/react';

export const toFormData = (obj) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    if (key === 'primaryImage' || key === 'image') {
      formData.append(key, obj[key], '1.jpeg');
    } else if (key && typeof obj[key] === 'object') {
      if (key === 'workHours') {
        Object.keys(obj[key]).forEach((k) => {
          obj[key][k].forEach((_, i) => {
            formData.append(`${key}[${k}][${i}][start]`, obj[key][k][i].start);
            formData.append(`${key}[${k}][${i}][end]`, obj[key][k][i].end);
          });
        });
      } else {
        Object.keys(obj[key]).forEach((k) => {
          formData.append(`${key}[${k}]`, obj[key][k]);
        });
      }
    } else if (key && Array.isArray(obj[key])) {
      obj[key].forEach((val) => {
        formData.append(key, val);
      });
    } else if (key) {
      formData.append(key, obj[key]);
    }
  });

  return formData;
};

export const validate = (model, obj) => {
  let requiredFields = entityFields[model].fields.filter((v) => v.required);
  let errors = [];
  let isValid = true;
  if (!requiredFields.length) return { isValid, errors };
  requiredFields.forEach((field) => {
    if (
      (field.inputType === 'text' && field.localization && !obj?.[field.key]?.['en']?.length) ||
      (field.type === 'image' && !obj[field.key]) ||
      (field.type === 'select' && !obj[field.key]?.length) ||
      (field.inputType === 'number' &&
        ((field.max !== undefined && +obj[field.key] > field.max) ||
          (field.min !== undefined && +obj[field.key] < field.min))) ||
      (field.type === 'workHours' && !Object.keys(obj[field.key]).length) ||
      (field.type === 'address' && !obj[field.key]?.length) ||
      (field.inputType === 'text' && !field.localization && !obj[field.key]?.length)
    ) {
      // check for en locale as it is the fallback
      isValid = false;
      errors.push(field.text);
    }
  });
  return { isValid, errors };
};

export const localeStringOptions = {
  timeZone: 'Asia/Jerusalem',
  minute: '2-digit',
  hour: '2-digit',
};

export const timestampToTime = (timestamp) => {
  let hours = Math.floor(timestamp / 36e5).toFixed(0);
  let minutes = Math.floor((timestamp % 36e5) / 6e4).toFixed(0);
  hours = +hours > 9 ? hours : `0${hours}`;
  minutes = +minutes > 9 ? minutes : `0${minutes}`;
  return `${hours}:${minutes}`;
};

export const timeToTimestamp = (hours, minutes) => {
  return hours * 36e5 + minutes * 6e4;
};

export const notifyDevelopers = (message, data) => {
  if (process.env.NODE_ENV !== 'development') {
    // Sentry.withScope(function(scope) {
    // 	scope.setTag("my-tag", "my value");
    // 	scope.setLevel('warning');
    // 	// will be tagged with my-tag="my value"
    // 	Sentry.captureException(new Error('my error'));
    // });

    Sentry.captureException({ message, data });
  }
};

export const cleanObj = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      cleanObj(v);
    }
    if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};
