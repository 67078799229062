import Spinner from 'components/Spinner';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap';
import { deleteBM } from 'redux/actions/business-manager';

const ManagerCard = ({ manager, businessId }) => {
  const { deletingBm } = useSelector((state) => state.managers);
  const dispatch = useDispatch();
  const handleManagerDelete = async () => {
    await dispatch(deleteBM(manager._id, businessId));
  };

  return (
    <div>
      <Card style={{ border: '1px solid grey' }}>
        <CardBody>
          <CardTitle tag="h5">
            {manager?.firstName} {manager?.lastName}
          </CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            {manager?.email}
          </CardSubtitle>
          {deletingBm._id === manager._id && deletingBm.loading ? (
            <Spinner size={20} />
          ) : (
            <Button
              disabled={deletingBm._id === manager._id && deletingBm.loading}
              onClick={handleManagerDelete}
            >
              Delete
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ManagerCard;
