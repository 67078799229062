import firebase from 'firebase/app';
import 'firebase/messaging';
const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJID,
  storageBucket: process.env.REACT_APP_FB_STORAGE,
  messagingSenderId: process.env.REACT_APP_FB_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
});
const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;
messaging && messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  'BCTbFtU-olAZ7gbZWZHF5TRhhjraE8_VYjqpGDkAc1dRv8cRWu-E6e4nK309E212-ycyD2myG53bMCVIhB4Ylos'
);
export { messaging };
