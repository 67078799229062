import Note from 'components/Note';
import { allowedLocales } from 'config/locales';
import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

const defaultValue = {
  name: {
    en: '',
    he: '',
    ar: '',
  },
  price: 0,
};

const OptionsForm = ({ options, setOptions }) => {
  const [langTab, setLangTab] = useState(0);
  const [option, setOption] = useState(defaultValue);

  const handleOptionOnChange = (e, field, locale) => {
    setOption({
      ...option,
      [field]: locale ? { ...option?.[field], [locale]: e.target.value } : e.target.value,
    });
  };

  const handleRemoveOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleAddOption = () => {
    if (!option.name.ar) delete option.name.ar;
    if (!option.name.en) delete option.name.en;
    if (!option.name.he) delete option.name.he;
    setOptions([...options, option]);
    setOption(defaultValue);
  };

  const handleOnChange = (e, field, locale, index) => {
    setOptions(
      options.map((v, i) => {
        if (i === index) {
          v[field] = locale ? { ...v[field], [locale]: e.target.value } : e.target.value;
        }
        return v;
      })
    );
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Note
            title="PLEASE NOTICE"
            subtitle="Every modification must be defined as single choice or multi choice. If it is defined as single choice, there must be at least 2 options to choose from, if it is defined as multi choice, you can define minimum and maximum choices."
          />
        </Col>
        <Col xs={12}>
          <Note
            title="Case 1"
            subtitle="To create a 'yes/no' modification select single choice with only 2 options."
          />
        </Col>
        <Col xs={12}>
          <Note
            title="Case 2"
            subtitle="For optional toppings(like on burger or pizza) select multi choice, with at least 2 options and minimum choices set to 0"
          />
        </Col>
      </Row>
      <div style={styles.container}>
        <Tabs selectedIndex={langTab} onSelect={(index) => setLangTab(index)}>
          <div style={styles.centerContent}>
            <span style={styles.bigFont}>Options</span>
          </div>
          <TabList style={{ display: 'flex' }}>
            {allowedLocales.map((locale, index) => (
              <Tab style={styles.tab} key={index}>
                <div style={styles.bigFont}>{locale}</div>
              </Tab>
            ))}
          </TabList>
          {allowedLocales.map((locale, index) => (
            <TabPanel key={index}>
              <div style={{ border: '1px solid grey', padding: 10, borderRadius: 10 }}>
                <Row>
                  <Col xs={12}>
                    <h3>Added options</h3>
                  </Col>
                </Row>
                {options?.map((_option, i) => {
                  return (
                    <Row key={i} style={{ alignItems: 'center' }}>
                      <Col xs={5}>
                        <FormGroup>
                          <Label for={`_option-name-${locale}-${i}`}>{'Name'}</Label>
                          <Input
                            type={'text'}
                            min={0}
                            name={`name`}
                            value={_option?.name?.[locale]}
                            onChange={(e) => handleOnChange(e, 'name', locale, i)}
                            id={`_option-name-${locale}-${i}`}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={5}>
                        <FormGroup>
                          <Label for={`_option-name-${locale}-${i}`}>
                            {'Price in agorot (1₪ = 100 agorot)'}
                          </Label>
                          <Input
                            type={'number'}
                            min={0}
                            name={`price`}
                            value={_option?.price}
                            onChange={(e) => handleOnChange(e, 'price', locale, i)}
                            id={`_option-price-${locale}-${i}`}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={2}>
                        <Button
                          onClick={() => handleRemoveOption(index)}
                          style={{ fontSize: '1.2rem' }}
                        >
                          -
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </div>

              <Row style={{ alignItems: 'center', marginTop: 50 }}>
                <Col xs={12}>
                  <h3>Add new option</h3>
                </Col>
                <Col xs={5}>
                  <FormGroup>
                    <Label for={`option-name-${locale}-${index}`}>{'Name'}</Label>
                    <Input
                      type={'text'}
                      min={0}
                      name={`option-name-${locale}-${index}`}
                      value={option?.name?.[locale]}
                      onChange={(e) => handleOptionOnChange(e, 'name', locale)}
                      id={`option-name-${locale}-${index}`}
                    />
                  </FormGroup>
                </Col>
                <Col xs={5}>
                  <FormGroup>
                    <Label for={`option-name-${locale}-${index}`}>
                      {'Price in agorot (1₪ = 100 agorot)'}
                    </Label>
                    <Input
                      type={'number'}
                      min={0}
                      name={`option-name-${locale}-${index}`}
                      value={option.price}
                      onChange={(e) => handleOptionOnChange(e, 'price')}
                      id={`option-price-${locale}-${index}`}
                    />
                  </FormGroup>
                </Col>
                <Col xs={2}>
                  <Button onClick={handleAddOption} style={{ fontSize: '1.2rem' }}>
                    +
                  </Button>
                </Col>
              </Row>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default OptionsForm;

const styles = {
  container: { backgroundColor: 'pink', borderRadius: 10, padding: 10 },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};
