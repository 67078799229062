import React, { useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper, Map, InfoWindow, Polygon } from 'google-maps-react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Collapse, Card, CardBody } from 'reactstrap';
import CourierMarker from './components/CourierMarker';
import BusinessMarker from './components/BusinessMarker';
import OrderMarker from './components/OrderMarker';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { createMessage } from '../../../redux/actions/message';
import { getProblematicOrders } from '../../../redux/actions/map';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { localeStringOptions } from 'utils';
import { getRegions } from 'redux/actions/regions';
import { getRegionCouriers } from 'redux/actions/order';

let interval;
let courierIntervals = [];

export const MapScreen = (props) => {
  const { businesses, delayed, unassigned, couriers } = useSelector(
    (state) => state.map
  );
  const { regions } = useSelector((state) => state.regions);
  const dispatch = useDispatch();
  // const [mapReady, setMapReady] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [location, setLocation] = useState('');
  const [filter, setFilter] = useState('');

  const [state, setState] = useState({
    selectedPlace: null,
    activeMarker: null,
    showingInfoWindow: false,
    type: null,
  });
  const [view, setView] = useState({
    available: true,
    unavailable: true,
    // businesses: false,
    // orders: true,
  });

  const [businessesMarkers, setBusinessesMarkers] = useState([]);
  const [availableMarkers, setAvailableMarkers] = useState([]);
  const [unavailableMarkers, setUnavailableMarkers] = useState([]);
  const [orderMarker, setOrderMarker] = useState({
    visible: false,
    lat: 0,
    lng: 0,
  });
  const [businessMarker, setBusinessMarker] = useState({
    visible: false,
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    businesses.length !== businessesMarkers.length &&
      setBusinessesMarkers(businesses);
    // eslint-disable-next-line
  }, [businesses, delayed, unassigned]);

  useEffect(() => {
    if (Object.keys(couriers).length > 0) {
      let _available = Object.values(couriers)?.reduce((all, region) => {
        return [...all, ...region.available];
      }, []);
      setAvailableMarkers(_available);
      let _unavailable = Object.values(couriers)?.reduce((all, region) => {
        return [...all, ...region.inDelivery];
      }, []);
      setUnavailableMarkers(_unavailable);
    }
  }, [couriers]);

  // useEffect(() => {
  //   if (props.match.params?.id && ordersMarkers.length && mapReady) {
  //     let present = ordersMarkers.find(
  //       (item) => item._id === props.match.params?.id
  //     );
  //     if (!present) return;
  //     let position = {
  //       lat: present.pickup?.lat,
  //       lng: present.pickup?.lng,
  //     };
  //     ref.current.map.setCenter(position);
  //     ref.current.map.setZoom(16);
  //     setDetailsOpen(true);
  //     setState({
  //       ...state,
  //       selectedPlace: present,
  //       activeMarker: null,
  //       type: 'order',
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [props.match.params?.id, ordersMarkers, mapReady]);

  useEffect(() => {
    dispatch(getProblematicOrders());
    dispatch(getRegions());
    clearInterval(interval);
    interval = setInterval(() => {
      dispatch(getProblematicOrders());
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (regions.length) {
      regions.forEach((region) => {
        dispatch(getRegionCouriers(region._id));
        let _interval = setInterval(() => {
          dispatch(getRegionCouriers(region._id));
        }, 10000);
        courierIntervals.push(_interval);
      });
      return () => {
        courierIntervals.forEach((v) => clearInterval(v));
      };
    }
  }, [regions, dispatch]);

  const handleViewChange = (e) => {
    setView({ ...view, [e.target.name]: !view[e.target.name] });
  };

  const fetchAddress = async (props) => {
    let lat, lng;
    if (props?.lastKnownLocation?.coordinates?.length === 2) {
      lat = props?.lastKnownLocation?.coordinates[1];
      lng = props?.lastKnownLocation?.coordinates[0];
    }
    let res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=en&key=${process.env.REACT_APP_GOOGLE_MAPS_APIKEY}`
    );
    if (res.data.status === 'NOT_FOUND') {
      return;
    } else {
      res.data?.results?.length &&
        setLocation(res.data?.results?.[0]?.formatted_address);
    }
  };

  const ref = useRef();
  const markerRef = useRef();
  const onMarkerClick = (props, marker, e, type) => {
    if (markerRef.current === props?._id) {
      ref.current.map.setZoom(14);
      setDetailsOpen(false);
      markerRef.current = null;
      setState({
        selectedPlace: null,
        activeMarker: null,
        showingInfoWindow: false,
        type,
      });
    } else {
      setDetailsOpen(true);
      if (type === 'courier' && props?.lastKnownLocation?.coordinates?.length) {
        setLocation('');
        fetchAddress(props);
      }
      markerRef.current = props._id;
      setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
        type,
      });
    }
  };

  const handleTabChange = (index) => {
    setTab(index);
    setFilter('');
  };

  const handleItemClick = (props, type) => {
    if (state.selectedPlace?._id === props?._id) {
      ref.current.map.setZoom(14);
      setDetailsOpen(false);
      markerRef.current = null;
      setState({
        selectedPlace: null,
        activeMarker: null,
        showingInfoWindow: false,
        type,
      });
      setOrderMarker({ visible: false, lat: 0, lng: 0 });
      setBusinessMarker({ visible: false, lat: 0, lng: 0 });
    } else {
      let position = {
        lat: props.business?.location?.coordinates[1],
        lng: props.business?.location?.coordinates[0],
      };
      // if (props.deliveryMethod === 'HomeDelivery') {
      //   // in case of home delivery, set bounds and zoom to those bounds
      //   let [lng, lat] = props?.deliveryLocation?.location?.coordinates;
      //   var southWest = new window.google.maps.LatLng(position);
      //   var northEast = new window.google.maps.LatLng({ lat, lng });
      //   let bounds = new window.google.maps.LatLngBounds(southWest, northEast);
      //   ref.current.map.fitBounds(bounds);
      // } else {
      ref.current.map.setCenter(position);
      ref.current.map.setZoom(17);
      // }
      if (props.deliveryMethod === 'HomeDelivery') {
        setOrderMarker({
          visible: true,
          lat: props?.deliveryLocation?.location?.coordinates[1],
          lng: props?.deliveryLocation?.location?.coordinates[0],
        });
      }
      setBusinessMarker({
        visible: true,
        lat: props.business?.location?.coordinates[1],
        lng: props.business?.location?.coordinates[0],
      });
      setDetailsOpen(true);
      setState({
        ...state,
        selectedPlace: props,
        activeMarker: null,
        type,
      });
    }
  };

  return (
    <div>
      <div style={styles.container}>
        <div style={styles.controlPane}>
          <div style={styles.detailsContainer}>
            <Collapse isOpen={detailsOpen}>
              <Card>
                <CardBody>
                  <div
                    style={{
                      ...styles.detailsHeader,
                      backgroundColor:
                        state.type === 'available'
                          ? 'var(  --secondary-color)'
                          : state.type === 'unavailable'
                          ? '#ccc'
                          : null,
                    }}
                  >
                    <div onClick={() => setDetailsOpen(false)}>
                      <i className="tim-icons icon-simple-remove"></i>
                    </div>
                  </div>
                  <div>
                    {state.type === 'courier' && (
                      <div>
                        <div style={styles.available}>
                          <span>
                            {state.selectedPlace?.firstName}{' '}
                            {state.selectedPlace?.lastName}
                          </span>
                          <span>
                            {state?.selectedPlace?.available
                              ? 'Available'
                              : 'In Delivery'}
                          </span>
                          <span>
                            Phone:{' '}
                            {state?.selectedPlace?.phone.replace('+972', '0')}
                          </span>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                              }}
                            ></div>
                            <Link
                              to={`/details-courier/${state?.selectedPlace?._id}`}
                              className="user-profile-link"
                            >
                              <Button
                                size="sm"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: 15,
                                  padding: 10,
                                }}
                              >
                                Details
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    {state.type === 'order' && (
                      <div>
                        <div style={styles.available}>
                          <span>
                            #{state.selectedPlace?.number}{' '}
                            {state.selectedPlace?._id}
                          </span>
                          <span>
                            Business: {state?.selectedPlace?.business?.name}
                          </span>
                          <span>
                            Delivery Method:{' '}
                            {state?.selectedPlace?.deliveryMethod}
                          </span>
                          {state?.selectedPlace?.created_at && (
                            <span>
                              Order time:{' '}
                              {new Date(
                                state?.selectedPlace?.created_at
                              ).toLocaleString('he-il', localeStringOptions)}
                            </span>
                          )}
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                              }}
                            ></div>
                            <Link
                              to={`/details-order/${state?.selectedPlace?._id}`}
                              className="user-profile-link"
                            >
                              <Button
                                size="sm"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: 15,
                                  padding: 10,
                                }}
                              >
                                Details
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Collapse>
            <Tabs
              selectedIndex={tab}
              onSelect={(index) => handleTabChange(index)}
            >
              <TabList style={{ display: 'flex' }}>
                <Tab style={{ width: '50%', textAlign: 'center' }}>
                  Unassigned Deliveries
                </Tab>
                <Tab style={{ width: '50%', textAlign: 'center' }}>
                  Delayed Deliveries
                </Tab>
              </TabList>
              <TabPanel>
                <div
                  style={{
                    ...styles.tabPanel,
                    height: detailsOpen ? '45vh' : '75vh',
                  }}
                >
                  {/* <FormGroup>
                    <Input
                      name="id"
                      id="input-identifier"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value.toLowerCase())}
                      placeholder="Filter by order number or business name.."
                    />
                  </FormGroup> */}

                  {unassigned.map((order, key) => (
                    <Card key={key}>
                      <div style={{ padding: 5 }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                            }}
                          >
                            <span>
                              #{order?.number} - {order?._id}
                            </span>
                            <span>
                              Order time:{' '}
                              {new Date(order.created_at).toLocaleDateString(
                                'he-il',
                                localeStringOptions
                              )}
                            </span>
                            <span>Business: {order.business?.name}</span>
                            <span>Method: {order.deliveryMethod}</span>
                            {order.deliveryLocation?.address && (
                              <span>
                                Delivery location:{' '}
                                {order.deliveryLocation?.address}
                              </span>
                            )}
                          </div>
                          <div>
                            <Button
                              size="sm"
                              onClick={() => {
                                handleItemClick(order, 'order');
                              }}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 25,
                                height: 50,
                                width: 50,
                              }}
                            >
                              <i
                                style={{ fontSize: '20px' }}
                                className="tim-icons icon-square-pin"
                              ></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{
                    ...styles.tabPanel,
                    height: detailsOpen ? '45vh' : '75vh',
                  }}
                >
                  {/* <FormGroup>
                    <Input
                      name="id"
                      id="input-identifier"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value.toLowerCase())}
                      placeholder="Filter by order number or business name.."
                    />
                  </FormGroup> */}
                  {delayed
                    .filter(
                      (v) =>
                        v?.business?.name.toLowerCase().indexOf(filter) > -1 ||
                        v?.number?.indexOf(filter) > -1
                    )
                    .map((order, key) => (
                      <Card key={key}>
                        <div style={{ padding: 5 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                              }}
                            >
                              <span>
                                #{order?.number} - {order?._id}
                              </span>
                              <span>
                                Order time:{' '}
                                {new Date(order.created_at).toLocaleDateString(
                                  'he-il',
                                  localeStringOptions
                                )}
                              </span>
                              <span>Business: {order.business?.name}</span>
                              <span>Method: {order.deliveryMethod}</span>
                              {order.deliveryLocation?.address && (
                                <span>
                                  Delivery location:{' '}
                                  {order.deliveryLocation?.address}
                                </span>
                              )}
                            </div>
                            <div>
                              <Button
                                size="sm"
                                onClick={() => {
                                  handleItemClick(order, 'order');
                                }}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: 25,
                                  height: 50,
                                  width: 50,
                                }}
                              >
                                <i
                                  style={{ fontSize: '20px' }}
                                  className="tim-icons icon-square-pin"
                                ></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
              </TabPanel>
              {/* SAVED IN CASE WE NEED TO SEE COURIERS IN CONTROL PANE */}
              {/* <TabPanel>
                <div
                  style={{
                    ...styles.tabPanel,
                    height: detailsOpen ? '45vh' : '75vh',
                  }}
                >
                  <FormGroup>
                    <Input
                      name="id"
                      id="input-identifier"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value.toLowerCase())}
                      placeholder="Filter by order number or business name.."
                    />
                  </FormGroup>

                  {available
                    .filter(
                      (v) => v.user.name.toLowerCase().indexOf(filter) > -1
                    )
                    .map((vehicle, key) => (
                      <Card key={key}>
                        <div style={{ padding: 5 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                              }}
                            >
                              <span>נהג: {vehicle?.user?.name}</span>
                              <span>חברה: {vehicle?.user?.company?.name}</span>
                            </div>
                            <div>
                              <Button
                                size="sm"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: 25,
                                  height: 50,
                                  width: 50,
                                }}
                                onClick={() => {
                                  handleItemClick(vehicle, 'available');
                                }}
                              >
                                <i
                                  style={{ fontSize: '20px' }}
                                  className="tim-icons icon-square-pin"
                                ></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
        <div style={styles.mapPane}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
              style={{
                width: 400,
                zIndex: 999,
                position: 'absolute',
                top: 5,
                left: 5,
              }}
            >
              <div style={styles.buttons}>
                <ButtonGroup style={{ width: '100%' }}>
                  {Object.keys(view).map((key, i) => (
                    <Button
                      key={i}
                      name={key}
                      onClick={handleViewChange}
                      style={{ padding: '4px 0px 4px 0px' }}
                    >
                      {!view[key] ? `Show ${key}` : `Hide ${key}`}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </div>
            <Map
              google={props.google}
              zoom={13}
              maxZoom={18}
              streetViewControl={false}
              // onReady={() => setMapReady(true)}
              mapTypeControl={false}
              minZoom={8}
              ref={ref}
              initialCenter={{ lat: 32.700524, lng: 35.293337 }}
              onZoomChanged={(e) => {}}
              onDragend={(e) => {}}
              styles={styles.googleMap}
            >
              {regions.map((v) => {
                let coords = [];
                v.geozone.coordinates[0].map((point) =>
                  coords.push({
                    lat: point[1],
                    lng: point[0],
                  })
                );
                return (
                  <Polygon
                    paths={coords}
                    key={v._id}
                    options={{
                      fillColor: '#FF4F61',
                      fillOpacity: 0.1,
                      strokeColor: '#000',
                      strokeOpacity: 1,
                      strokeWeight: 1,
                    }}
                  />
                );
              })}
              {view.businesses &&
                businessesMarkers?.map((business, key) => (
                  <BusinessMarker key={key} {...{ business, onMarkerClick }} />
                ))}
              {businessMarker?.visible && (
                <BusinessMarker
                  {...{
                    order: {
                      business: {
                        location: {
                          coordinates: [businessMarker.lng, businessMarker.lat],
                        },
                      },
                    },
                    onMarkerClick,
                  }}
                />
              )}
              {orderMarker?.visible && (
                <OrderMarker
                  {...{
                    order: {
                      deliveryMethod: 'HomeDelivery',
                      deliveryLocation: {
                        location: {
                          coordinates: [orderMarker.lng, orderMarker.lat],
                        },
                      },
                    },
                    onMarkerClick,
                  }}
                />
              )}
              {view.available &&
                availableMarkers?.map((courier, key) => {
                  return (
                    <CourierMarker
                      available
                      key={key}
                      {...{ courier, onMarkerClick }}
                    />
                  );
                })}
              {view.unavailable &&
                unavailableMarkers?.map((courier, key) => {
                  return (
                    <CourierMarker
                      available={false}
                      key={key}
                      {...{ courier, onMarkerClick }}
                    />
                  );
                })}
              <InfoWindow
                marker={state.activeMarker}
                visible={state.showingInfoWindow}
              >
                <div>
                  {state.type === 'order' && (
                    <div style={styles.available}>
                      <p
                        style={{
                          fontWeight: '700',
                          color: 'red',
                          fontSize: 16,
                        }}
                      >
                        {state?.selectedPlace?.number}{' '}
                        {state?.selectedPlace?.deliveryMethod}
                      </p>
                      <span>{state?.selectedPlace?.business?.name}</span>
                      <span>{state?.selectedPlace?.business?.address}</span>
                      <span>{state?.selectedPlace?.client?.firstName}</span>
                    </div>
                  )}
                  {/* {state.type === 'hospital' && (
                    <span>
                      {state.selectedPlace?.name?.IW
                        ? state.selectedPlace?.name?.IW
                        : null}
                    </span>
                  )} */}
                  {state.type === 'courier' && (
                    <div style={styles.available}>
                      <span>{location}</span>
                      <span>
                        {state?.selectedPlace?.available
                          ? 'Available'
                          : 'In Delivery'}
                      </span>
                      <span>
                        {state?.selectedPlace?.firstName}{' '}
                        {state?.selectedPlace?.lastName}
                      </span>
                      <span>
                        {state?.selectedPlace?.phone?.replace('+972', '0')}
                      </span>
                    </div>
                  )}
                </div>
              </InfoWindow>
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  language: 'en',
})(MapScreen);

const styles = {
  container: {
    display: 'flex',
    flex: '1',
  },
  tabPanel: {
    height: '100%',
    overflowY: 'scroll',
  },
  controlPane: {
    backgroundColor: 'white',
    flex: '1',
    marginLeft: 20,
    borderRadius: 5,
    padding: 5,
    maxHeight: '85vh',
  },
  mapPane: {
    flex: '2',
  },
  available: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsHeader: {
    position: 'absolute',
    right: 20,
    top: 10,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderRadius: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  detailsContainer: {
    height: '85vh',
  },
  bold: {
    fontSize: 18,
    fontWeight: 700,
  },
  googleMap: [
    {
      featureType: 'water',
      stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#808080' }, { lightness: 54 }],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ece2d9' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ccdca1' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#767676' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#ffffff' }],
    },
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
    },
    { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
    {
      featureType: 'poi.sports_complex',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.medical',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    //
  ],
};
