import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const MerchItem = ({ item, locale }) => {
  const [open, setOpen] = useState(false);

  return (
    <div onClick={() => setOpen(!open)}>
      {open ? (
        <Collapse isOpen={open}>
          <div style={styles.appCard}>
            {item?.name?.[locale] ?? item?.name?.['en']}
            {item?.modifications?.map((mod) => (
              <div key={item._id + mod._id}>
                {mod?.name?.[locale] ?? mod?.name?.['en']}
              </div>
            ))}
          </div>
        </Collapse>
      ) : (
        <div style={styles.appCard}>
          {item?.name?.[locale] ?? item?.name?.['en']}
        </div>
      )}
    </div>
  );
};

export default MerchItem;

const styles = {
  appCard: {
    cursor: 'pointer',
    padding: 17,
  },
};
