const types = {
  REGIONS_LOAD_ATTEMPT: 'REGIONS_LOAD_ATTEMPT',
  REGIONS_LOAD_SUCCESS: 'REGIONS_LOAD_SUCCESS',
  REGIONS_LOAD_FAIL: 'REGIONS_LOAD_FAIL',
  REGION_CREATE_ATTEMPT: 'REGION_CREATE_ATTEMPT',
  REGION_CREATE_SUCCESS: 'REGION_CREATE_SUCCESS',
  REGION_CREATE_FAIL: 'REGION_CREATE_FAIL',
  REGION_DELETE_ATTEMPT: 'REGION_DELETE_ATTEMPT',
  REGION_DELETE_SUCCESS: 'REGION_DELETE_SUCCESS',
  REGION_DELETE_FAIL: 'REGION_DELETE_FAIL',
};

export default types;
