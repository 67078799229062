import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Spinner from 'components/Spinner';

import { Button, Card, CardBody, Row, Col, CardFooter } from 'reactstrap';

import {
  getEntity,
  updateEntity,
  setSingleEntity,
  clearSingleEntity,
  reactivateEntity,
  deactivateEntity,
  publishEntity,
  unpublishEntity,
  deleteEntity,
} from '../../../redux/actions/entities';
import { getMerchEntity } from '../../../redux/actions/merchandise';
import { returnDetailsObject, entityDetailsTabs, entityFields } from './entities';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import systemEntitiesList from '../../../config/entities';
import { getBms } from 'redux/actions/business-manager';
import { createMessage } from 'redux/actions/message';
import { validate } from 'utils';

const EntityDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const { singleEntity, singleEntityLoading, updatingEntity } = useSelector(
    (state) => state.entities
  );
  const [displayedEntity, setDisplayedEntity] = useState({});
  const [entityTabs, setEntityTabs] = useState([]);
  // const [selectedEntityTab, setSelectedEntityTab] = useState(0);
  // const [formTabs, setFormTabs] = useState([]);
  const [selectedFormTab, setSelectedFormTab] = useState(0);
  const [model, setModel] = useState('');

  useEffect(() => {
    if (match.path) {
      let _model = match.path.slice(9, match.path.slice(1).indexOf('/') + 1);
      if (systemEntitiesList.includes(_model)) {
        setModel(_model);
      } else {
        history.push('/');
      }
    }
    return () => {
      dispatch(clearSingleEntity());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisplayedEntity({});
    match.params.id &&
      model &&
      (() => {
        dispatch(getEntity(model, match.params.id));
        if (model === 'business') {
          dispatch(getMerchEntity('sections', match.params.id));
          dispatch(getMerchEntity('items', match.params.id));
          dispatch(getMerchEntity('modifications', match.params.id));
          dispatch(getBms(0, match.params.id));
        } else if (model === 'courier') {
          // if any more actions needed for courier
        }
        setEntityTabs(entityDetailsTabs[model]);
      })();
  }, [match.params.id, model, dispatch]);

  useEffect(() => {
    return () => dispatch(setSingleEntity({}));
  }, [dispatch]);

  useEffect(() => {
    if (model && singleEntity?._id) {
      let obj = returnDetailsObject(singleEntity, model);
      setDisplayedEntity(obj);
    } else {
      setDisplayedEntity({});
    }
  }, [singleEntity, model]);

  const handleEntityUpdate = () => {
    let obj = entityFields[model].transform(displayedEntity);
    let { isValid, errors } = validate(model, obj);
    if (isValid) {
      dispatch(updateEntity(model, obj));
    } else {
      dispatch(createMessage(`Invalid input - ${errors[0]}`));
    }
  };

  const saveOrder = async (sections) => {
    // save order of sections
    await dispatch(
      updateEntity(model, {
        _id: displayedEntity._id,
        merchandise: sections.map((v) => v._id),
      })
    );
    dispatch(getMerchEntity('sections', match.params.id));

  };

  const handleEntityActivation = () => {
    if (displayedEntity?.active === true) {
      dispatch(deactivateEntity(displayedEntity._id, model));
    } else {
      dispatch(reactivateEntity(displayedEntity._id, model));
    }
  };

  const handleEntityDelete = () => {
    if (displayedEntity?.deleted !== true) {
      dispatch(deleteEntity(displayedEntity._id, model, history));
    }
  };

  const handleEntityPublishStatusChange = () => {
    if (displayedEntity?.published === true) {
      dispatch(unpublishEntity(displayedEntity._id, model));
    } else {
      dispatch(publishEntity(displayedEntity._id, model));
    }
  };

  const handleTabChange = (index) => {
    setSelectedFormTab(index);
  };

  const handleOnChange = (e, field, locale, isMulti) => {
    switch (field.type) {
      case 'select':
        if (isMulti && e) {
          setDisplayedEntity({
            ...displayedEntity,
            [field.key]: [...e],
          });
        } else if (isMulti && !e) {
          setDisplayedEntity({
            ...displayedEntity,
            [field.key]: [],
          });
        } else {
          setDisplayedEntity({
            ...displayedEntity,
            [field.key]: e,
          });
        }
        break;
      case 'checkbox':
        setDisplayedEntity({
          ...displayedEntity,
          [field.key]: e.target.checked,
        });
        break;
      default:
        setDisplayedEntity({
          ...displayedEntity,
          [field.key]: field?.localization
            ? {
                ...(displayedEntity?.[field.key] || {}),
                [locale]: e.target.value,
              }
            : e.target.value,
        });
        break;
    }
  };

  const handleImageChange = (img, key) => {
    setDisplayedEntity({
      ...displayedEntity,
      [key]: {
        binary: img,
      },
    });
  };

  const saveHours = (workHours) => {
    setDisplayedEntity({ ...displayedEntity, workHours });
  };

  const handleBusinessLocationChange = (address, location) => {
    setDisplayedEntity({
      ...displayedEntity,
      address,
      location,
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12} className="text-left">
            <Link to={`/list-${model}`}>
              <Button
                className="btn-fill mx-1"
                color="primary"
                type="submit"
                style={{ width: '250px' }}
              >
                Go back
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {singleEntityLoading || updatingEntity ? (
              <Spinner />
            ) : (
              <Card style={{ padding: '10px' }}>
                <CardBody>
                  <Tabs
                    selectedIndex={selectedFormTab}
                    onSelect={(index) => handleTabChange(index)}
                  >
                    <TabList style={{ display: 'flex' }}>
                      {displayedEntity?._id &&
                        entityTabs.map((tab, index) => <Tab key={index}>{tab.title}</Tab>)}
                    </TabList>
                    {displayedEntity?._id &&
                      entityTabs.map((tab, index) => {
                        let Component = tab.component;

                        let instance = displayedEntity || {};

                        return (
                          <TabPanel key={index}>
                            <Component
                              {...{
                                instance,
                                model,
                                handleOnChange,
                                saveHours,
                                handleEntityUpdate,
                                handleEntityActivation,
                                handleEntityPublishStatusChange,
                                handleImageChange,
                                handleBusinessLocationChange,
                                handleEntityDelete,
                                saveOrder,
                              }}
                            />
                          </TabPanel>
                        );
                      })}
                  </Tabs>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EntityDetails;
