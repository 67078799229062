import * as types from '../types/business-manager';
import entityTypes from '../types/entities';

const initialState = {
  bmsPage: 0,
  bmsLoading: false,
  bmsExhausted: false,
  bms: [],
  creatingBm: false,
  deletingBm: { _id: '', loading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.DELETE_BM_ATTEMPT:
      return {
        ...state,
        deletingBm: { _id: action.payload, loading: true },
      };
    case types.DELETE_BM_SUCCESS:
    case types.DELETE_BM_FAIL:
      return {
        ...state,
        deletingBm: { _id: '', loading: false },
      };
    case types.CREATE_BM_ATTEMPT:
      return {
        ...state,
        creatingBm: true,
      };
    case types.CREATE_BM_SUCCESS:
      return {
        ...state,
        creatingBm: false,
      };
    case types.CREATE_BM_FAIL:
      return {
        ...state,
        creatingBm: false,
      };
    case entityTypes.CLEAR_ENTITY:
      return initialState;
    case types.GET_BMS_ATTEMPT:
      return { ...state, bmsLoading: true };
    case types.GET_BMS_SUCCESS:
      return {
        ...state,
        bmsLoading: false,
        bms:
          action.payload.bmsExhausted &&
          action.payload.data.length === state.bms.length
            ? state.bms
            : action.payload.data.length === 0
            ? []
            : [...state.bms, ...action.payload.data],
        bmsExhausted: action.payload.bmsExhausted,
        bmsPage: !action.payload.bmsExhausted
          ? state.bmsPage + 1
          : state.bmsPage,
      };
    case types.GET_BMS_FAIL:
      return { ...state, bmsLoading: false };
    default:
      return state;
  }
}
