import React, { useState } from 'react';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ManagerCard from './components/ManagerCard';
import { createBM } from 'redux/actions/business-manager';
import Spinner from 'components/Spinner';
import Note from 'components/Note';
import { createMessage } from 'redux/actions/message';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
};

const Manager = () => {
  const { bms, creatingBm } = useSelector((state) => state.managers);
  const { singleEntity } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const handleOnChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleManagerCreate = async () => {
    if (!Object.values(state).includes('')) {
      await dispatch(createBM({ ...state, business: singleEntity._id }));
      setState(initialState);
    } else {
      dispatch(createMessage('All fields are required'));
    }
  };

  return (
    <div>
      <Note title="Please make sure that the business is unavailable before deleting business managers" />
      {!bms.length && (
        <Row style={{ alignItems: 'flex-end', marginBottom: 10 }}>
          {Object.keys(state).map((key) => {
            return (
              <Col key={key} xs={3}>
                <Label for={key}>{key.toUpperCase()}</Label>
                <Input
                  type="text"
                  name={key}
                  id={key}
                  value={state[key]}
                  onChange={handleOnChange}
                />
              </Col>
            );
          })}
          <Col xs={3}>
            {creatingBm ? (
              <Spinner size={20} />
            ) : (
              <Button
                disabled={creatingBm}
                style={{ margin: 0 }}
                onClick={handleManagerCreate}
              >
                Create
              </Button>
            )}
          </Col>
        </Row>
      )}

      <Row>
        {bms.map((manager) => (
          <Col xs={6} key={manager._id}>
            <ManagerCard {...{ manager, businessId: singleEntity._id }} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Manager;
