// import { requestWrapper, supportedRequestMethods } from '../../API';
import { PROBLEMATIC_ORDERS_LOADED } from '../types/map';
import { supportedRequestMethods, requestWrapper } from '../../API';
import { createMessage } from './message';

// import { createMessage } from './message';

export const getProblematicOrders = () => async (dispatch, getState) => {
  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/orders/problematic`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: PROBLEMATIC_ORDERS_LOADED,
      payload: response.data.data,
    });
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: PROBLEMATIC_ORDERS_LOADED,
      payload: [],
    });
  }
};
