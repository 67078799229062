import React from 'react';
import { Col, Row } from 'reactstrap';

const Note = ({ title, subtitle }) => {
  return (
    <Row style={{ marginTop: 10, marginBottom: 10 }}>
      <Col>
        <div style={styles.title}>{title}</div>
        <div style={styles.subtitle}>{subtitle}</div>
      </Col>
    </Row>
  );
};

export default Note;

const styles = {
  title: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#E71D36',
    fontWeight: 600,
  },
  subtitle: { textAlign: 'center', fontSize: '0.9rem', color: '#E71D36' },
};
