import imageCompression from 'browser-image-compression';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { Row, Col, FormGroup, Button, Input } from 'reactstrap';
import Note from './Note';

const CropperUpload = ({ img, setImage, aspect, aspectText }) => {
  const [_img, _setImg] = useState('');
  const cropperRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (img !== undefined) _setImg(`${process.env.REACT_APP_PUBLIC_URL}${img}`);
    else {
      _setImg('');
    }
  }, [img]);

  const handleFileUpload = (e) => {
    if (e?.target?.files?.[0]?.name) {
      _setImg(URL.createObjectURL(e?.target?.files?.[0]));
      setImage(e?.target?.files?.[0]);
    }
  };

  const onCropEnd = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob(async (blob) => {
      const compressedFile = await imageCompression(blob, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 700,
      });
      let mutatedCompressFile = new File([compressedFile], blob.name, {
        type: blob.type,
      });
      setImage(mutatedCompressFile);
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Note title={`Please provide an image with ${aspectText} aspect ratio`} />
      </Col>
      <Col xs={12} style={styles.col}>
        <div style={{ height: 270, maxWidth: 480 }}>
          <Cropper
            src={_img}
            // Cropper.js options
            style={{ height: 270, maxWidth: 480 }}
            initialAspectRatio={aspect}
            aspectRatio={aspect}
            guides={true}
            viewMode={2}
            ref={cropperRef}
            autoCropArea={1}
            cropend={onCropEnd}
          />
        </div>
      </Col>
      <Col xs={12} style={styles.col}>
        <FormGroup style={styles.imageContainer}>
          <Button htmlFor={'file'}>Select file</Button>
          <Input type="file" name="file" ref={ref} id={'file'} onChange={handleFileUpload} />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default CropperUpload;

const styles = {
  imageContainer: {
    width: '100%',
    height: 'auto',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    borderRadius: '10px',
  },
  whiteFont: {
    color: 'white',
  },
  col: {
    display: 'flex',
    justifyContent: 'center',
  },
};
