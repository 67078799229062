import React, { useRef } from 'react';
import { GoogleApiWrapper, Map, Polygon, Marker } from 'google-maps-react';
import { useSelector } from 'react-redux';

const SmallMap = (props) => {
  const { regions } = useSelector((state) => state.regions);
  const { coordinates } = props;

  const ref = useRef();

  let lat = coordinates[1];
  let lng = coordinates[0];
  let position = new props.google.maps.LatLng(+lat, +lng);

  return (
    <div style={{ width: '100%', height: 300 }}>
      <Map
        google={props.google}
        zoom={13}
        maxZoom={18}
        streetViewControl={false}
        // onReady={() => setMapReady(true)}
        mapTypeControl={false}
        minZoom={8}
        ref={ref}
        center={{ lat: coordinates[1], lng: coordinates[0] }}
        initialCenter={{ lat: coordinates[1], lng: coordinates[0] }}
        onZoomChanged={(e) => {}}
        onDragend={(e) => {}}
        style={{ position: 'relative', width: '100%', height: 300 }}
        styles={styles.googleMap}
      >
        {regions.map((v) => {
          let coords = [];
          v.geozone.coordinates[0].map((point) =>
            coords.push({
              lat: point[1],
              lng: point[0],
            })
          );
          return (
            <Polygon
              paths={coords}
              key={v._id}
              options={{
                fillColor: '#FF4F61',
                fillOpacity: 0.1,
                strokeColor: '#000',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
            />
          );
        })}
        <Marker {...{ position }} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  language: 'en',
})(SmallMap);

const styles = {
  googleMap: [
    {
      featureType: 'water',
      stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#808080' }, { lightness: 54 }],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ece2d9' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ccdca1' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#767676' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#ffffff' }],
    },
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
    },
    { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
    {
      featureType: 'poi.sports_complex',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.medical',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    //
  ],
};
