import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getEntityList } from '../../../redux/actions/entities';
import Switch from 'react-bootstrap-switch';
import { useTable, usePagination } from 'react-table';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import Spinner from 'components/Spinner';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { entityTabs, entitiesColumns, returnEntityObj } from './entities';
import systemEntitiesList from '../../../config/entities';
import Select from 'react-select';
import { getRegions } from 'redux/actions/regions';

const mapIndexToType = {
  0: '',
  1: 'restaurant',
  2: 'retail',
};

const deliveryOptions = [
  {
    value: '',
    label: ' All methods ',
  },
  {
    value: 'HomeDelivery',
    label: ' Home delivery ',
  },
  { label: ' Take away ', value: 'TakeAway' },
];

const orderStateOptions = [
  {
    value: '',
    label: ' All states',
  },
  { label: 'Placed', value: 'placed' },
  { label: ' Accepted ', value: 'accepted' },
  {
    label: ' Awaiting Pickup ',
    value: 'awaitingPickup',
  },
  {
    label: ' In Delivery ',
    value: 'inDelivery',
  },
  {
    label: ' Delivered ',
    value: 'delivered',
  },
  { label: ' Rejected ', value: 'rejected' },
  {
    label: ' Cancelled ',
    value: 'cancelled',
  },
];

const EntityList = (props) => {
  const {
    entityList,
    entityCount,
    getEntityList,
    loading,
    match,
    history,
    regions,
    getRegions,
  } = props;
  const [state, setState] = useState({ data: [] });
  const [columns, setColumns] = useState([]);
  const [type, setType] = useState('');
  const [entity, setEntity] = useState('');
  const [tab, setTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [active, setActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [timer, setTimer] = useState(null);
  const [displayedRegions, setDisplayedRegions] = useState([]);
  const [PAGE_SIZE, setPageSize] = useState(10);

  useEffect(() => {
    if (entity === 'admin') setPageSize(100);
  }, [entity]);

  const handlePageChange = (pageNumber) => {
    getEntityList({
      active,
      offset: pageNumber,
      filters: { type },
      limit: PAGE_SIZE,
      entity,
    });
    setPageNumber(pageNumber);
    //get clients with new offset
  };

  // Re-fetch data based on changed filters
  useEffect(() => {
    // Don't fetch if tabs change - if all filters are reset manually then the properties will stay but as empty strings
    if (Object.keys(filters).length > 0 && filters.constructor === Object) {
      if (timer) {
        clearTimeout(timer);
      }

      setTimer(
        setTimeout(() => {
          setTimer(null);
          setPageNumber(0);
          getEntityList({
            entity,
            active,
            offset: 0,
            filters: { ...filters, type },
            limit: PAGE_SIZE,
          });
        }, 1000)
      );
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (match.path) {
      let model = match.path.slice(6);
      if (systemEntitiesList.includes(model)) {
        setEntity(model);
        getEntityList({
          entity: model,
          offset: 0,
          limit: PAGE_SIZE,
          filters: { type: '' },
          active,
        });
        setColumns(entitiesColumns[model]);
        if (model === 'business') {
          getRegions();
        }
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!entityList?.length) {
      setState({ data: [] });
    } else {
      entityList?.length &&
        setState({
          data: entityList.map((item, key) => {
            return returnEntityObj(entity, item, key);
          }),
        });
    }

    // eslint-disable-next-line
  }, [entityList]);

  useEffect(() => {
    setMaxPages(Math.floor(entityCount / PAGE_SIZE));
  }, [entityCount, PAGE_SIZE]);

  // const showDeactivateModal = (_id) => {
  //   setId(_id);
  //   setAction('deactivate');
  //   setModalOpen(true);
  // };
  // const showActivateModal = (_id) => {
  //   setId(_id);
  //   setAction('activate');
  //   setModalOpen(true);
  // };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: state.data,
      initialState: { pageIndex: 0, pageSize: PAGE_SIZE },
    },
    usePagination
  );

  const handleTabChange = (index) => {
    setTab(index);
    let type = mapIndexToType[index];
    setType(type);
    setColumns(entitiesColumns[type ? type : entity]);
    getEntityList({
      active,
      offset: 0,
      filters: { type },
      limit: PAGE_SIZE,
      entity,
    });
    setPageNumber(0);
    setFilters({});
  };

  const handleSwitch = (elem, state) => {
    setActive(state);
    getEntityList({
      active: state,
      offset: 0,
      filters: { type },
      limit: PAGE_SIZE,
      entity,
    });
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    if (regions.length) {
      setDisplayedRegions(regions.map((v) => ({ value: v._id, label: v.name })));
    }
  }, [regions]);

  return (
    <>
      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={true}>
        <ModalHeader>
          <h3>אשרו פעולה</h3>
        </ModalHeader>
        <ModalBody>
          {/* <div style={{ display: 'flex', fontSize: 20 }}>
            {action === 'activate'
              ? 'בטוחים שתרצו לשחזר משתמש זה?'
              : 'להשבית משתמש?'}
          </div> */}
        </ModalBody>
        <ModalFooter>
          {/* <Button
            color="success"
            style={{ paddingLeft: 50, paddingRight: 50 }}
            onClick={() => {
              action === 'activate' ? restoreClient(id) : deactivateClient(id);
              setModalOpen(false);
            }}
          >
            {action === 'activate' ? 'שחזר' : 'השבת'}
          </Button> */}
          <Button color="primary" onClick={toggleModal}>
            בטל
          </Button>
        </ModalFooter>
      </Modal>
      <div className="content">
        <Row>
          <Col md="12" style={{ direction: 'ltr' }}>
            <Tabs selectedIndex={tab} onSelect={(index) => handleTabChange(index)}>
              <TabList style={{ display: 'flex' }}>
                {entityTabs?.[entity]?.map((userType, index) => (
                  <Tab key={index}>{userType.text}</Tab>
                ))}
              </TabList>
              {entityTabs?.[entity]?.map((userType, index) => (
                <TabPanel key={index}>
                  <Card>
                    <>
                      <CardHeader>
                        <CardTitle tag="h4">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>List of {userType.text}</span>
                            {entity === 'business' && (
                              <div style={{ display: 'flex' }}>
                                <FormGroup style={{ width: 200, marginRight: 10 }}>
                                  <Label>Region</Label>
                                  <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name=""
                                    onChange={(value) => {
                                      if (value.value)
                                        setFilters({
                                          ...filters,
                                          region: value.value,
                                        });
                                    }}
                                    value={displayedRegions?.find(
                                      (v) => v.value === filters?.region
                                    )}
                                    options={displayedRegions}
                                    placeholder="Select region"
                                  />
                                </FormGroup>
                              </div>
                            )}
                            {entity === 'order' ? (
                              <div style={{ display: 'flex' }}>
                                <FormGroup style={{ width: 200, marginRight: 10 }}>
                                  <Label>State</Label>
                                  <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name=""
                                    onChange={(value) => {
                                      if (value.value)
                                        setFilters({
                                          ...filters,
                                          orderState: value.value,
                                        });
                                      else {
                                        setFilters({});
                                      }
                                    }}
                                    value={
                                      orderStateOptions.find(
                                        (v) => v.value === filters?.orderState
                                      ) || orderStateOptions[0]
                                    }
                                    options={orderStateOptions}
                                    placeholder="Select order state"
                                  />
                                </FormGroup>
                                <FormGroup style={{ width: 200, marginLeft: 10 }}>
                                  <Label>Delivery Method</Label>
                                  <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name=""
                                    onChange={(value) => {
                                      if (value?.value)
                                        setFilters({
                                          ...filters,
                                          deliveryMethod: value.value,
                                        });
                                      else {
                                        setFilters({});
                                      }
                                    }}
                                    value={
                                      deliveryOptions.find(
                                        (v) => v.value === filters?.deliveryMethod
                                      ) || deliveryOptions[0]
                                    }
                                    options={deliveryOptions}
                                    placeholder="Select method"
                                  />
                                </FormGroup>
                              </div>
                            ) : (
                              <>
                                {!['client', 'admin'].includes(entity) ? (
                                  <div>
                                    <Switch
                                      onChange={(el, state) => handleSwitch(el, state)}
                                      value={active}
                                      onText={
                                        <span
                                          style={{
                                            fontSize: '11px',
                                            color: 'white',
                                          }}
                                        >
                                          Active
                                        </span>
                                      }
                                      offText={
                                        <span
                                          style={{
                                            fontSize: '9px',
                                            color: 'white',
                                          }}
                                        >
                                          Inactive
                                        </span>
                                      }
                                      bsSize="mini"
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row
                          className="pagination"
                          style={{
                            alignItems: 'flex-start',
                          }}
                        >
                          <Col
                            style={{
                              width: '33.33%',
                            }}
                          >
                            <button
                              onClick={() => {
                                pageNumber > 0 && handlePageChange(pageNumber - 1);
                              }}
                              disabled={pageNumber === 0}
                              className="btn-fill mx-1 btn btn-primary"
                              style={{ width: '100%' }}
                            >
                              {'Previous'}
                            </button>
                          </Col>

                          <Col
                            style={{
                              width: '33.33%',
                            }}
                          >
                            {loading ? (
                              <Spinner size={'1.6em'} />
                            ) : (
                              <Row
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <Col style={styles.flexCenter}>
                                  <span
                                    style={{
                                      textAlign: 'center',
                                      marginLeft: 20,
                                    }}
                                  >
                                    {`Page ${pageNumber + 1} out of ${maxPages + 1}`}
                                  </span>
                                </Col>
                                {/* <Col>
                                  <FormGroup>
                                    <Label for="exampleSelect">Page Size</Label>
                                    <Input
                                      type="select"
                                      name="select"
                                      id="exampleSelect"
                                    >
                                      <option disabled>Page size</option>
                                      <option>1</option>
                                      <option>2</option>
                                      <option>3</option>
                                      <option>4</option>
                                      <option>5</option>
                                    </Input>
                                  </FormGroup>
                                </Col> */}
                              </Row>
                            )}
                          </Col>

                          <Col
                            style={{
                              width: '33.33%',
                            }}
                          >
                            <button
                              onClick={() => {
                                handlePageChange(pageNumber + 1);
                              }}
                              disabled={pageNumber >= maxPages}
                              className="btn-fill mx-1 btn btn-primary"
                              style={{ width: '100%' }}
                            >
                              {'Next'}
                            </button>
                          </Col>
                        </Row>

                        <div
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        ></div>
                        <table {...getTableProps()} style={{ width: '100%' }}>
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps([
                                      {
                                        className: column.headerClassName,
                                      },
                                    ])}
                                    style={{
                                      color: 'black',
                                      fontWeight: 'bold',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    {column.render('Header')}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                  return (
                                    <th
                                      {...column.getHeaderProps([
                                        {
                                          className: column.headerClassName,
                                        },
                                      ])}
                                      style={{
                                        color: 'black',
                                        fontWeight: 'bold',
                                        borderBottom: '1px solid #ccc',
                                      }}
                                    >
                                      {column.filterable ? (
                                        <div style={{ padding: '0 10px' }}>
                                          <Input
                                            type="text"
                                            placeholder={`Filter by ${column.Header}`}
                                            name={column.id}
                                            onChange={(e) => {
                                              if (entity !== 'order') {
                                                setFilters({
                                                  ...filters,
                                                  [column.id]: e.target.value,
                                                });
                                              } else {
                                                let _field = column.id;
                                                switch (_field) {
                                                  case 'number':
                                                    _field = 'orderNumber';
                                                    break;
                                                  default:
                                                    break;
                                                }
                                                setFilters({
                                                  ...filters,
                                                  [_field]: e.target.value,
                                                });
                                              }
                                            }}
                                            id={column.id}
                                          />
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  style={{
                                    backgroundColor: i % 2 ? 'white' : '#eaeaea',
                                  }}
                                >
                                  {row.cells.map((cell, i) => {
                                    return (
                                      <td
                                        {...cell.getCellProps([
                                          {
                                            className: cell.column.className,
                                          },
                                        ])}
                                        style={{
                                          padding: '10px',
                                          borderRight: '1px solid #ccc',
                                        }}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                    </>
                  </Card>
                </TabPanel>
              ))}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const mapStateToProps = (state) => ({
  entityList: state.entities.entityList,
  entityCount: state.entities.entityCount,
  loading: state.entities.entityListLoading,
  user: state.auth.user,
  regions: state.regions.regions,
});

export default connect(mapStateToProps, {
  getEntityList,
  getRegions,
})(EntityList);
