import types from '../types/entities';
import { createMessage } from './message';
import { requestWrapper, supportedRequestMethods } from '../../API';
import { toFormData } from '../../utils';
import { verifySession } from './auth';

export const setSingleEntity = (payload) => async (dispatch) =>
  dispatch({ type: types.SET_ENTITY, payload });

const returnRoute = (entity) => {
  let route = '';
  switch (entity) {
    case 'business':
      route = 'businesses';
      break;
    case 'business-manager':
    case 'courier':
    case 'client':
    case 'order':
    case 'admin':
      route = entity + 's';
      break;
    default:
      break;
  }
  return route;
};

export const clearSingleEntity = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ENTITY });
};

export const getEntityList = ({ entity, offset, limit, filters, active }) => async (dispatch) => {
  dispatch({ type: types.ENTITIES_LOAD_ATTEMPT });

  let route = returnRoute(entity);

  let args = `?offset=${offset}&limit=${limit}${
    !['order', 'client'].includes(entity) ? (active ? '&active=1' : '&active=0') : ''
  }`;

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/${route}${entity !== 'admin' ? args : ''}`,
  };

  if (filters) {
    for (const key of Object.keys(filters)) {
      if (filters[key]) requestParams.endpoint += `&${key}=${filters[key]}`;
    }
  }

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.ENTITIES_LOAD_SUCCESS,
      payload: {
        data: response.data.data.results,
        entityCount: response.data.data.entityCount,
      },
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.ENTITIES_LOAD_FAIL,
    });
  }
};

export const createEntity = (entity, obj) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: types.ENTITY_CREATE_ATTEMPT });

    let data = ['business'].includes(entity) ? toFormData(obj) : obj;

    let route = returnRoute(entity);

    let requestParams = {
      method: supportedRequestMethods.POST,
      endpoint: `admin-api/${route}`,
      body: data,
    };
    let response = await requestWrapper(requestParams);

    if (response?.success) {
      dispatch({
        type: types.ENTITY_CREATE_SUCCESS,
      });
      let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
      if (message) dispatch(createMessage(message));
      resolve();
    } else {
      let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
      if (message) dispatch(createMessage(message));
      dispatch({
        type: types.ENTITY_CREATE_FAIL,
      });
      reject();
    }
  });
};

export const getEntity = (entity, _id) => async (dispatch) => {
  dispatch({ type: types.ENTITY_LOAD_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/${route}/${_id}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_LOAD_SUCCESS,
      payload: { ...response.data.data, entity },
    });
  } else {
    dispatch({
      type: types.ENTITY_LOAD_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const updateEntity = (entity, obj) => async (dispatch) => {
  dispatch({ type: types.ENTITY_UPDATE_ATTEMPT });
  let _id = obj._id;
  delete obj._id;

  let route = returnRoute(entity);

  let data = ['business'].includes(entity) ? toFormData(obj) : obj;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}/${_id}`,
    body: data,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_UPDATE_SUCCESS,
    });
    dispatch(getEntity(entity, _id));
  } else {
    dispatch({
      type: types.ENTITY_UPDATE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const deactivateEntity = (_id, entity) => async (dispatch) => {
  dispatch({ type: types.ENTITY_DEACTIVATE_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}/${_id}/deactivate`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_DEACTIVATE_SUCCESS,
    });
    dispatch(getEntity(entity, _id));
  } else {
    dispatch({
      type: types.ENTITY_DEACTIVATE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const reactivateEntity = (_id, entity) => async (dispatch) => {
  dispatch({ type: types.ENTITY_REACTIVATE_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}/${_id}/reactivate`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_REACTIVATE_SUCCESS,
    });
    dispatch(getEntity(entity, _id));
  } else {
    dispatch({
      type: types.ENTITY_REACTIVATE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const unpublishEntity = (_id, entity) => async (dispatch) => {
  dispatch({ type: types.ENTITY_UNPUBLISH_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}/${_id}/unpublish`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_UNPUBLISH_SUCCESS,
    });
    dispatch(getEntity(entity, _id));
  } else {
    dispatch({
      type: types.ENTITY_UNPUBLISH_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const publishEntity = (_id, entity) => async (dispatch) => {
  dispatch({ type: types.ENTITY_PUBLISH_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}/${_id}/publish`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_PUBLISH_SUCCESS,
    });
    dispatch(getEntity(entity, _id));
  } else {
    dispatch({
      type: types.ENTITY_PUBLISH_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const crownAdmin = (_id) => async (dispatch) => {
  dispatch({ type: types.CROWN_ADMIN_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/admins/crown`,
    body: { _id },
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.CROWN_ADMIN_SUCCESS,
    });
    dispatch(verifySession());
  } else {
    dispatch({
      type: types.CROWN_ADMIN_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const deleteAdmin = (_id, history) => async (dispatch) => {
  dispatch({ type: types.DELETE_ADMIN_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `admin-api/admins/${_id}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.DELETE_ADMIN_SUCCESS,
    });
    dispatch(getEntityList({ entity: 'admin', offset: 0, limit: 20 }));
    history.push('/list-admin');
  } else {
    dispatch({
      type: types.DELETE_ADMIN_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
};

export const deleteEntity= (_id, entity, history) => async (dispatch) => {
  dispatch({ type: types.ENTITY_DEACTIVATE_ATTEMPT });

  let route = returnRoute(entity);

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `admin-api/${route}/${_id}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.ENTITY_DEACTIVATE_SUCCESS,
    });
    dispatch(getEntityList({ entity: 'clients', offset: 0, limit: 20 }));
    history.push('/list-client');
  } else {
    dispatch({
      type: types.ENTITY_DEACTIVATE_FAIL,
    });
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
  }
}