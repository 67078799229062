import React, { Component } from 'react';
import { Marker } from 'google-maps-react';

export class CourierMarker extends Component {
  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  shouldComponentUpdate(nextProps) {
    const shouldUpdate =
      this.props?.courier?.lastKnownLocation?.coordinates[0] !==
        nextProps?.courier?.lastKnownLocation?.coordinates[0] ||
      this.props?.courier?.lastKnownLocation?.coordinates[1] !==
        nextProps?.courier?.lastKnownLocation?.coordinates[1];
    if (shouldUpdate) this.marker.setMap(null); // delete previous marker
    return shouldUpdate;
  }

  render() {
    let { map, google, courier, onMarkerClick, available } = this.props;

    let lat = courier?.lastKnownLocation?.coordinates[1];
    let lng = courier?.lastKnownLocation?.coordinates[0];

    let position = new google.maps.LatLng(+lat, +lng);

    const pref = {
      map: map,
      position: position,
    };
    this.marker = new google.maps.Marker(pref);
    this.marker.setClickable(true);
    this.marker.setIcon({
      url: available
        ? require('./img/courier_marker.png')
        : require('./img/unavailable_courier_marker.png'),
    });
    this.marker.addListener('click', (e) => {
      onMarkerClick(this.props.courier, this.marker, e, 'courier');
    });

    return <Marker {...{ position }} onClick={onMarkerClick} />;
  }
}

export default CourierMarker;
