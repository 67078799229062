import React, { memo, useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
export const ItemTypes = {
  ITEM: 'item',
};

const containerStyle = {
  width: 400,
};

const DndListForPreview = memo(function DndListForPreview({
  data,
  Component,
  setData,
  field,
  locale,
  openEdit,
  entity,
  goToPreview,
  saveOrder,
}) {
  const [items, setItems] = useState(data);
  const findItem = useCallback(
    (_id) => {
      const item = items.filter((c) => `${c._id}` === _id)[0];
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items]
  );

  const moveItem = useCallback(
    (_id, atIndex) => {
      const { item, index } = findItem(_id);

      setItems(
        update(items, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        })
      );
    },
    [findItem, items, setItems]
  );

  const save = () => {
    setData(entity, items);
    saveOrder(items);
    setItems([]);
    goToPreview();
  };

  const [, drop] = useDrop(() => ({ accept: ItemTypes.ITEM }));
  return (
    <div ref={drop} style={containerStyle}>
      {entity === 'sections' && (
        <Button block onClick={save}>
          Save &amp; preview
        </Button>
      )}
      {items?.map((item, index) => {
        return (
          <Component
            key={item._id}
            _id={`${item._id}`}
            text={item?.[field]?.[locale]}
            item={item}
            moveItem={moveItem}
            findItem={findItem}
            {...{ openEdit, entity }}
          />
        );
      })}
    </div>
  );
});
export default DndListForPreview;
