import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { createMessage } from '../../../../../../redux/actions/message';
import 'react-datepicker/dist/react-datepicker.css';
import { timeToTimestamp, timestampToTime } from 'utils';
let weekdays = moment.weekdays().map((v) => v.toLowerCase());

const styles = {
  workHours: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  day: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  dayRow: {
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  timeslot: {
    padding: 5,
    borderRadius: 5,
    border: '1px solid grey',
    marginRight: 5,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoursRow: {
    marginTop: 30,
    borderTop: '1px solid #bbb',
    paddingTop: 10,
  },
};

const initialHours = { start: 0, end: 0 };

const WorkHours = ({ saveHours, hours: providedHours }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [day, setDay] = useState('');
  const [workHours, setWorkHours] = useState({});
  const [hours, setHours] = useState(initialHours);
  const dispatch = useDispatch();

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleOpenModal = (d) => {
    setDay(d);
    toggleModal();
  };

  useEffect(() => {
    if (providedHours && Object.keys(providedHours)?.length) {
      setWorkHours(providedHours);
    } else {
      setWorkHours({});
    }
  }, [providedHours]);

  const handleAddWorkHours = (day, start, end) => {
    let lastSlot = workHours?.[day]?.[workHours?.[day].length - 1];
    if (!lastSlot || start > lastSlot.end) {
      setWorkHours({
        ...workHours,
        [day]: [...(workHours?.[day] || []), { start, end }],
      });
    } else {
      dispatch(createMessage('Please check hours'));
    }
  };

  const handleSetHours = (_hours, _minutes, type, day) => {
    // const offset = new Date().getTimezoneOffset();
    // const change = (offset > 0 ? offset / 60 : offset / -60) * 36e5;
    const timestamp = timeToTimestamp(_hours, _minutes);
    if (type === 'start') {
      if (!workHours?.[day]?.length) setHours({ ...hours, [type]: timestamp });
      else {
        if (timestamp > workHours[day][workHours[day].length - 1].end) {
          setHours({ ...hours, [type]: timestamp });
        } else {
          dispatch(createMessage('Please check hours'));
        }
      }
    } else if (type === 'end') {
      setHours({ ...hours, [type]: timestamp });
    }
  };

  const handleRemoveWorkHours = (day, index) => {
    setWorkHours({
      ...workHours,
      [day]: workHours?.[day]?.filter((_, i) => i !== index),
    });
  };

  const handleSaveHoursForDate = () => {
    saveHours(workHours);
    toggleModal();
    setDay('');
    setHours(initialHours);
  };

  return (
    <>
      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={true}>
        <ModalHeader>Set work hours for {day}</ModalHeader>
        <ModalBody>
          {workHours?.[day]?.map((workhours, index) => (
            <Row key={index} style={{ marginBottom: 5 }}>
              <Col xs={5}>
                {timestampToTime(workhours.start)}
                {/* <DatePicker
                  selected={new Date(workhours.start)}
                  onChange={(e) => {
                    handleAddWorkHours(
                      day,
                      new Date(0).setHours(e.getHours(), e.getMinutes(), 0, 0),
                      workhours.end
                    );
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                /> */}
              </Col>
              <Col xs={5}>
                {timestampToTime(workhours.end)}
                {/* <DatePicker
                  selected={new Date(workhours.end)}
                  onChange={(e) =>
                    handleAddWorkHours(
                      day,
                      workhours.start,
                      new Date(0).setHours(e.getHours(), e.getMinutes(), 0, 0)
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                /> */}
              </Col>
              <Col xs={2}>
                <Button
                  style={styles.button}
                  size="sm"
                  onClick={(e) => handleRemoveWorkHours(day, index)}
                >
                  -
                </Button>
              </Col>
            </Row>
          ))}
          <Row style={styles.hoursRow}>
            <Col xs={5}>
              <DatePicker
                // selected={new Date(hours.start)}
                value={timestampToTime(hours.start)}
                onChange={(e) => {
                  handleSetHours(e.getHours(), e.getMinutes(), 'start', day);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
              />
            </Col>
            <Col xs={5}>
              <DatePicker
                // selected={new Date(hours.end)}
                value={timestampToTime(hours.end)}
                onChange={(e) => handleSetHours(e.getHours(), e.getMinutes(), 'end', day)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
              />
            </Col>
            <Col xs={2}>
              <Button
                style={styles.button}
                size="sm"
                onClick={(e) => handleAddWorkHours(day, hours.start, hours.end)}
              >
                +
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="info" onClick={handleSaveHoursForDate}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Row style={styles.workHours}>
        {weekdays.map((day) => (
          <Col key={day} xs={12}>
            <Row style={styles.dayRow}>
              <Col xs={2} style={styles.day}>
                {day}
              </Col>
              <Col xs={9} style={styles.alignCenter}>
                {workHours?.[day]?.map((day, i) => (
                  <span key={i} style={styles.timeslot}>
                    {timestampToTime(day.start)} - {timestampToTime(day.end)}
                  </span>
                ))}
              </Col>
              <Col xs={1} style={{ ...styles.alignCenter, ...styles.justifyContentEnd }}>
                <Button style={styles.button} size="sm" onClick={(e) => handleOpenModal(day)}>
                  +
                </Button>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WorkHours;
