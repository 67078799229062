import * as types from '../types/dashboard';

const initialState = {
  stats: null,
  statistics: null,
  loading: false,
  statsLoading: false,
  reportLoading: false,
  reportData: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STATISTICS_LOADED:
      return {
        ...state,
        loading: false,
        statistics: action.payload,
      };
    case types.STATISTICS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        statsLoading: false,
      };
    case types.GENERATE_RECEIPT_REPORT_ATTEMPT:
      return { ...state, reportLoading: true };
    case types.GENERATE_RECEIPT_REPORT_SUCCESS:
      return { ...state, reportLoading: false, reportData: action.payload };
    case types.GENERATE_RECEIPT_REPORT_FAIL:
      return { ...state, reportLoading: false };
    case types.GENERATE_ORDER_REPORT_ATTEMPT:
      return { ...state, ordersLoading: true };
    case types.GENERATE_ORDER_REPORT_SUCCESS:
      return { ...state, ordersLoading: false, ordersData: action.payload };
    case types.GENERATE_ORDER_REPORT_FAIL:
      return { ...state, ordersLoading: false };
    default:
      return state;
  }
}
