export const GET_ORDER_ATTEMPT = 'GET_ORDER_ATTEMPT';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const CANCEL_ORDER_ATTEMPT = 'CANCEL_ORDER_ATTEMPT';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';

export const TRANSFER_DELIVERY_ATTEMPT = 'TRANSFER_DELIVERY_ATTEMPT';
export const TRANSFER_DELIVERY_SUCCESS = 'TRANSFER_DELIVERY_SUCCESS';
export const TRANSFER_DELIVERY_FAIL = 'TRANSFER_DELIVERY_FAIL';

export const GET_REGION_COURIERS_ATTEMPT = 'GET_REGION_COURIERS_ATTEMPT';
export const GET_REGION_COURIERS_SUCCESS = 'GET_REGION_COURIERS_SUCCESS';
export const GET_REGION_COURIERS_FAIL = 'GET_REGION_COURIERS_FAIL';