import types from '../types/merchandise';

const defaultLoadingState = {
  sections: false,
  items: false,
  modifications: false,
};

const initialState = {
  creating: defaultLoadingState,
  reactivating: defaultLoadingState,
  deactivating: defaultLoadingState,
  publishing: defaultLoadingState,
  unpublishing: defaultLoadingState,
  deleting: defaultLoadingState,
  updating: defaultLoadingState,
  section: null,
  item: null,
  modification: null,
  sections: [],
  items: [],
  modifications: [],
  loading: false,
};

const publishItem = (state, entity, _id) => {
  return {
    ...state,
    publishing: defaultLoadingState,
    [entity]: state?.[entity].map((v) => ({
      ...v,
      published: v._id === _id ? true : v.published,
    })),
  };
};

const unpublishItem = (state, entity, _id) => {
  return {
    ...state,
    unpublishing: defaultLoadingState,
    [entity]: state?.[entity].map((v) => ({
      ...v,
      published: v._id === _id ? false : v.published,
    })),
  };
};

const reactivateItem = (state, entity, _id) => {
  return {
    ...state,
    reactivating: defaultLoadingState,
    [entity]: state?.[entity].map((v) => ({
      ...v,
      active: v._id === _id ? true : v.active,
    })),
  };
};

const deactivateItem = (state, entity, _id) => {
  return {
    ...state,
    deactivating: defaultLoadingState,
    [entity]: state?.[entity].map((v) => ({
      ...v,
      active: v._id === _id ? false : v.active,
    })),
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UNPUBLISH_ATTEMPT.modifications:
      return {
        ...state,
        unpublishing: { ...state.unpublishing, modifications: true },
      };
    case types.UNPUBLISH_ATTEMPT.sections:
      return {
        ...state,
        unpublishing: { ...state.unpublishing, sections: true },
      };
    case types.UNPUBLISH_ATTEMPT.items:
      return { ...state, unpublishing: { ...state.unpublishing, items: true } };
    case types.UNPUBLISH_SUCCESS.modifications:
      return unpublishItem(state, 'modifications', action.payload);
    case types.UNPUBLISH_SUCCESS.sections:
      return unpublishItem(state, 'sections', action.payload);
    case types.UNPUBLISH_SUCCESS.items:
      return unpublishItem(state, 'items', action.payload);
    case types.DEACTIVATE_FAIL.modifications:
    case types.DEACTIVATE_FAIL.items:
    case types.DEACTIVATE_FAIL.sections:
      return {
        ...state,
        deactivating: defaultLoadingState,
      };
    case types.DEACTIVATE_SUCCESS.modifications:
      return deactivateItem(state, 'modifications', action.payload);
    case types.DEACTIVATE_SUCCESS.sections:
      return deactivateItem(state, 'sections', action.payload);
    case types.DEACTIVATE_SUCCESS.items:
      return deactivateItem(state, 'items', action.payload);
    case types.UNPUBLISH_FAIL.items:
    case types.UNPUBLISH_FAIL.sections:
    case types.UNPUBLISH_FAIL.modifications:
      return {
        ...state,
        unpublishing: defaultLoadingState,
      };
    case types.REACTIVATE_ATTEMPT.modifications:
      return {
        ...state,
        reactivating: { ...state.reactivating, modifications: true },
      };
    case types.REACTIVATE_ATTEMPT.sections:
      return { ...state, reactivating: { ...state.reactivating, sections: true } };
    case types.REACTIVATE_ATTEMPT.items:
      return { ...state, reactivating: { ...state.reactivating, items: true } };
    case types.DEACTIVATE_ATTEMPT.modifications:
      return {
        ...state,
        deactivating: { ...state.deactivating, modifications: true },
      };
    case types.DEACTIVATE_ATTEMPT.sections:
      return { ...state, deactivating: { ...state.deactivating, sections: true } };
    case types.DEACTIVATE_ATTEMPT.items:
      return { ...state, deactivating: { ...state.deactivating, items: true } };
    case types.PUBLISH_ATTEMPT.modifications:
      return {
        ...state,
        publishing: { ...state.publishing, modifications: true },
      };
    case types.PUBLISH_ATTEMPT.sections:
      return { ...state, publishing: { ...state.publishing, sections: true } };
    case types.PUBLISH_ATTEMPT.items:
      return { ...state, publishing: { ...state.publishing, items: true } };
    case types.PUBLISH_SUCCESS.modifications:
      return publishItem(state, 'modifications', action.payload);
    case types.PUBLISH_SUCCESS.sections:
      return publishItem(state, 'sections', action.payload);
    case types.PUBLISH_SUCCESS.items:
      return publishItem(state, 'items', action.payload);
    case types.REACTIVATE_SUCCESS.modifications:
      return reactivateItem(state, 'modifications', action.payload);
    case types.REACTIVATE_SUCCESS.sections:
      return reactivateItem(state, 'sections', action.payload);
    case types.REACTIVATE_SUCCESS.items:
      return reactivateItem(state, 'items', action.payload);
    case types.PUBLISH_FAIL.items:
    case types.PUBLISH_FAIL.sections:
    case types.PUBLISH_FAIL.modifications:
      return {
        ...state,
        publishing: defaultLoadingState,
      };
    case types.UPDATE_ATTEMPT.modifications:
      return { ...state, updating: { ...state.updating, modifications: true } };
    case types.UPDATE_ATTEMPT.items:
      return { ...state, updating: { ...state.updating, items: true } };
    case types.UPDATE_ATTEMPT.sections:
      return { ...state, updating: { ...state.updating, sections: true } };
    case types.UPDATE_SUCCESS.items:
    case types.UPDATE_SUCCESS.modifications:
    case types.UPDATE_SUCCESS.sections:
    case types.UPDATE_FAIL.items:
    case types.UPDATE_FAIL.modifications:
    case types.UPDATE_FAIL.sections:
      return {
        ...state,
        updating: defaultLoadingState,
      };
    case types.CREATE_ATTEMPT.modifications:
      return { ...state, creating: { ...state.creating, modifications: true } };
    case types.CREATE_ATTEMPT.items:
      return { ...state, creating: { ...state.creating, items: true } };
    case types.CREATE_ATTEMPT.sections:
      return { ...state, creating: { ...state.creating, sections: true } };
    case types.CREATE_SUCCESS.items:
    case types.CREATE_SUCCESS.modifications:
    case types.CREATE_SUCCESS.sections:
    case types.CREATE_FAIL.items:
    case types.CREATE_FAIL.modifications:
    case types.CREATE_FAIL.sections:
    case types.GET_ATTEMPT.sections:
      return {
        ...state,
        creating: defaultLoadingState,
      };
    case types.GET_ATTEMPT.items:
    case types.GET_ATTEMPT.modifications:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUCCESS.sections:
      return {
        ...state,
        loading: false,
        sections: action.payload,
      };
    case types.GET_SUCCESS.items:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case types.GET_SUCCESS.modifications:
      return {
        ...state,
        loading: false,
        modifications: action.payload,
      };
    case types.GET_FAIL.sections:
    case types.GET_FAIL.items:
    case types.GET_FAIL.modifications:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
