import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import PrivateRoute from 'components/PrivateRoute';
import Login from './views/pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { verifySession } from './redux/actions/auth';
import 'react-datepicker/dist/react-datepicker.css';
import { messaging } from './init-fcm';
import { newNotification } from './redux/actions/message';
import { updateFirebaseToken } from './redux/actions/auth';
import { SET_FB_TOKEN } from './redux/types/auth';
import 'cropperjs/dist/cropper.css';

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifySession());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      (async () => {
        const token = messaging ? await messaging.getToken() : null;
        if (token) {
          dispatch({ type: SET_FB_TOKEN, payload: token });
          dispatch(updateFirebaseToken());
        }
        navigator.serviceWorker.addEventListener('message', (message) => {
          dispatch(
            newNotification(
              message.data['firebase-messaging-msg-data'].data?.body ||
                message.data['firebase-messaging-msg-data'].notification?.body,
              message.data['firebase-messaging-msg-data'].data?.orderId
            )
          );
        });
      })();
    }
  }, [user, dispatch]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute path="/" component={Layout} />
      {/* <Route path="/" component={Layout} /> */}
    </Switch>
  );
};

export default App;
