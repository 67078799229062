import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { deleteAdmin } from 'redux/actions/entities';

const DeleteAdminModal = ({ visible, setVisible, _id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { deletingEntity } = useSelector((state) => state.entities);

  const handleDelete = async () => {
    await dispatch(deleteAdmin(_id, history));
    setVisible(false);
  };
  return (
    <Modal
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      className="text-center"
    >
      <ModalHeader>Are you sure you want to delete this admin?</ModalHeader>

      <ModalBody>
        <Row>
          <Col xs={12}>
            <h4>
              The admin will not be able to connect to the dashboard any more
              after this
            </h4>
          </Col>
          {deletingEntity ? (
            <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </Col>
          ) : (
            <>
              <Col xs={6}>
                <Button color="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
              <Col xs={6}>
                <Button>Cancel</Button>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAdminModal;
