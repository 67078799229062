import {
  USER_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_CREDENTIALS,
  LOGOUT,
  AUTH_VERIFIED_SUCCESS,
  AUTH_VERIFIED_FAIL,
  SET_FB_TOKEN,
} from '../types/auth';

const initialState = {
  authenticated: false,
  verifyingSession: true,
  isLoading: false,
  otpSent: false,
  crowned: false,
  user: null,
  firebaseToken: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FB_TOKEN:
      return {
        ...state,
        firebaseToken: action.payload,
      };
    case AUTH_VERIFIED_SUCCESS:
      return {
        ...state,
        authenticated: true,
        verifyingSession: false,
        isLoading: false,
        user: action.payload._id,
        crowned: action.payload.crowned,
      };
    case AUTH_VERIFIED_FAIL:
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        verifyingSession: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpSent: true,
      };
    // case LOGIN_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     authenticated: true,
    //     verifyingSession: false,
    //   };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        // crowned: false,
        otpSent: false,
      };
    case SEND_OTP_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        verifyingSession: false,
      };
    case RESET_CREDENTIALS:
      return {
        ...state,
        otpSent: false,
      };
    case LOGIN_SUCCESS:
    default:
      return state;
  }
}
