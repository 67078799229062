import React from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';

export const RedStar = () => (
  <span style={{ fontSize: '1.1rem', color: 'red' }}>*</span>
);

const CreateEntityField = ({
  field,
  value,
  locale = 'en',
  checked,
  handleOnChange,
  min = 0,
  max = 4999,
}) => {
  let content;
  switch (field.type) {
    case 'select':
      content = (
        <FormGroup>
          <Label for={field.key}>
            {field?.required && <RedStar />}
            {field.text}
          </Label>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            name="singleSelect"
            components={{
              ClearIndicator: () => null,
            }}
            value={value}
            onChange={(e) => {
              handleOnChange(e, field, locale, field.multi);
            }}
            disabled={field?.editable === false}
            options={field.options}
            isMulti={field.multi}
            placeholder="Choose"
            noOptionsMessage={() => 'No more options'}
            closeMenuOnSelect={field.multi ? false : true}
          />
        </FormGroup>
      );
      break;
    case 'checkbox':
      content = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '20px',
          }}
        >
          <input
            type="checkbox"
            name={field.key}
            checked={checked}
            onChange={(e) => handleOnChange(e, field, locale)}
            disabled={field?.editable === false}
            style={{
              marginLeft: '10px',
              width: 20,
              height: 20,
            }}
          />
          <Label for={field.key}>
            {field?.required && <RedStar />}
            {field.text}
          </Label>
        </div>
      );
      break;
    default:
      content = (
        <FormGroup>
          <Label for={field.key}>
            {field?.required && <RedStar />}
            {field.text}
          </Label>
          <Input
            type={field?.inputType ?? 'text'}
            min={min}
            max={max}
            maxLength={field.maxLength ?? 500}
            name={field.key}
            value={field.localization ? value?.[locale] ?? '' : value}
            onChange={(e) => handleOnChange(e, field, locale)}
            disabled={field?.editable === false}
            id={field.key}
          />
        </FormGroup>
      );
      break;
  }
  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Col>{content}</Col>
    </Row>
  );
};

export default CreateEntityField;
