import { createMessage } from './message';
import { requestWrapper, supportedRequestMethods } from '../../API';
import * as types from '../types/business-manager';

const BMS_LIMIT = 10;

export const setBMs = (bms) => (dispatch) => {
  dispatch({ type: types.SET_BMS, payload: bms });
};

export const setBMsPage = (page) => (dispatch) => {
  dispatch({ type: types.SET_BMS_PAGE, payload: page });
};

export const getBms = (page, _id) => async (dispatch) => {
  dispatch({ type: types.GET_BMS_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/business-managers?offset=${page}&limit=${BMS_LIMIT}&business=${_id}`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.GET_BMS_SUCCESS,
      payload: {
        data: response.data.data,
        bmsExhausted: response.data.data.length < BMS_LIMIT,
      },
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.GET_BMS_FAIL,
    });
  }
};

export const createBM =
  ({ firstName, lastName, email, business }) =>
  async (dispatch) => {
    dispatch({ type: types.CREATE_BM_ATTEMPT });

    let requestParams = {
      method: supportedRequestMethods.POST,
      endpoint: `admin-api/business-managers`,
      body: {
        firstName,
        lastName,
        email,
        business,
      },
    };

    let response = await requestWrapper(requestParams);

    if (response?.success) {
      dispatch({
        type: types.CREATE_BM_SUCCESS,
      });
      dispatch(setBMsPage(0));
      dispatch(setBMs([]));
      dispatch(getBms(0, business));
    } else {
      let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
      if (message) dispatch(createMessage(message));
      dispatch({
        type: types.CREATE_BM_FAIL,
      });
    }
  };

export const deleteBM = (bmId, businessId) => async (dispatch) => {
  dispatch({ type: types.DELETE_BM_ATTEMPT, payload: bmId });

  let requestParams = {
    method: supportedRequestMethods.DELETE,
    endpoint: `admin-api/business-managers/${bmId}`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: types.DELETE_BM_SUCCESS,
    });
    dispatch(setBMsPage(0));
    dispatch(setBMs([]));
    dispatch(getBms(0, businessId));
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.DELETE_BM_FAIL,
    });
  }
};
