/**
 * Project configuration
 *
 * In order to change colors, got to index.css and edit them
 */

export const project = {
  name: 'Pika Admin',
  sidebarText: 'Pika',
  login: {
    mainText: 'Pika Admin Panel',
    sendButtonText: 'Send otp',
    sendAgainButtonText: 'Send again',
    goBackButtonText: 'Go Back',
    continueButtonText: 'Continue',
  },
  // path from assets
  img: require('../assets/img/custom/logo.svg'),
  icon: require('../assets/img/custom/logo.png'),
};
