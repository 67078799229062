import React, { useRef } from 'react';
import { project } from '../../../../config/index';
import { Row, Col, FormGroup, Button, Input } from 'reactstrap';

const styles = {
  imageContainer: {
    width: '100%',
    height: 'auto',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    borderRadius: '10px',
  },
  whiteFont: {
    color: 'white',
  },
};

const FileUploadField = ({ field, file, currentFile, handleFileUpload }) => {
  const ref = useRef(null);

  return (
    <Row>
      <Col>
        <div style={styles.imageContainer}>
          <img
            style={styles.image}
            src={
              file
                ? file
                : currentFile
                ? `${process.env.REACT_APP_PUBLIC_URL}${currentFile}`
                : project.img
            }
            alt={field.key}
          />
        </div>
        <FormGroup style={styles.imageContainer}>
          <Button htmlFor={field.key}>Select {field.text}</Button>
          <Input
            type="file"
            name="file"
            ref={ref}
            id={field.key}
            onChange={(e) => handleFileUpload(e, field)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default FileUploadField;
