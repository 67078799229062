import React from 'react';
import { Container } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';
const pkg = require('../../../package.json');

class Footer extends React.Component {
  render() {
    return (
      <Container fluid={this.props.fluid ? true : false} style={{ paddingLeft: 50 }}>
        <div className="copyright" dir="ltr" style={{ float: 'none', textAlign: 'right' }}>
          Powered by{' '}
          <a
            href="https://www.branded.co.il/"
            target="_blank"
            rel="noopener noreferrer"
            className="branded-link"
          >
            Branded
          </a>
          <br />
          version {pkg.version}
        </div>
      </Container>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
