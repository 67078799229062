import React from 'react';
import { Route, Switch } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";
import { Helmet } from 'react-helmet';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import { routes } from './routes.js';

// import { Button, InputGroup, Nav } from "reactstrap";
import { connect } from 'react-redux';
import { sideBarRoutes } from './routes';
import { project } from './config';

var ps;

const RouteWithTitle = ({ title, ...props }) => (
  <>
    <Helmet>
      <title>
        {title} | {project.name}
      </title>
    </Helmet>
    <Route {...props} />
  </>
);

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: 'primary',
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }

  componentDidMount() {
    // open side menu by default
    document.body.classList.toggle('sidebar-mini');
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.refs.mainPanel);
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener('scroll', this.showNavbarButton);
    // on this page, we need on the body tag the classes .rtl and .menu-on-right
    // an on the html tag the classe .rtl-active
    // document.body.classList.add('rtl', 'menu-on-right');
    // document.documentElement.classList.add('rtl-active');
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
    window.removeEventListener('scroll', this.showNavbarButton);
    // when we exit this page, we need to delete the classes .rtl and .menu-on-right
    // from the body tag and the class rtl-active from the html tag
    // document.body.classList.remove('rtl', 'menu-on-right');
    // document.documentElement.classList.remove('rtl-active');
  }

  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf('Win') > -1) {
        let tables = document.querySelectorAll('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  showNavbarButton = () => {
    if (
      (document.documentElement.scrollTop > 50 ||
        document.scrollingElement.scrollTop > 50 ||
        this.refs.mainPanel.scrollTop > 50) &&
      this.state.opacity === 0
    ) {
      this.setState({ opacity: 1 });
    }
    if (
      document.documentElement.scrollTop <= 50 &&
      document.scrollingElement.scrollTop <= 50 &&
      this.refs.mainPanel.scrollTop <= 50 &&
      this.state.opacity === 1
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = (routes) => {
    return routes
      .filter((v) => !v.onlyCrowned || this.props.crowned) // hide routes that are meant to only crowned admins
      .map((prop, key) => {
        if (prop.collapse) {
          return this.getRoutes(prop.views);
        }
        if (prop.specific) {
          return (
            <RouteWithTitle
              title={prop.name}
              exact
              path={prop.path + prop.specific}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return (
            <RouteWithTitle
              title={prop.name}
              exact
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      });
  };

  getActiveRoute = (routes) => {
    let activeRoute = project.name;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle('nav-open');
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove('nav-open');
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={sideBarRoutes.filter(
            (v) => !v.onlyCrowned || this.props.crowned
          )}
          activeColor={this.state.activeColor}
          rtlActive={false}
          logo={{
            outterLink: '/map',
            text: project.sidebarText,
            imgSrc: project.icon,
          }}
          closeSidebar={this.closeSidebar}
        />

        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />

          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  crowned: state.auth.crowned,
});

export default connect(mapStateToProps, {})(Admin);
