import { requestWrapper, supportedRequestMethods } from '../../API';
import * as types from '../types/dashboard';

import { createMessage } from './message';

export const getStatistics = () => async (dispatch) => {
  dispatch({ type: types.DATA_LOADING });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: '/admin/statistics',
  };

  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: types.STATISTICS_LOADED,
      payload: response.data.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: types.STATISTICS_LOAD_FAIL,
    });
  }
};

export const generateReceiptReport = (start, end) => async (dispatch) => {
  dispatch({ type: types.GENERATE_RECEIPT_REPORT_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/export-receipts?startDate=${start}&endDate=${end}`,
  };

  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: types.GENERATE_RECEIPT_REPORT_SUCCESS,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: types.GENERATE_RECEIPT_REPORT_FAIL,
    });
  }
};

export const generateOrderReport = (start, end) => async (dispatch) => {
  dispatch({ type: types.GENERATE_ORDER_REPORT_ATTEMPT });

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/export-orders?startDate=${start}&endDate=${end}`,
  };

  let response = await requestWrapper(requestParams);
  if (response && response.success) {
    dispatch({
      type: types.GENERATE_ORDER_REPORT_SUCCESS,
      payload: response.data,
    });
  } else {
    if (response) dispatch(createMessage(response.data.message));
    dispatch({
      type: types.GENERATE_ORDER_REPORT_FAIL,
    });
  }
};
