import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';

import { Button, Card, CardBody, Row, Col, CardFooter, Form, FormGroup, Label } from 'reactstrap';

import { allowedLocales } from '../../../config/locales';
import { createEntity } from '../../../redux/actions/entities';
import { entities, entityFields } from './entities';
import { useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreateEntityField, { RedStar } from './components/CreateEntityField';
import WorkHours from './components/entityTabs/business/WorkHours';
import systemEntitiesList from '../../../config/entities';
import PlacesAutoComplete from 'components/PlacesAutoComplete';
import { validate } from 'utils';
import { createMessage } from 'redux/actions/message';
import CropperUpload from 'components/CropperUpload';
import SmallMap from 'components/SmallMap';

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: '1.3rem',
  },
  tab: { padding: '5px 20px' },
};

const CreateEntity = (props) => {
  const { createEntity, creatingEntity, match, history, createMessage } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [entity, setEntity] = useState('');
  const [type, setType] = useState({
    value: '',
    label: '',
  });
  const [fields, setFields] = useState([]);
  const [tab, setTab] = useState(0);

  const handleSetType = useCallback(
    async (selectedOption) => {
      setType(selectedOption);
      let beforeHook = entityFields?.[selectedOption.value]?.beforeHook;
      let fields = entityFields?.[selectedOption.value]?.fields?.filter((v) => v.showOnCreate);
      if (beforeHook) {
        let beforeHookResponse = await beforeHook(dispatch);
        beforeHookResponse &&
          Object.keys(beforeHookResponse).forEach((key) => {
            fields = fields.map((v) => {
              if (v.key === key) {
                v.options = beforeHookResponse?.[key]?.map((v) => ({
                  value: v._id,
                  label: v?.name?.en ?? v.name,
                }));
              }
              return v;
            });
          });
        setFields(fields);
      } else {
        setFields(fields);
      }
      setState(
        fields.reduce((obj, field) => {
          return {
            ...obj,
            [field.key]: field.value,
          };
        }, {})
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (match.path) {
      let model = match.path.slice(8);
      if (systemEntitiesList.includes(model)) {
        setEntity(model);
        handleSetType({
          value: entities[model].model,
          label: entities[model].single,
        });
      } else {
        // history.push('/');
      }
    }
  }, [handleSetType, match.path, history]);

  const handleEntityCreate = async () => {
    const entity = {
      ...state,
    };
    let obj = entityFields[type.value].transform(entity);
    let { isValid, errors } = validate(type.value, obj);
    if (isValid) {
      await createEntity(type.value, obj);
      setState({
        ...entityFields?.[type.value]?.fields.reduce((obj, item) => {
          const { key, value } = item;
          return {
            ...obj,
            [key]: value,
          };
        }, {}),
        workHours: {},
        primaryImage: {},
      });
    } else {
      createMessage(`Invalid input - ${errors[0]}`);
    }
  };

  const handleOnChange = (e, field, locale, isMulti) => {
    switch (field.type) {
      case 'select':
        if (isMulti && e) {
          setState({
            ...state,
            [field.key]: [...e],
          });
        } else if (isMulti && !e) {
          setState({
            ...state,
            [field.key]: [],
          });
        } else {
          setState({
            ...state,
            [field.key]: e,
          });
        }
        break;
      case 'checkbox':
        setState({
          ...state,
          [field.key]: e.target.checked,
        });
        break;
      default:
        setState({
          ...state,
          [field.key]: field?.localization
            ? {
                ...(state?.[field.key] || {}),
                [locale]: e.target.value,
              }
            : field.regex
            ? e.target.value.replace(field.regex, '')
            : e.target.value,
        });
        break;
    }
  };

  // for reference, a multi choice select
  // useEffect(() => {
  //   setMultiSelectOptions(
  //     companies.map((v) => ({ value: v._id, label: v.name }))
  //   );
  // }, []);

  const handleTabChange = (index) => {
    setTab(index);
  };

  const saveHours = (workHours) => {
    setState({ ...state, workHours });
  };

  const setImage = (img) => {
    setState({
      ...state,
      primaryImage: {
        binary: img,
      },
    });
  };

  return (
    <>
      <div className="content">
        <h2 className="text-center">Create {entity}</h2>
        <Row>
          <Col xs={12} className="text-left">
            <Card style={{ padding: '10px' }}>
              <CardBody>
                <Row style={{ justifyContent: 'center' }}>
                  <Col xs={12} lg={6}>
                    {!!fields.filter((v) => v.localization).length && (
                      <>
                        <div style={styles.centerContent}>
                          <span style={styles.bigFont}>Localized fields</span>
                        </div>
                        <Tabs selectedIndex={tab} onSelect={(index) => handleTabChange(index)}>
                          <TabList style={{ display: 'flex' }}>
                            {allowedLocales.map((locale, index) => (
                              <Tab style={styles.tab} key={index}>
                                <div style={styles.bigFont}>{locale}</div>
                              </Tab>
                            ))}
                          </TabList>
                          {allowedLocales.map((locale, index) => (
                            <TabPanel key={index}>
                              <Form>
                                {fields
                                  .filter(
                                    (v) =>
                                      v.localization &&
                                      !['image', 'address', 'workHours'].includes(v.type)
                                  )
                                  .map((field, index) => {
                                    return (
                                      <CreateEntityField
                                        key={index}
                                        value={state?.[field?.key]}
                                        checked={state?.[field?.key]}
                                        {...{
                                          field,
                                          handleOnChange,
                                          locale,
                                        }}
                                      />
                                    );
                                  })}
                              </Form>
                            </TabPanel>
                          ))}
                        </Tabs>
                      </>
                    )}
                    <div style={styles.centerContent}>
                      <span style={styles.bigFont}>Non-localized fields</span>
                    </div>
                    {fields
                      .filter(
                        (v) =>
                          !v.localization && !['image', 'address', 'workHours'].includes(v.type)
                      )
                      .map((field, index) => {
                        return (
                          <CreateEntityField
                            key={index}
                            value={state?.[field?.key] ?? ''}
                            checked={state?.[field?.key] ?? false}
                            selectOptions={field.options ?? []}
                            {...{
                              field,
                              handleOnChange,
                            }}
                          />
                        );
                      })}
                    {fields
                      .filter((v) => ['address'].includes(v.type))
                      .map((field, index) => {
                        return (
                          <div key={field.key}>
                            <FormGroup>
                              <Label for={field.key}>
                                {field?.required && <RedStar />}
                                {field.text}
                              </Label>
                              <PlacesAutoComplete
                                value={state?.address}
                                handleSelect={(address, location) => {
                                  setState({
                                    ...state,
                                    address: address,
                                    location: location,
                                  });
                                }}
                              />
                            </FormGroup>
                            {state?.location?.coordinates?.length === 2 ||
                            state?.location?.length === 2 ? (
                              <SmallMap
                                coordinates={
                                  state?.location?.coordinates?.length === 2
                                    ? state?.location?.coordinates
                                    : [state?.location[1], state?.location[0]]
                                }
                              />
                            ) : (
                              'No coordinates provided'
                            )}
                          </div>
                        );
                      })}
                    {fields
                      .filter((v) => ['workHours'].includes(v.type))
                      .map((field, index) => {
                        return (
                          <div key={field.key}>
                            <RedStar /> <strong>Work hours</strong>
                            <WorkHours {...{ hours: state?.[field?.key], saveHours }} key={index} />
                          </div>
                        );
                      })}
                    <Row>
                      {fields
                        .filter((v) => v.type === 'image')
                        .map((field, index) => {
                          return (
                            <Col key={index} xs={12}>
                              <CropperUpload
                                aspect={16 / 9}
                                aspectText={'16/9'}
                                img={state?.[field?.key]?.path}
                                {...{ setImage }}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              {type && Object.keys(state)?.length > 0 && (
                <CardFooter>
                  {creatingEntity ? (
                    <Spinner />
                  ) : (
                    type && <Button onClick={handleEntityCreate}>Create</Button>
                  )}
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  creatingEntity: state.entities.creatingEntity,
});

export default connect(mapStateToProps, { createEntity, createMessage })(CreateEntity);
