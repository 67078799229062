import React, { Component } from 'react';
import { Marker } from 'google-maps-react';

export class RideMarker extends Component {
  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.order._id !== nextProps.order._id;
  }

  render() {
    let { map, google, order, onMarkerClick } = this.props;

    let lat = order?.business?.location?.coordinates[1];
    let lng = order?.business?.location?.coordinates[0];

    let position = new google.maps.LatLng(+lat, +lng);

    const pref = {
      map: map,
      position: position,
    };
    this.marker = new google.maps.Marker(pref);
    this.marker.setClickable(true);
    this.marker.setIcon({
      url: require('./img/business_marker.png'),
    });
    this.marker.addListener('click', (e) => {
      onMarkerClick(this.props.order, this.marker, e, 'order');
    });

    return <Marker {...{ position }} onClick={onMarkerClick} />;
  }
}

export default RideMarker;
