import types from '../types/entities';

const initialState = {
  entityList: [],
  entityListLoading: false,
  singleEntity: null,
  singleEntityLoading: null,
  entityCount: 0,
  creatingEntity: false,
  updatingEntity: false,
  publishingEntity: false,
  deletingEntity: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.DELETE_ADMIN_ATTEMPT:
    case types.CROWN_ADMIN_ATTEMPT:
      return {
        ...state,
        deletingEntity: true,
      };
    case types.DELETE_ADMIN_FAIL:
    case types.DELETE_ADMIN_SUCCESS:
    case types.CROWN_ADMIN_FAIL:
    case types.CROWN_ADMIN_SUCCESS:
      return {
        ...state,
        deletingEntity: false,
      };
    case types.ENTITY_PUBLISH_ATTEMPT:
      return { ...state, publishingEntity: true };
    case types.ENTITY_PUBLISH_SUCCESS:
      return {
        ...state,
        publishingEntity: false,
        singleEntity: { ...state.singleEntity, published: true },
      };
    case types.ENTITY_PUBLISH_FAIL:
      return { ...state, publishingEntity: false };
    case types.ENTITY_UNPUBLISH_ATTEMPT:
      return { ...state, publishingEntity: true };
    case types.ENTITY_UNPUBLISH_SUCCESS:
      return {
        ...state,
        publishingEntity: false,
        singleEntity: { ...state.singleEntity, published: false },
      };
    case types.ENTITY_UNPUBLISH_FAIL:
      return { ...state, publishingEntity: false };
    case types.ENTITY_UPDATE_ATTEMPT:
      return { ...state, updatingEntity: true };
    case types.ENTITY_UPDATE_SUCCESS:
    case types.ENTITY_UPDATE_FAIL:
      return { ...state, updatingEntity: false, singleEntity: null };
    case types.ENTITY_CREATE_ATTEMPT:
      return { ...state, creatingEntity: true };
    case types.ENTITY_CREATE_SUCCESS:
    case types.ENTITY_CREATE_FAIL:
      return { ...state, creatingEntity: false };
    case types.SET_ENTITY:
      return { ...state, singleEntity: action.payload };
    case types.ENTITY_LOAD_ATTEMPT:
      return { ...state, singleEntityLoading: true };
    case types.ENTITY_LOAD_SUCCESS:
      return {
        ...state,
        singleEntityLoading: false,
        singleEntity: action.payload,
      };
    case types.ENTITY_LOAD_FAIL:
      return { ...state, singleEntityLoading: false };
    case types.ENTITIES_LOAD_ATTEMPT:
      return {
        ...state,
        entityListLoading: true,
      };
    case types.ENTITIES_LOAD_SUCCESS:
      return {
        ...state,
        entityListLoading: false,
        entityList: action.payload.data,
        entityCount: action.payload.entityCount,
      };
    case types.ENTITIES_LOAD_FAIL:
      return {
        ...state,
        entityListLoading: false,
      };
    default:
      return state;
  }
}
