import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { crownAdmin } from 'redux/actions/entities';

const CrownAdminModal = ({ visible, setVisible, _id }) => {
  const dispatch = useDispatch();
  const { deletingEntity } = useSelector((state) => state.entities);

  const handleCrown = async () => {
    await dispatch(crownAdmin(_id));
    setVisible(false);
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      className="text-center"
    >
      <ModalHeader>Are you sure you want to crown this admin?</ModalHeader>

      <ModalBody>
        <Row>
          <Col xs={12}>
            <h4>
              This admin will become the master of the system and you will not
              be able to reverse this action, see and create admins and
              manipulate them in any way.
            </h4>
          </Col>
          {deletingEntity ? (
            <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </Col>
          ) : (
            <>
              <Col xs={6}>
                <Button color="danger" onClick={handleCrown}>
                  Crown
                </Button>
              </Col>
              <Col xs={6}>
                <Button>Cancel</Button>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CrownAdminModal;
