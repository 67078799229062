import types from '../types/merchandise';
import { createMessage } from './message';
import { requestWrapper, supportedRequestMethods } from '../../API';
import { toFormData } from 'utils';

export const setSection = (section) => async (dispatch) =>
  dispatch({ type: types.SET_SINGLE.section, payload: section });

export const setSections = (sections) => async (dispatch) =>
  dispatch({ type: types.SET_LIST.section, payload: sections });

export const setItem = (item) => async (dispatch) =>
  dispatch({ type: types.SET_SINGLE.items, payload: item });

export const setItems = (items) => async (dispatch) =>
  dispatch({ type: types.SET_LIST.items, payload: items });

export const setModification = (modification) => async (dispatch) =>
  dispatch({ type: types.SET_SINGLE.modifications, payload: modification });

export const setModifications = (modifications) => async (dispatch) =>
  dispatch({ type: types.SET_LIST.modifications, payload: modifications });

export const createMerchEntity = (entity, obj, businessId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: types.CREATE_ATTEMPT?.[entity] });

    let formData = entity !== 'modifications' ? toFormData(obj) : obj;

    let route = `businesses/${businessId}/merchandise/${entity}`;

    let requestParams = {
      method: supportedRequestMethods.POST,
      endpoint: `admin-api/${route}`,
      body: entity === 'sections' ? obj : formData,
    };

    let response = await requestWrapper(requestParams);
    if (response?.success) {
      dispatch({
        type: types.CREATE_SUCCESS?.[entity],
      });
      dispatch(getMerchEntity(entity, businessId));
      resolve('');
    } else {
      let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
      if (message) dispatch(createMessage(message));
      dispatch({
        type: types.CREATE_FAIL?.[entity],
      });
      reject('');
    }
  });
};

export const updateMerchEntity = (entity, obj) => async (dispatch, getState) => {
  dispatch({ type: types.UPDATE_ATTEMPT[entity] });

  let route = `businesses/merchandise/${entity}/${obj._id}`;

  delete obj._id;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}`,
    body: entity !== 'items' ? obj : toFormData(obj),
  };

  let businessId = getState().entities?.singleEntity?._id;

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.UPDATE_SUCCESS[entity],
    });
    dispatch(getMerchEntity(entity, businessId));
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.UPDATE_FAIL[entity],
    });
  }
};

// list of all sections, items or modifications, not in pagination.
export const getMerchEntity = (entity, businessId) => async (dispatch) => {
  dispatch({ type: types.GET_ATTEMPT?.[entity] });

  let route = `businesses/${businessId}/merchandise/${entity}`;

  let requestParams = {
    method: supportedRequestMethods.GET,
    endpoint: `admin-api/${route}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.GET_SUCCESS?.[entity],
      payload: response.data.data,
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.GET_FAIL?.[entity],
    });
  }
};

export const publishMerchEntity = (entity, _id) => async (dispatch) => {
  dispatch({ type: types.PUBLISH_ATTEMPT?.[entity] });

  let route = `businesses/merchandise/${entity}/${_id}/publish`;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.PUBLISH_SUCCESS?.[entity],
      payload: _id,
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.PUBLISH_FAIL?.[entity],
    });
  }
};

export const unpublishMerchEntity = (entity, _id) => async (dispatch) => {
  dispatch({ type: types.UNPUBLISH_ATTEMPT?.[entity] });

  let route = `businesses/merchandise/${entity}/${_id}/unpublish`;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.UNPUBLISH_SUCCESS?.[entity],
      payload: _id,
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.UNPUBLISH_FAIL?.[entity],
    });
  }
};

export const reactivateMerchEntity = (entity, _id) => async (dispatch) => {
  dispatch({ type: types.REACTIVATE_ATTEMPT?.[entity] });

  let route = `businesses/merchandise/${entity}/${_id}/reactivate`;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.REACTIVATE_SUCCESS?.[entity],
      payload: _id,
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.REACTIVATE_FAIL?.[entity],
    });
  }
};

export const deactivateMerchEntity = (entity, _id) => async (dispatch) => {
  dispatch({ type: types.DEACTIVATE_ATTEMPT?.[entity] });

  let route = `businesses/merchandise/${entity}/${_id}/deactivate`;

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/${route}`,
  };

  let response = await requestWrapper(requestParams);
  if (response?.success) {
    dispatch({
      type: types.DEACTIVATE_SUCCESS?.[entity],
      payload: _id,
    });
  } else {
    let message = response?.data?.message ?? response?.data?.errors?.[0]?.message;
    if (message) dispatch(createMessage(message));
    dispatch({
      type: types.DEACTIVATE_FAIL?.[entity],
    });
  }
};
