import { createMessage } from './message';

import { requestWrapper, supportedRequestMethods } from '../../API';

import {
  USER_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_CREDENTIALS,
  LOGOUT,
  AUTH_VERIFIED_SUCCESS,
  AUTH_VERIFIED_FAIL,
  UPDATE_FB_TOKEN_SUCCESS,
  UPDATE_FB_TOKEN_FAIL,
} from '../types/auth';

/*
 * action creators
 */

export const sendCredentials = (email) => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: 'admin-api/login',
    body: {
      email,
    },
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: SEND_OTP_SUCCESS,
    });

    return response.data;
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: SEND_OTP_FAIL,
    });
  }
};

export const resetCredentials = () => async (dispatch) => {
  dispatch({ type: RESET_CREDENTIALS });
};

export const login = (email, otp) => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin-api/login`,
    body: {
      email,
      otp,
    },
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: LOGIN_SUCCESS,
    });
    dispatch(verifySession());
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch) => {
  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin-api/logout`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({ type: LOGOUT });
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: AUTH_VERIFIED_FAIL,
    });
  }
};

export const verifySession = () => async (dispatch) => {
  dispatch({ type: USER_LOADING });

  let requestParams = {
    method: supportedRequestMethods.POST,
    endpoint: `admin-api/verify-authentication`,
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: AUTH_VERIFIED_SUCCESS,
      payload: {
        _id: response.data.data._id,
        crowned: response.data.data.crowned,
      },
    });
  } else {
    response?.data?.message &&
      dispatch(
        createMessage(response.data.message_he ?? response.data.message)
      );
    dispatch({
      type: AUTH_VERIFIED_FAIL,
    });
  }
};

export const updateFirebaseToken = () => async (dispatch, getState) => {
  const { firebaseToken } = getState().auth;

  if (!firebaseToken) return;

  // const formData = new FormData();

  // formData.append('firebaseToken', firebaseToken);

  let requestParams = {
    method: supportedRequestMethods.PATCH,
    endpoint: `admin-api/profile`,
    body: { firebaseToken },
  };

  let response = await requestWrapper(requestParams);

  if (response?.success) {
    dispatch({
      type: UPDATE_FB_TOKEN_SUCCESS,
    });
  } else {
    dispatch({
      type: UPDATE_FB_TOKEN_FAIL,
    });
  }
};
