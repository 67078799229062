import imageCompression from 'browser-image-compression';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useTable } from 'react-table';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import { createMessage } from 'redux/actions/message';
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from '../../redux/actions/categories';
import FileUploadField from './Entities/components/FileUploadField';
import Cropper from 'react-cropper';

const columns = [
  {
    Header: 'Name En',
    accessor: 'name.en',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: true,
  },
  {
    Header: 'Name He',
    accessor: 'name.he',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: true,
  },
  {
    Header: 'Name Ar',
    accessor: 'name.ar',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: true,
  },
  {
    Header: 'Image',
    accessor: 'image.path',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    headerClassName: 'text-center',
    className: 'text-center',
    filterable: false,
    sortable: false,
  },
];

const PAGE_SIZE = 20;

const initialState = {
  name: {
    en: '',
    he: '',
    ar: '',
  },
  image: {
    file: null,
    binary: null,
  },
};

const Categories = () => {
  const dispatch = useDispatch();
  const { loading, categories, entityCount } = useSelector(
    (state) => state.categories
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [offset, setOffset] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editFields, setEditFields] = useState(initialState);
  const cropperRef = useRef(null);

  useEffect(() => {
    dispatch(getCategories({ limit: PAGE_SIZE, offset }));
  }, [dispatch, offset]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data: categories,
      initialState: { pageIndex: 0, PAGE_SIZE },
    },
    usePagination
  );

  const handlePageChange = (pageNumber) => {
    getCategories({
      offset: pageNumber,
      limit: PAGE_SIZE,
    });
    setOffset(pageNumber);
    setPageNumber(pageNumber);
    //get clients with new offset
  };

  useEffect(() => {
    setMaxPages(Math.floor(entityCount / PAGE_SIZE));
  }, [entityCount]);

  const handleOnEditClick = (category) => {
    setEditMode(true);
    setEditFields(category);
  };

  const handleOnChange = (value, locale) => {
    setEditFields({
      ...editFields,
      name: { ...editFields.name, [locale]: value },
    });
  };

  const handleFileUpload = async (e, field) => {
    e.preventDefault();
    if (e?.target?.files?.[0]?.name) {
      let image = e.target.files[0];
      const compressedFile = await imageCompression(image, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
      });
      let mutatedCompressFile = new File([compressedFile], image.name, {
        type: image.type,
      });
      setEditFields({
        ...editFields,
        [field.key]: {
          file: URL.createObjectURL(image),
          binary: mutatedCompressFile,
        },
      });
    }
  };

  const handleCreate = async () => {
    if (
      (!editFields.name.he && !editFields.name.ar && !editFields.name.en) ||
      !editFields.image
    ) {
      dispatch(createMessage('Please provide name and image'));
    } else {
      setUpdating(true);
      let cat = {};
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        let name = {};
        if (editFields.name.ar) name.ar = editFields.name.ar;
        if (editFields.name.en) name.en = editFields.name.en;
        if (editFields.name.he) name.he = editFields.name.he;
        cat.name = name;
        const compressedFile = await imageCompression(blob, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 700,
        });
        let mutatedCompressFile = new File([compressedFile], blob.name, {
          type: blob.type,
        });
        cat.primaryImage = mutatedCompressFile;
        await dispatch(createCategory(cat));
        setCreateMode(false);
        setEditFields(initialState);
        setUpdating(false);
      });
    }
  };

  const handleUpdate = async () => {
    if (
      (!editFields.name.he && !editFields.name.ar && !editFields.name.en) ||
      !editFields.image
    ) {
      dispatch(createMessage('Please provide name and image'));
    } else {
      setUpdating(true);
      let cat = { _id: editFields._id };
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        let name = {};
        if (editFields.name.ar) name.ar = editFields.name.ar;
        if (editFields.name.en) name.en = editFields.name.en;
        if (editFields.name.he) name.he = editFields.name.he;
        cat.name = name;
        const compressedFile = await imageCompression(blob, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 700,
        });
        let mutatedCompressFile = new File([compressedFile], blob.name, {
          type: blob.type,
        });
        cat.primaryImage = mutatedCompressFile;
        await dispatch(updateCategory(cat));
        setEditMode(false);
        setEditFields(initialState);
        setUpdating(false);
      });
    }
  };

  const handleDelete = async () => {
    editFields?._id && (await dispatch(deleteCategory(editFields._id)));
    setEditMode(false);
    setEditFields(initialState);
  };

  return (
    <div className="content">
      <Button onClick={() => setCreateMode(!createMode)}>Create</Button>
      {(editMode || createMode) && (
        <div>
          {updating ? (
            <Row>
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: 20,
                }}
              >
                <Spinner />
              </Col>
            </Row>
          ) : (
            <Form>
              <Row>
                <Col>
                  <h4>{editMode ? 'Edit' : 'Create'} category</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for={'Name EN'}>Name EN</Label>
                        <Input
                          maxLength={50}
                          value={editFields.name.en}
                          onChange={(e) => handleOnChange(e.target.value, 'en')}
                          id={'Name EN'}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for={'Name HE'}>Name HE</Label>
                        <Input
                          maxLength={50}
                          value={editFields.name.he}
                          onChange={(e) => handleOnChange(e.target.value, 'he')}
                          id={'Name HE'}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for={'Name AR'}>Name AR</Label>
                        <Input
                          maxLength={50}
                          value={editFields.name.ar}
                          onChange={(e) => handleOnChange(e.target.value, 'ar')}
                          id={'Name AR'}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col xs={6}>
                      {(!!editFields?.image?.file ||
                        !!editFields?.image?.path) && (
                        <>
                          <Cropper
                            src={
                              editFields?.image?.file
                                ? editFields?.image?.file
                                : `${process.env.REACT_APP_PUBLIC_URL}${editFields?.image?.path}`
                            }
                            style={{ width: '100%' }}
                            // Cropper.js options
                            initialAspectRatio={1 / 1}
                            width={'100%'}
                            aspectRatio={1 / 1}
                            guides={true}
                            ref={cropperRef}
                            autoCropArea={1}
                          />
                        </>
                      )}
                    </Col>
                    <Col xs={6}>
                      <FileUploadField
                        file={editFields?.image?.file}
                        currentFile={editFields?.image?.path}
                        {...{
                          field: {
                            key: 'image',
                            text: 'Image',
                          },
                          handleFileUpload,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                {editMode ? (
                  <Button onClick={handleUpdate}>Update category</Button>
                ) : (
                  <Button onClick={handleCreate}>Create category</Button>
                )}
                <Button onClick={handleDelete}>Delete category</Button>
              </Row>
            </Form>
          )}
        </div>
      )}

      <Card>
        <>
          <CardBody>
            <Row
              className="pagination"
              style={{
                alignItems: 'flex-start',
              }}
            >
              <Col
                style={{
                  width: '33.33%',
                }}
              >
                <button
                  onClick={() => {
                    pageNumber > 0 && handlePageChange(pageNumber - 1);
                  }}
                  disabled={pageNumber === 0}
                  className="btn-fill mx-1 btn btn-primary"
                  style={{ width: '100%' }}
                >
                  {'Previous'}
                </button>
              </Col>

              <Col
                style={{
                  width: '33.33%',
                }}
              >
                {loading ? (
                  <div style={styles.flexCenter}>
                    <Spinner size={'1.6em'} />
                  </div>
                ) : (
                  <Row
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <Col style={styles.flexCenter}>
                      <span
                        style={{
                          textAlign: 'center',
                          marginLeft: 20,
                        }}
                      >
                        {`Page ${pageNumber + 1} out of ${maxPages + 1}`}
                      </span>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col
                style={{
                  width: '33.33%',
                }}
              >
                <button
                  onClick={() => {
                    handlePageChange(pageNumber + 1);
                  }}
                  disabled={pageNumber >= maxPages}
                  className="btn-fill mx-1 btn btn-primary"
                  style={{ width: '100%' }}
                >
                  {'Next'}
                </button>
              </Col>
            </Row>

            <div
              style={{
                marginTop: '10px',
                marginBottom: '10px',
              }}
            ></div>
            <table {...getTableProps()} style={{ width: '100%' }}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps([
                          {
                            className: column.headerClassName,
                          },
                        ])}
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          {...column.getHeaderProps([
                            {
                              className: column.headerClassName,
                            },
                          ])}
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                            borderBottom: '1px solid #ccc',
                          }}
                        ></th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        backgroundColor: i % 2 ? 'white' : '#eaeaea',
                      }}
                    >
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                              },
                            ])}
                            style={{
                              padding: '10px',
                              borderRight: '1px solid #ccc',
                            }}
                          >
                            {cell.column.id === 'image.path' ? (
                              <div style={styles.imgContainer}>
                                <img
                                  src={
                                    process.env.REACT_APP_PUBLIC_URL +
                                    cell.value
                                  }
                                  style={styles.img}
                                  alt=""
                                />
                              </div>
                            ) : cell.column.id === 'actions' ? (
                              <div className="actions-column">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-expanded={false}
                                    aria-haspopup={true}
                                    caret
                                    className="btn btn-block"
                                    color="primary"
                                    data-toggle="dropdown"
                                    id="dropdownMenuButton"
                                    type="button"
                                  >
                                    Actions
                                  </DropdownToggle>
                                  <DropdownMenu
                                    right
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        handleOnEditClick(cell?.row?.original)
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
        </>
      </Card>
    </div>
  );
};

export default Categories;

const styles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: 150,
    height: 'auto',
  },
};
