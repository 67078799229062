const types = {
  SET_SINGLE: {
    sections: 'SET_SECTION',
    items: 'SET_ITEM',
    modifications: 'SET_MODIFICATION',
  },
  SET_LIST: {
    sections: 'SET_SECTIONS',
    items: 'SET_ITEMS',
    modifications: 'SET_MODIFICATIONS',
  },
  GET_ATTEMPT: {
    sections: 'GET_SECTIONS_ATTEMPT',
    items: 'GET_ITEMS_ATTEMPT',
    modifications: 'GET_MODIFICATIONS_ATTEMPT',
  },
  GET_SUCCESS: {
    sections: 'GET_SECTIONS_SUCCESS',
    items: 'GET_ITEMS_SUCCESS',
    modifications: 'GET_MODIFICATIONS_SUCCESS',
  },
  GET_FAIL: {
    sections: 'GET_SECTIONS_FAIL',
    items: 'GET_ITEMS_FAIL',
    modifications: 'GET_MODIFICATIONS_FAIL',
  },
  CREATE_ATTEMPT: {
    sections: 'CREATE_SECTION_ATTEMPT',
    items: 'CREATE_ITEM_ATTEMPT',
    modifications: 'CREATE_MODIFICATION_ATTEMPT',
  },
  CREATE_SUCCESS: {
    sections: 'CREATE_SECTION_SUCCESS',
    items: 'CREATE_ITEM_SUCCESS',
    modifications: 'CREATE_MODIFICATION_SUCCESS',
  },
  CREATE_FAIL: {
    sections: 'CREATE_SECTION_FAIL',
    items: 'CREATE_ITEM_FAIL',
    modifications: 'CREATE_MODIFICATION_FAIL',
  },
  UPDATE_ATTEMPT: {
    sections: 'UPDATE_SECTION_ATTEMPT',
    items: 'UPDATE_ITEM_ATTEMPT',
    modifications: 'UPDATE_MODIFICATION_ATTEMPT',
  },
  UPDATE_SUCCESS: {
    sections: 'UPDATE_SECTION_SUCCESS',
    items: 'UPDATE_ITEM_SUCCESS',
    modifications: 'UPDATE_MODIFICATION_SUCCESS',
  },
  UPDATE_FAIL: {
    sections: 'UPDATE_SECTION_FAIL',
    items: 'UPDATE_ITEM_FAIL',
    modifications: 'UPDATE_MODIFICATION_FAIL',
  },
  PUBLISH_ATTEMPT: {
    sections: 'PUBLISH_SECTION_ATTEMPT',
    items: 'PUBLISH_ITEM_ATTEMPT',
    modifications: 'PUBLISH_MODIFICATION_ATTEMPT',
  },
  PUBLISH_SUCCESS: {
    sections: 'PUBLISH_SECTION_SUCCESS',
    items: 'PUBLISH_ITEM_SUCCESS',
    modifications: 'PUBLISH_MODIFICATION_SUCCESS',
  },
  PUBLISH_FAIL: {
    sections: 'PUBLISH_SECTION_FAIL',
    items: 'PUBLISH_ITEM_FAIL',
    modifications: 'PUBLISH_MODIFICATION_FAIL',
  },
  UNPUBLISH_ATTEMPT: {
    sections: 'UNPUBLISH_SECTION_ATTEMPT',
    items: 'UNPUBLISH_ITEM_ATTEMPT',
    modifications: 'UNPUBLISH_MODIFICATION_ATTEMPT',
  },
  UNPUBLISH_SUCCESS: {
    sections: 'UNPUBLISH_SECTION_SUCCESS',
    items: 'UNPUBLISH_ITEM_SUCCESS',
    modifications: 'UNPUBLISH_ITEM_FAIL',
  },
  UNPUBLISH_FAIL: {
    sections: 'UNPUBLISH_SECTION_FAIL',
    items: 'UNPUBLISH_MODIFICATION_SUCCESS',
    modifications: 'UNPUBLISH_MODIFICATION_FAIL',
  },
  REACTIVATE_ATTEMPT: {
    sections: 'REACTIVATE_SECTION_ATTEMPT',
    items: 'REACTIVATE_ITEM_ATTEMPT',
    modifications: 'REACTIVATE_MODIFICATION_ATTEMPT',
  },
  REACTIVATE_SUCCESS: {
    sections: 'REACTIVATE_SECTION_SUCCESS',
    items: 'REACTIVATE_ITEM_SUCCESS',
    modifications: 'REACTIVATE_MODIFICATION_SUCCESS',
  },
  REACTIVATE_FAIL: {
    sections: 'REACTIVATE_SECTION_FAIL',
    items: 'REACTIVATE_ITEM_FAIL',
    modifications: 'REACTIVATE_MODIFICATION_FAIL',
  },
  DEACTIVATE_ATTEMPT: {
    sections: 'DEACTIVATE_SECTION_ATTEMPT',
    items: 'DEACTIVATE_ITEM_ATTEMPT',
    modifications: 'DEACTIVATE_MODIFICATION_ATTEMPT',
  },
  DEACTIVATE_SUCCESS: {
    sections: 'DEACTIVATE_SECTION_SUCCESS',
    items: 'DEACTIVATE_ITEM_SUCCESS',
    modifications: 'DEACTIVATE_ITEM_FAIL',
  },
  DEACTIVATE_FAIL: {
    sections: 'DEACTIVATE_SECTION_FAIL',
    items: 'DEACTIVATE_MODIFICATION_SUCCESS',
    modifications: 'DEACTIVATE_MODIFICATION_FAIL',
  },
};

export default types;
